import { FETCH_PAGE } from "../../actions/userQuestionnaire/selectPage";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";

export default (state = null, action) => {
  switch (action.type) {
    case FETCH_PAGE:
      return action.payload.data.previousQuestions;
    case SET_USER_QUESTIONNAIRE_ID:
      return null;
    default:
      return state;
  }
};
