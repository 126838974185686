import api from "../../assets/common/api/api";

export const FETCH_CASCADING_LEVELS = "FETCH_CASCADING_LEVELS";

export const fetchCascadingLevels = (componentName) => async (dispatch, getState) => {
  const response = await api.get("/CascadingList/GetLevels", {
    params: {
      componentName: encodeURI(componentName),
    },
  });

  if (response.data && response.data.length)
    dispatch({
      type: FETCH_CASCADING_LEVELS,
      payload: {
        componentName,
        levels: response.data,
      },
    });
};
