import _ from "lodash";
import { SET_TEXT_CATEGORIES } from "../../actions/userQuestionnaire/setTextCategories";
import {
  SET_PREVIOUS_SELECTED_CATEGORIES_TO_DEFAULT_VALUE,
  ADD_OPTION_CATEGORIES,
  SET_OPTION_CATEGORIES_TO_DEFAULT_VALUE,
} from "../../actions/userQuestionnaire/categories/onTextCategoriesChange";
import { SET_TEXT_CATEGORIES_TO_DEFAULT_VALUE } from "../../actions/userQuestionnaire/categories/setTextCategoriesToDefaultValue";
import { MODIFY_CASCADING_DRUG_CATEGORY_VALUES } from "../../actions/userQuestionnaire/categories/modifyCascadingDrugCategoryValues";
import { REMOVE_CATEGORIES_FROM_NOT_VISIBLE_PAGES } from "../../actions/userQuestionnaire/categories/removeCategoriesFromNotVisiblePages";
import { RESET_TEXT_CATEGORIES } from "../../actions/userQuestionnaire/categories/resetTextCategories";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";

import handleCategoryChange from "../../assets/questionnaire/handleStateFunctions/textCategories";
import { enableES5, produce } from "immer";

const initState = [];
enableES5();
export default produce((draft, action) => {
  switch (action.type) {
    case SET_TEXT_CATEGORIES: {
      if (!action.payload) return [];
      return JSON.parse(action.payload);
    }
    case SET_PREVIOUS_SELECTED_CATEGORIES_TO_DEFAULT_VALUE: {
      let { sectionId, options, answers } = action.payload;
      handleCategoryChange.set_options_categories_to_default_value(draft, options, answers, sectionId);
      return;
    }
    case ADD_OPTION_CATEGORIES: {
      let { selectedOption, sectionId, sectionSortOrder } = action.payload;
      handleCategoryChange.add_categories(draft, selectedOption.CategoryValues, sectionId, sectionSortOrder);
      return;
    }

    case SET_OPTION_CATEGORIES_TO_DEFAULT_VALUE: {
      let { selectedOption, sectionId } = action.payload;

      handleCategoryChange.set_option_categories_to_default_value(draft, selectedOption.CategoryValues, sectionId);
      return;
    }
    case SET_TEXT_CATEGORIES_TO_DEFAULT_VALUE: {
      let { options, answers, sectionId } = action.payload;
      handleCategoryChange.set_options_categories_to_default_value(draft, options, answers, sectionId);

      return;
    }

    case MODIFY_CASCADING_DRUG_CATEGORY_VALUES: {
      let { previousAnswer, newAnswer, selectedPage, zIndexCategories } = action.payload;
      if (
        previousAnswer &&
        previousAnswer.AnswerValue.categoryValues &&
        previousAnswer.AnswerValue.categoryValues.length > 0
      ) {
        const catgoriesName = _.map(previousAnswer.AnswerValue.categoryValues, (category) => {
          return category.CategoryName;
        });
        const avalableZIndexCategories = _.filter(zIndexCategories, (category) => {
          return catgoriesName.includes(category.CategoryName);
        });

        handleCategoryChange.delete_categories(draft, avalableZIndexCategories, selectedPage.id);
      }
      if (newAnswer && newAnswer.categoryValues && newAnswer.categoryValues.length > 0) {
        const catgoriesName = _.map(newAnswer.categoryValues, (category) => {
          return category.CategoryName;
        });
        const avalableZIndexCategories = _.filter(zIndexCategories, (category) => {
          return catgoriesName.includes(category.CategoryName);
        });
        handleCategoryChange.add_categories(draft, avalableZIndexCategories, selectedPage.id, selectedPage.sortOrder);
      }

      return;
    }
    case REMOVE_CATEGORIES_FROM_NOT_VISIBLE_PAGES: {
      let { notRelevantCategories } = action.payload;
      handleCategoryChange.set_not_relevant_categories_to_default_value(draft, notRelevantCategories);
      return;
    }
    case RESET_TEXT_CATEGORIES: {
      let { categoriesValues, selectedPage } = action.payload;
      handleCategoryChange.set_option_categories_to_default_value(draft, categoriesValues, selectedPage.id);
      return;
    }
    case SET_USER_QUESTIONNAIRE_ID:
      return initState;
  }
}, initState);
