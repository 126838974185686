import { SET_SHOW_PROGRESS_BAR } from "../../actions/userQuestionnaire/setShowProgressBar";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";

export default function (state = null, action) {
  switch (action.type) {
    case SET_SHOW_PROGRESS_BAR:
      return action.payload;
    case SET_USER_QUESTIONNAIRE_ID:
      return false;
    default:
      return state;
  }
}
