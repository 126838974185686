import api from "../../assets/common/api/api";
import _ from "lodash";
import history from "../../assets/common/custom/history";
import { setLoading } from "../loading/setLoading";

export const GET_PAGES_BY_NAME = "GET_PAGES_BY_NAME";

export const getPageByName = (name, studyId) => async (dispatch, getState) => {
  setLoading(true);
  if (_.isEmpty(name)) history.push("/NotFound");

  await api
    .get(`/pages/page?studyId=${studyId}&pageName=${name}`)
    .then((response) => {
      setLoading(false);
      if (response.status === 200) {
        let responseData = response.data;
        if (_.isEmpty(responseData)) {
          history.push("/NotFound");
        } else {
          dispatch({ type: GET_PAGES_BY_NAME, payload: response.data });
        }
      }
    })
    .catch((error) => {
      setLoading(false);
      history.push("/NotFound");
    });
};
