import api from "../../assets/common/api/api";
import _ from "lodash";
import { toast } from "react-toastify";
import { setLoading } from "../loading/setLoading";
import { res } from "../../assets/localization/resources";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const authenticate = (email, password) => async (dispatch, getState) => {
  let { study } = getState();
  let user = { email, password, studyId: study.StudyId };
  setLoading(true);

  await api
    .post("/account/authenticate", user)
    .then((response) => {
      if (response.status === 200) {
        setLoading(false);
        let responseData = response.data;
        if (_.isEmpty(responseData))
          return dispatch({ type: LOGIN_FAILURE, payload: { message: "Error while authentication" } });

        if (!_.isEmpty(response.data.Message)) {
          toast.error(res(study.Culture, response.data.Message));
        }

        //check the user and the jwt token
        if (!_.isEmpty(responseData.User)) {
          if (!_.isEmpty(responseData.JwtToken)) {
            localStorage.setItem("token", JSON.stringify(responseData.JwtToken));
            localStorage.setItem("user", JSON.stringify(responseData.User));
            dispatch({ type: LOGIN_SUCCESS, payload: response.data });
          } else if (responseData.User.LockoutEnable && responseData.User.LockoutSecondsRemaining > 0) {
            dispatch({
              type: LOGIN_FAILURE,
              payload: {
                message: "lockedaccount",
                lockoutSecondsRemaining: responseData.User.LockoutSecondsRemaining,
              },
            });
          }
        } else dispatch({ type: LOGIN_FAILURE, payload: { message: response.data.Message } });
      }
    })
    .catch((error) => {
      setLoading(false);
      toast.error(res(study.Culture, "ErrorOcurred"));
      dispatch({ type: LOGIN_FAILURE, payload: "Error while authentication" });
    });
};
