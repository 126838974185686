import { GET_PAGES_BY_NAME } from "../actions/pages/getPageByName";

export function pages(state = {}, action) {
  switch (action.type) {
    case GET_PAGES_BY_NAME: {
      var { pageName, title, html } = action.payload;
      if (!state[pageName]) {
        return { ...state, [pageName]: { title, html } };
      }
      return;
    }
    default:
      return state;
  }
}
