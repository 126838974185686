import api from "../../assets/common/api/api";
import _ from "lodash";
import history from "../../assets/common/custom/history";
import { toast } from "react-toastify";
import { setLoading } from "../loading/setLoading";
import { res } from "../../assets/localization/resources";

export const GET_FOLLOWUP_DATA = "GET_FOLLOWUP_DATA";

export const getFollowupData = (urlParameters) => async (dispatch, getState) => {
  let { study } = getState();

  let form = {
    FormName: "ReportForm",
    StudyId: study.StudyId,
    Culture: study.Culture,
    QuestionnaireId: urlParameters.get("questionnaireId"),
  };
  setLoading(true);
  await api
    .post("/followup/followup", form)
    .then((response) => {
      if (response.status === 200) {
        setLoading(false);
        let responseData = response.data;
        if (_.isEmpty(responseData)) history.replace("/pages/error");
        if (!responseData.AccessGranted) history.replace("/pages/accessdenied");
        else dispatch({ type: GET_FOLLOWUP_DATA, payload: response.data });
      }
    })
    .catch((error) => {
      setLoading(false);
      toast.error(res(study.Culture, "ErrorOcurred"));
      history.replace("/pages/error");
    });
};
