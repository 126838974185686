export const RESET_TEXT_CATEGORIES = "RESET_TEXT_CATEGORIES";

let resetTextCategories = (textCategoriesValues) => (dispatch, getState) => {
  let { selectedPage } = getState().userQuestionnaire;

  dispatch({
    type: RESET_TEXT_CATEGORIES,
    payload: {
      categoriesValues: textCategoriesValues,
      selectedPage,
    },
  });
};

export default resetTextCategories;
