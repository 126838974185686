import api from "../../assets/common/api/api";
import { toast } from "react-toastify";
import { setLoading } from "../loading/setLoading";

export const DELETE_WEBSITE_CONTENT_UPLOADS = "DELETE_WEBSITE_CONTENT_UPLOADS";

export const deleteWebSiteContentUploads = (id, contentType) => async (dispatch, getState) => {
  setLoading(true);
  await api
    .post(`/study/DeleteWebSiteContentUplodas?id=${id}`)
    .then((response) => {
      if (response.status === 200) {
        setLoading(false);
        if (response.data && response.data.success) {
          dispatch({
            type: DELETE_WEBSITE_CONTENT_UPLOADS,
            payload: { id, contentType },
          });
        } else toast.error("Error while deleting site content");
      }
    })
    .catch((error) => {
      setLoading(false);
      toast.error("Error while deleting site content");
    });
};
