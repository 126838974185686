import handleRepeat from "./sectionRepeat";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

//handle state functions-section
const defaultSectionData = (id) => {
  return {
    id,
    changeState: "Added",
    repeats: {},
  };
};

const sectionHandleStateExport = {
  add: (
    draftState,
    section,
    parentRepeatID,
    parentRepeatNumber,
    parentRepeatFollowup,
    sectionCondition,
    previousMCAnswers,
    previousSections,
    previousQuestions,
    cultureCode
  ) => {
    if (!draftState[section.ID]) {
      draftState[section.ID] = defaultSectionData(section.ID);
    }

    let visible = sectionCondition && sectionCondition.visible;

    let repeats = _.filter(draftState[section.ID].repeats, (x) => {
      return x.parentRepeatNumber === parentRepeatNumber;
    });

    if ((draftState[section.ID].changeState === "Added" || _.isEmpty(repeats)) && (visible || !sectionCondition)) {
      if (
        parentRepeatFollowup &&
        section.SectionDTO.ShowOnlyInNewRepeatSection &&
        !section.SectionDTO.ShowOnlyInFollowUpRepeatSection
      )
        return;

      if (
        !parentRepeatFollowup &&
        section.SectionDTO.ShowOnlyInFollowUpRepeatSection &&
        !section.SectionDTO.ShowOnlyInNewRepeatSection
      )
        return;

      let followup = parentRepeatFollowup && section.SectionDTO.ShowOnlyInFollowUpRepeatSection;

      if (!section.SectionDTO.Repeated) {
        let repeatId = parentRepeatID ? parentRepeatID : section.ID;
        handleRepeat.add(
          draftState,
          section,
          repeatId,
          parentRepeatID,
          parentRepeatNumber,
          0,
          false,
          previousQuestions,
          followup,
          cultureCode
        );
      }

      if (section.SectionDTO.Repeated) {
        handleRepeat.addInitialRepeats(
          draftState,
          section,
          parentRepeatID,
          parentRepeatNumber,
          previousSections,
          previousQuestions,
          previousMCAnswers,
          followup,
          cultureCode
        );
      }
    }
    if (
      draftState[section.ID].repeats &&
      section.SectionDTO.NumericQuestionRepeatID &&
      !_.isEmpty(previousMCAnswers) &&
      (visible || !sectionCondition)
    )
      changeRepeatsWithMCQuestion(
        draftState,
        section,
        parentRepeatID,
        parentRepeatNumber,
        previousMCAnswers[section.SectionDTO.NumericQuestionRepeatID]
      );
  },
  show_or_hide: (
    draftState,
    section,
    parentRepeatId,
    parentRepeatNumber,
    followup,
    visible,
    repeats,
    previousSection,
    previousQuestions,
    sectionConditions,
    cultureCode
  ) => {
    let sectionConditionsVisible = sectionConditions && sectionConditions.visible;
    if (visible && (!sectionConditions || sectionConditionsVisible)) {
      //show - add initial if section has not repeats
      if (_.isEmpty(repeats)) {
        if (!section.SectionDTO.Repeated) {
          let repeatId = parentRepeatId ? parentRepeatId : section.ID;
          handleRepeat.add(
            draftState,
            section,
            repeatId,
            parentRepeatId,
            parentRepeatNumber,
            0,
            false,
            previousQuestions,
            followup,
            cultureCode
          );
        }

        if (section.SectionDTO.Repeated) {
          handleRepeat.addInitialRepeats(
            draftState,
            section,
            parentRepeatId,
            parentRepeatNumber,
            previousSection,
            previousQuestions,
            null,
            followup,
            cultureCode
          );
        }
      }
    } else {
      //hide - delete repeats
      _.map(repeats, (r) => {
        handleRepeat.delete(draftState, section.ID, r.id, section.SectionDTO.SectionContentTemplate.Components);
      });
    }
    draftState[section.ID].questionConditionVisibility = visible;
  },
};

export function changeRepeatsWithMCQuestion(
  draftState,
  section,
  parentRepeatId,
  parentRepeatNumber,
  answer,
  cultureCode
) {
  if (_.isEmpty(answer)) return;

  let repeats = _.filter(Object.values(draftState[section.ID].repeats), (repeat) => {
    return repeat.parentRepeatId === parentRepeatId;
  });
  let repeatNumber = parseInt(answer);
  let repeatsCount = repeats.length;

  if (repeatsCount < repeatNumber) {
    for (let i = repeatsCount + 1; i <= repeatsCount + (repeatNumber - repeatsCount); i++)
      handleRepeat.add(draftState, section, uuidv4(), parentRepeatId, parentRepeatNumber, i, true, cultureCode);
  }

  if (repeatsCount > repeatNumber) {
    for (let i = repeatsCount; i > repeatNumber; i--) {
      let repeat = _.find(repeats, ["number", i]);
      handleRepeat.delete(draftState, section.ID, repeat.id, section.SectionDTO.SectionContentTemplate.Components);
    }
  }
}

export default sectionHandleStateExport;
