import axios from "axios";
import axiosInstance from "./api";
import logout from "../../../actions/authentication/logout";
import history from "../custom/history";

const setup = (store) => {
  axiosInstance.interceptors.request.use((config) => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  const { dispatch } = store;
  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (er) {
      if (axios.isAxiosError(er)) {
        if (er.response) {
          if (er.response.status === 401 || er.response.status === 403) {
            if (window.location.pathname.includes("form")) history.replace("/");
            else {
              dispatch(logout());
              history.replace("/login");
            }
            // <-- navigate
          } else history.replace("/pages/error"); // <-- navigate to error page
        }
      }
      return Promise.reject(er);
    }
  );
};

export default setup;
