import _ from "lodash";

export const getFirstThankYouPage = (pageListValues, sectionsConditions) => {
  return _.chain(pageListValues)
    .values()
    .filter((p) => {
      return (!sectionsConditions[p.ID] || sectionsConditions[p.ID].visible) && p.PageType === 1;
    })
    .minBy("SortOrder")
    .value();
};
export const calulateNextPage = (pageListValues, sectionsConditions, selectedPageOrder) => {
  let regularPage = _.chain(pageListValues)
    .values()
    .filter((p) => {
      return (
        p.SortOrder > selectedPageOrder &&
        (!sectionsConditions[p.ID] || sectionsConditions[p.ID].visible) &&
        p.PageType === 0
      );
    })
    .minBy("SortOrder")
    .value();
  if (_.isEmpty(regularPage)) {
    return getFirstThankYouPage(pageListValues, sectionsConditions);
  } else return regularPage;
};

export const calulatePreviousPage = (pageListValues, sectionsConditions, selectedPageOrder) => {
  return _.chain(pageListValues)
    .values()
    .filter((p) => {
      return (
        p.SortOrder < selectedPageOrder &&
        (!sectionsConditions[p.ID] || sectionsConditions[p.ID].visible) &&
        p.PageType === 0
      );
    })
    .maxBy("SortOrder")
    .value();
};
