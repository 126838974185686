import { GET_USER_QUESTIONNAIRES } from "../actions/questionnaires/getUserQuestionnaires";
import { SUBSCRIBE_PUSH_NOTIFICATIONS } from "../actions/questionnaires/subscribePushNotifications";
import { LOGIN_FAILURE } from "../actions/authentication/authenticate";
import { LOGOUT } from "../actions/authentication/logout";
export function questionnaires(state = {}, action) {
  switch (action.type) {
    case GET_USER_QUESTIONNAIRES:
      return action.payload;
    case LOGOUT:
    case LOGIN_FAILURE:
      return {};
    case SUBSCRIBE_PUSH_NOTIFICATIONS:
      return { ...state, ShowPushNotificationPopup: action.payload.ShowPushNotificationPopup };
    default:
      return state;
  }
}
