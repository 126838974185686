import _ from "lodash";

const setCategoriesToDefaultValue = (draftState, categoryValues, sectionId) => {
  _.forEach(categoryValues, (item) => {
    let founded = _.find(draftState, (x) => {
      return (
        x.CategoryID === item.CategoryID && x.CategoryValueID === item.CategoryValueID && x.SectionID === sectionId
      );
    });
    if (founded) {
      if (founded.CategoryValueID === item.DefaultCategoryValueID) draftState.splice(draftState.indexOf(founded), 1);
      else {
        founded.Value = item.DefaultValue;
        founded.CategoryValueID = item.DefaultCategoryValueID;
        founded.SetAsDefault = true;
      }
    }
  });
};

const textCategoriesHandleStateExport = {
  add_categories: (draftState, categoryValues, sectionId, sectionSortOrder) => {
    _.forEach(categoryValues, (item) => {
      let founded = _.find(draftState, (x) => {
        return x.CategoryID === item.CategoryID && x.SectionID === sectionId;
      });
      if (!founded) {
        let newItem = { ...item };
        newItem["SectionID"] = sectionId;
        newItem["SectionSortOrder"] = sectionSortOrder;
        draftState.push(newItem);
      } else {
        founded.CategoryValueID = item.CategoryValueID;
        founded.Value = item.Value;
        founded.SetAsDefault = false;
      }
    });
  },
  delete_categories: (draftState, categoryValues, sectionId) => {
    _.forEach(categoryValues, (item) => {
      const index = draftState.findIndex((x) => x.CategoryID === item.CategoryID && x.SectionID === sectionId);
      if (index !== -1) draftState.splice(index, 1);
    });
  },
  set_options_categories_to_default_value: (draftState, options, answers, sectionId) => {
    let optionIds = answers ? Object.keys(answers) : null;
    _.forEach(options, (option) => {
      if (optionIds.includes(option.ID) && option.CategoryValues.length > 0) {
        setCategoriesToDefaultValue(draftState, option.CategoryValues, sectionId);
      }
    });
  },
  set_option_categories_to_default_value: (draftState, categoryValues, sectionId) => {
    setCategoriesToDefaultValue(draftState, categoryValues, sectionId);
  },
  set_not_relevant_categories_to_default_value: (draftState, notRelevantCategories) => {
    _.forEach(notRelevantCategories, (category) => {
      let founded = _.find(draftState, (x) => {
        return (
          x.CategoryID === category.CategoryID &&
          x.CategoryValueID === category.CategoryValueID &&
          x.SectionID === category.SectionID
        );
      });
      if (founded) {
        draftState.splice(draftState.indexOf(founded), 1);
      }
    });
  },
};

export default textCategoriesHandleStateExport;
