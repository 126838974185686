import { enableES5, produce } from "immer";
import _ from "lodash";

import handleSectionCategories from "../../assets/questionnaire/handleStateFunctions/sectionCategories";
import {
  evaluateConditions,
  getSectionCategories,
} from "../../assets/questionnaire/containers/categoryConditionContainer";
import { INIT_CONDITIONAL_SECTION } from "../../actions/userQuestionnaire/categories/initConditionalSection";
import { CALCULATE_SECTION_VISIBILITY } from "../../actions/userQuestionnaire/categories/calculateSectionVisibility";
import { SET_SECTIONS_CONDITIONS } from "../../actions/userQuestionnaire/categories/setSectionsConditions";
import { CALCULATE_VISIBILITY_WITH_NOT_RELEVANT_CATEGORIES } from "../../actions/userQuestionnaire/categories/calculateVisibilityWithNotRelevantCategories";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";
import { CategoryOperator } from "../../assets/questionnaire/Enums";

const initState = {};
enableES5();
const defaultData = (sortOrder) => {
  return {
    categories: {},
    properties: {},
    visible: true,
    sortOrder,
  };
};

export default produce((draft, action) => {
  switch (action.type) {
    case INIT_CONDITIONAL_SECTION: {
      let {
        sectionId,
        pageSortOrder,
        categoryShowCondition,
        secondCategoryShowCondition,
        secondCategoryConditionOperator,
        textCategories,
        numericCategories,
        isTopSection,
      } = action.payload;

      if (_.isEmpty(categoryShowCondition.CategoryValue1.Value)) {
        return;
      }

      if (draft[sectionId] && isTopSection) return;

      if (draft[sectionId] && !isTopSection) {
        handleSectionCategories.calculate_section_condition(
          draft,
          sectionId,
          getSectionCategories(textCategories, pageSortOrder, CategoryOperator.Smaller),
          getSectionCategories(numericCategories, pageSortOrder, CategoryOperator.Smaller)
        );
        return;
      }

      let changedTextCategories = getSectionCategories(textCategories, pageSortOrder);
      let changedNumericCategories = getSectionCategories(numericCategories, pageSortOrder);

      draft[sectionId] = defaultData(pageSortOrder);
      handleSectionCategories.add_category_condition(
        draft,
        sectionId,
        categoryShowCondition,
        true,
        changedTextCategories,
        changedNumericCategories
      );
      if (!_.isEmpty(secondCategoryShowCondition) && !_.isEmpty(secondCategoryShowCondition.CategoryValue1.Value)) {
        handleSectionCategories.add_category_condition(
          draft,
          sectionId,
          secondCategoryShowCondition,
          false,
          changedTextCategories,
          changedNumericCategories
        );
      }
      draft[sectionId].properties = {
        logicalOperator1: categoryShowCondition.LogicalOperator,
        logicalOperator2: secondCategoryShowCondition ? secondCategoryShowCondition.LogicalOperator : null,
        secondCategoryConditionOperator,
      };

      draft[sectionId].visible = evaluateConditions(draft[sectionId]);
      return;
    }
    case CALCULATE_SECTION_VISIBILITY: {
      let { changedTextCategories, sortOrder, categoriesMap, changedNumericCategories } = action.payload;
      for (let category of changedTextCategories) {
        if (categoriesMap[category.CategoryID]) {
          let sections = categoriesMap[category.CategoryID];
          handleSectionCategories.calculate_condition(
            draft,
            sections,
            sortOrder,
            category.CategoryID,
            changedTextCategories,
            changedNumericCategories
          );
        }
      }
      for (let category of changedNumericCategories) {
        if (categoriesMap[category.CategoryID]) {
          let sections = categoriesMap[category.CategoryID];
          handleSectionCategories.calculate_condition(
            draft,
            sections,
            sortOrder,
            category.CategoryID,
            changedTextCategories,
            changedNumericCategories
          );
        }
      }
      return;
    }
    case CALCULATE_VISIBILITY_WITH_NOT_RELEVANT_CATEGORIES: {
      let { notRelevantCategories, categoriesMap, sortOrder } = action.payload;
      for (let category of notRelevantCategories) {
        if (categoriesMap[category.CategoryID]) {
          let sections = categoriesMap[category.CategoryID];
          handleSectionCategories.calculate_condition_not_relevant_categories(
            draft,
            sections,
            sortOrder,
            category.CategoryID
          );
        }
      }
      return;
    }
    case SET_SECTIONS_CONDITIONS: {
      return JSON.parse(action.payload);
    }
    case SET_USER_QUESTIONNAIRE_ID: {
      return initState;
    }
    default:
      return;
  }
}, initState);
