import { DELETE_REPEAT } from "../../actions/section/deleteRepeat";
import { SHOW_OR_HIDE_SECTION } from "../../actions/section/showOrHideSection";
import { SET_SHOULD_MODIFY_CATEGORIES } from "../../actions/tempStorage/setShouldModifyCategories";
import { FETCH_PAGE } from "../../actions/userQuestionnaire/selectPage";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";

export default function (state = false, action) {
  switch (action.type) {
    case SHOW_OR_HIDE_SECTION: {
      let { visible } = action.payload;
      return !visible;
    }
    case DELETE_REPEAT:
      return true;
    case SET_SHOULD_MODIFY_CATEGORIES:
      return action.payload;
    case FETCH_PAGE:
      return false;
    case SET_USER_QUESTIONNAIRE_ID:
      return {};
    default:
      return state;
  }
}
