import api from "../../assets/common/api/api";
import { setLoading } from "../loading/setLoading";

export const GET_USER_QUESTIONNAIRES = "GET_USER_QUESTIONNAIRES";

let getUserQuestionnaires = () => async (dispatch, getState) => {
  dispatch(setLoading(true));

  // {
  //   params: {
  //     componentId: componentId,
  //     levelName: encodeURI(levelName),
  //     groupId: groupId,
  //     parentItemId: parentItemId,
  //   },
  // }

  const response = await api.get(`/questionnaires/GetUserQuestionnaires`);
  if (response.status === 200) {
    dispatch(setLoading(false));
    dispatch({ type: GET_USER_QUESTIONNAIRES, payload: response.data });
  } else {
    dispatch(setLoading(false));
  }
};

export default getUserQuestionnaires;
