import { FETCH_CASCADING_LEVELS } from "../../actions/tempStorage/fetchCascadingLevels";
import { FETCH_CASCADING_DRUGS } from "../../actions/tempStorage/fetchCascadingDrugs";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";
import _ from "lodash";

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_CASCADING_LEVELS: //action.payload.levels
      return {
        ...state,
        [action.payload.componentName]: {
          ...state[action.payload.componentName],
          levels: action.payload.levels,
        },
      };
    case FETCH_CASCADING_DRUGS:
      return {
        ...state,
        [action.payload.componentName]: {
          ...state[action.payload.componentName],
          [action.payload.groupName]: _.keyBy(action.payload.drugs, "AnswerValue"),
        },
      };
    case SET_USER_QUESTIONNAIRE_ID: {
      return {};
    }
    default:
      return state;
  }
}
