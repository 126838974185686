import _ from "lodash";
import { LogicalOperator } from "../Enums";
import showOrHideSection from "../../../actions/section/showOrHideSection";
import resetTextCategories from "../../../actions/userQuestionnaire/categories/resetTextCategories";
import { getDescendantMCquestionsInSection } from "../Utils/questionComponentUtil";

export let SectionConditionsMap = {};

export const resetSectionConditionsMap = () => {
  SectionConditionsMap = {};
};

export const registerCondition = (section) => {
  let sectionId = section.ID;
  let showConditions = section.SectionDTO.AnswerShowCondition;

  if (!SectionConditionsMap[sectionId]) {
    if (!_.isEmpty(showConditions) && _.isArray(showConditions)) {
      var conditions = _.filter(showConditions, (val) => !_.isEmpty(val.QuestionID));

      if (!_.isEmpty(conditions)) SectionConditionsMap[sectionId] = { section, conditions };
    }
  }
};

export const calculateSectionsVisibility = (storeData, dispatch, sectionId, repeatId, mcQuestionId) => {
  if (sectionId && repeatId && mcQuestionId) {
    let affectedSections = _.pickBy(SectionConditionsMap, (prop) =>
      _.some(prop.conditions, ["QuestionID", mcQuestionId])
    );

    if (!_.isEmpty(affectedSections)) {
      for (let sectionKey in affectedSections)
        calculateSectionVisibility(affectedSections[sectionKey], storeData, sectionId, repeatId, dispatch);
    }
  }
};

export const calculateSectionVisibilityWhileInit = (storeData, sectionId, parentSectionId, repeatId) => {
  if (!SectionConditionsMap[sectionId]) return null;

  return calculateSectionVisibility(SectionConditionsMap[sectionId], storeData, parentSectionId, repeatId);
};

function calculateSectionVisibility(sectionData, storeData, sectionId, repeatId, dispatch = null) {
  let { sections, selectedPage, previousQuestions, previousSections, sectionsConditions, cultureCode } = storeData;
  let { section, conditions } = sectionData;
  let evaulationResults = [];
  let operator = _.last(conditions).LogicalOperator;

  _.map(conditions, (condition) => {
    if (
      sections[sectionId] &&
      sections[sectionId].repeats[repeatId] &&
      sections[sectionId].repeats[repeatId].questions[condition.QuestionID]
    ) {
      let answers = sections[sectionId].repeats[repeatId].questions[condition.QuestionID].answers;
      evaulationResults.push(evaluateAnswerStatement(answers, condition));
    }
  });

  let visible;
  if (operator === LogicalOperator.And) visible = _.every(evaulationResults, Boolean);
  else visible = _.some(evaulationResults, Boolean);

  let parentRepeatNumber = sections[sectionId].repeats[repeatId].repeatable
    ? sections[sectionId].repeats[repeatId].number
    : sections[sectionId].repeats[repeatId].parentRepeatId != null
    ? sections[sectionId].repeats[repeatId].parentRepeatNumber
    : null;

  let parentRepeatId = sections[sectionId].repeats[repeatId].repeatable
    ? repeatId
    : sections[sectionId].repeats[repeatId].parentRepeatId != null
    ? sections[sectionId].repeats[repeatId].parentRepeatId
    : null;
  let followup = parentRepeatId ? sections[sectionId].repeats[repeatId].followup : false;

  //specific for the sections with empty referenceName
  if (
    _.isEmpty(sectionData.section.SectionDTO.ReferenceName.trim()) &&
    sectionData.section.SectionDTO.ShowOnlyInFollowUpRepeatSection &&
    !sectionData.section.SectionDTO.ShowOnlyInNewRepeatSection
  )
    parentRepeatNumber = parentRepeatId ? sections[sectionId].repeats[repeatId].parentRepeatNumber : null;

  var existingRepeats = _.chain(sections[section.ID].repeats)
    .values()
    .filter((r) => {
      return r.parentRepeatId === parentRepeatId;
    })
    .value();

  let showSection = visible && _.isEmpty(existingRepeats);
  let hideSection = !visible && !_.isEmpty(existingRepeats);

  let shouldDispatch = showSection || hideSection;

  let actionValue = {
    section,
    parentRepeatId,
    parentRepeatNumber,
    followup,
    visible,
    existingRepeats,
    selectedPage,
    previousQuestions,
    previousSections,
    sectionsConditions,
    cultureCode,
  };

  if (hideSection && typeof dispatch == "function") {
    let MCquestions = getDescendantMCquestionsInSection(section.SectionDTO.SectionContentTemplate.Components);
    let textCategoryValues = [];

    _.forEach(MCquestions, (q) => {
      _.forEach(q.QuestionDTO.AnswerOptions, (ao) => {
        _.forEach(ao.CategoryValues, (catVal) => textCategoryValues.push(catVal));
      });
    });

    if (!_.isEmpty(textCategoryValues)) dispatch(resetTextCategories(textCategoryValues));
  }

  if (shouldDispatch && typeof dispatch == "function") {
    //if it is called by MC question
    dispatch(showOrHideSection(actionValue));
    return;
  }

  return actionValue;
}

function evaluateAnswerStatement(answers, condition) {
  let answerFound = false;
  _.forIn(answers, (answerObj, optionId) => {
    if (answerObj.optionValue === condition.AnswerValue) {
      answerFound = true;
      return false; //exit loop
    }
  });

  if (condition.Negate) return !answerFound;
  else return answerFound;
}
