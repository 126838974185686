import _ from "lodash";
import { enableES5, produce } from "immer";

import { GET_QUESTION_IMAGE_CONTENT } from "../../actions/question/getQuestionImageContent";

const initState = {};
enableES5();
export default produce((draft, action) => {
  switch (action.type) {
    case GET_QUESTION_IMAGE_CONTENT: {
      let { QuestionId, Content, ContentType } = action.payload;
      if (!draft[QuestionId]) draft[QuestionId] = { Content, ContentType };
      else {
        draft[QuestionId].Content = Content;
        draft[QuestionId].ContentType = ContentType;
      }
      return;
    }

    default:
      return;
  }
}, initState);
