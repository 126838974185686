import _ from "lodash";

import { ADD_INVALID_ITEM } from "../../actions/tempStorage/invalidItems/addInvalidItem";
import { DELETE_INVALID_ITEM } from "../../actions/tempStorage/invalidItems/deleteInvalidItem";
import { DELETE_MULTIPLE_INVALID_OPTIONS } from "../../actions/tempStorage/invalidItems/deleteMultipleInvalidOptions";
import { ADD_REQUIRED_INVALID_QUESTIONS } from "../../actions/tempStorage/invalidItems/addRequiredInvalidQuestions";
import { FETCH_PAGE } from "../../actions/userQuestionnaire/selectPage";
import { DELETE_REQUIRED_ITEM } from "../../actions/tempStorage/requiredItems/deleteRequiredItem";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";

export default function (state = {}, action) {
  switch (action.type) {
    case ADD_INVALID_ITEM: {
      let { optionId, questionId, repeatId, message } = action.payload;
      let key = optionId ? `${optionId}-${questionId}-${repeatId}` : `${questionId}-${repeatId}`;
      return {
        ...state,
        [key]: message,
      };
    }
    case DELETE_INVALID_ITEM: {
      let { optionId, questionId, repeatId } = action.payload;
      let key = optionId ? `${optionId}-${questionId}-${repeatId}` : `${questionId}-${repeatId}`;
      if (state[key]) return _.omit(state, key);
      return state;
    }
    case DELETE_MULTIPLE_INVALID_OPTIONS: {
      let { options, questionId, repeatId } = action.payload;
      let keys = [];
      _.forEach(options, (option) => {
        let key = `${option.ID}-${questionId}-${repeatId}`;
        if (state[key]) keys.push(key);
      });
      if (keys) return _.omit(state, keys);
      return state;
    }
    case ADD_REQUIRED_INVALID_QUESTIONS: {
      let { items, message } = action.payload;
      let newState = { ...state };
      for (let item of items) {
        let key = `${item.questionId}-${item.repeatId}`;
        newState[key] = message;
      }
      return newState;
    }
    case FETCH_PAGE: {
      return {};
    }
    case DELETE_REQUIRED_ITEM: {
      let { questionId, repeatId } = action.payload;
      let key = `${questionId}-${repeatId}`;
      return _.omit(state, key);
    }
    case SET_USER_QUESTIONNAIRE_ID: {
      return {};
    }
    default:
      return state;
  }
}
