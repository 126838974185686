import { useEffect } from 'react';

const ScrollToTopOnRouteChange = ({history})  =>{
  useEffect(() => {  
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
    });
    return () => {
      unlisten(); // Clean up the listener when the component unmounts
    };
  }, [history]);

  return null; // This component doesn't render anything
}

export default ScrollToTopOnRouteChange;