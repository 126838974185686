import { LOADING_PAGE } from "../actions/loading/setLoading";

export default function (state = null, action) {
  switch (action.type) {
    case LOADING_PAGE:
      return action.payload;
    default:
      return state;
  }
}
