import { SET_CATEGORIES_MAP } from "../../actions/userQuestionnaire/setCategoriesMap";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";

export default function (state = null, action) {
  switch (action.type) {
    case SET_CATEGORIES_MAP:
      return JSON.parse(action.payload);
    case SET_USER_QUESTIONNAIRE_ID:
      return null;
    default:
      return state;
  }
}
