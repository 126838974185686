import { enableES5, produce } from "immer";
import _ from "lodash";
import { CHANGE_WEBSITE_CONTENT_UPLOADS } from "../actions/study/changeWebSiteContentUploads";
import { DELETE_WEBSITE_CONTENT_UPLOADS } from "../actions/study/deleteWebSiteContentUploads";
import { SET_TOOLBOX_DATA } from "../actions/study/getWebSiteContentUploads";

const initState = [];
enableES5();

export default produce((draft, action) => {
  switch (action.type) {
    case SET_TOOLBOX_DATA: {
      return action.payload;
    }
    case CHANGE_WEBSITE_CONTENT_UPLOADS: {
      let { id, name, value, order, file, fileName, contentType, webContentType, culture, url } = action.payload;
      let websiteContentItem = null;
      if (webContentType == 5) {
        websiteContentItem = draft.find((x) => x.ID === id);
        if (websiteContentItem == null) {
          websiteContentItem = { ID: id, WebContentType: webContentType, CultureID: culture };
          draft.push(websiteContentItem);
        }

        if (!_.isEmpty(websiteContentItem)) {
          websiteContentItem.Name = name;
          websiteContentItem.Value = value;
          websiteContentItem.File = file;
          websiteContentItem.Order = order;
          websiteContentItem.FileName = fileName;
          websiteContentItem.ContentType = contentType;
          websiteContentItem.URL = url;
        }
      }

      return;
    }
    case DELETE_WEBSITE_CONTENT_UPLOADS: {
      let { id, contentType } = action.payload;
      if (contentType == 5) {
        const index = draft.findIndex((x) => x.ID === id);
        if (index !== -1) draft.splice(index, 1);
      }

      return;
    }

    default:
      return;
  }
}, initState);
