export const ADD_REPEAT = "ADD_REPEAT";

let addRepeat = (section, parentRepeatId, parentRepeatNumber) => (dispatch, getState) => {
  let { selectedPage, previousQuestions, cultureCode } = getState().userQuestionnaire;

  dispatch({
    type: ADD_REPEAT,
    payload: { section, parentRepeatId, parentRepeatNumber, selectedPage, previousQuestions, cultureCode },
  });
};

export default addRepeat;
