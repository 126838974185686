import { SET_CURRENT_DATE } from "../../actions/userQuestionnaire/setCurrentDate";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";

export default function (state = null, action) {
  switch (action.type) {
    case SET_CURRENT_DATE:
      return action.payload;
    case SET_USER_QUESTIONNAIRE_ID: {
      return null;
    }
    default:
      return state;
  }
}
