import api from "../../assets/common/api/api";
import { resetSectionConditionsMap } from "../../assets/questionnaire/containers/sectionConditionsContainer";
import { setPageCategories } from "../../assets/questionnaire/containers/tempSelectPageCategoriesContainer";
import { calulateNextPage, calulatePreviousPage } from "../../assets/questionnaire/Utils/pageNavUtil";
import { completeQuestionnaire } from "./completeQuestionnaire";
import { PageNavigationENUM } from "../../assets/questionnaire/Enums";
import _ from "lodash";

export const FETCH_PAGE = "FETCH_PAGE";

export const selectPage = (page, navigationDirection) => async (dispatch, getState) => {
  resetSectionConditionsMap();
  let { id, cultureCode, textCategories, numericCategories } = getState().userQuestionnaire;
  setPageCategories(textCategories, numericCategories, page.SortOrder);

  let response = {};
  await api
    .get("/Questionnaire/GetPageTemplate", {
      params: {
        pageId: page.ID,
        userQuestionnaireId: id,
        cultureCode,
      },
    })
    .then((res) => {
      response = res;
    });

  let { codeValidation } = response.data;
  if (codeValidation && codeValidation.skip === true) {
    let nextPage;

    if (navigationDirection === PageNavigationENUM.BACK) {
      nextPage = calulatePreviousPage(
        Object.values(getState().userQuestionnaire.pageList),
        getState().userQuestionnaire.sectionsConditions,
        page.SortOrder
      );
    } else {
      nextPage = calulateNextPage(
        Object.values(getState().userQuestionnaire.pageList),
        getState().userQuestionnaire.sectionsConditions,
        page.SortOrder
      );
    }

    if (nextPage) {
      if (nextPage.PageType === 1 && page.PageType === 0) dispatch(completeQuestionnaire(nextPage));
      else {
        dispatch(selectPage(nextPage));
      }
    } else dispatch(completeQuestionnaire());
  } else {
    dispatch({ type: FETCH_PAGE, payload: { data: response.data, sortOrder: page.SortOrder } });
  }
};

export const getFirstVisiblePage = (pageList, sectionsConditions, selectedPage) => {
  return _.chain(pageList)
    .values()
    .filter((p) => {
      return (
        p.PageType === 0 &&
        p.SortOrder <= selectedPage.sortOrder &&
        (!sectionsConditions[p.ID] || sectionsConditions[p.ID].visible)
      );
    })
    .minBy("SortOrder")
    .value();
};

export const getLastVisiblePage = (pageList, sectionsConditions, selectedPage) => {
  return _.chain(pageList)
    .values()
    .filter((p) => {
      return (
        p.PageType === 0 &&
        p.SortOrder >= selectedPage.sortOrder &&
        (!sectionsConditions[p.ID] || sectionsConditions[p.ID].visible)
      );
    })
    .maxBy("SortOrder")
    .value();
};
