import { FETCH_TOP_SECTIONS_LIST } from "../../actions/userQuestionnaire/pageMenuNav";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";

export default (state = [], action) => {
  switch (action.type) {
    case FETCH_TOP_SECTIONS_LIST:
      return JSON.parse(action.payload);
    case SET_USER_QUESTIONNAIRE_ID:
      return [];
    default:
      return state;
  }
};
