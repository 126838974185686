import { calculateSectionsVisibility } from "../../assets/questionnaire/containers/sectionConditionsContainer";

export const SHOW_OR_HIDE_SECTION = "SHOW_OR_HIDE_SECTION";
//show/hide section action
export const showOrHideSectionThunk = (sectionId, repeatId, questionId) => (dispatch, getState) => {
  let { sections, tempStorage } = getState();
  let { selectedPage, previousQuestions, previousSections, sectionsConditions, cultureCode } =
    getState().userQuestionnaire;
  let storeData = {
    sections,
    requiredItems: tempStorage.requiredItems,
    selectedPage,
    previousQuestions,
    previousSections,
    sectionsConditions,
    cultureCode,
  };

  calculateSectionsVisibility(storeData, dispatch, sectionId, repeatId, questionId); //inside this, showOrHideSection action is dispatched
};

export default function showOrHideSection({
  section,
  parentRepeatId,
  parentRepeatNumber,
  followup,
  visible,
  existingRepeats,
  selectedPage,
  previousQuestions,
  previousSections,
  sectionsConditions,
  cultureCode,
}) {
  return {
    type: SHOW_OR_HIDE_SECTION,
    payload: {
      section,
      parentRepeatId,
      parentRepeatNumber,
      selectedPage,
      followup,
      visible,
      repeats: existingRepeats,
      previousQuestions,
      previousSections,
      sectionsConditions,
      cultureCode,
    },
  };
}
