import _ from "lodash";
import { REGISTER_REQUIRED_ITEM } from "../../actions/tempStorage/requiredItems/registerRequiredItem";
import { DELETE_REQUIRED_ITEM } from "../../actions/tempStorage/requiredItems/deleteRequiredItem";
import { FETCH_PAGE } from "../../actions/userQuestionnaire/selectPage";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";

export default function (state = [], action) {
  switch (action.type) {
    case REGISTER_REQUIRED_ITEM: {
      state.push(action.payload.item);
      return state;
    }
    case DELETE_REQUIRED_ITEM: {
      let { questionId, repeatId } = action.payload;
      _.remove(state, (question) => {
        return question.questionId == questionId && question.repeatId == repeatId;
      });
      return state;
    }
    case FETCH_PAGE: {
      return [];
    }
    case SET_USER_QUESTIONNAIRE_ID: {
      return {};
    }
    default:
      return state;
  }
}
