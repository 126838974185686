import api from "../../assets/common/api/api";
import _ from "lodash";
import history from "../../assets/common/custom/history";
import { toast } from "react-toastify";
import { setLoading } from "../loading/setLoading";
import { res } from "../../assets/localization/resources";

export const GET_FORM_DATA = "GET_FORM_DATA";

export const getFormData = (urlParameters, formName) => async (dispatch, getState) => {
  let { study } = getState();

  let form = {
    FormName: formName,
    StudyId: study.StudyId,
    Culture: study.Culture,
    Value: urlParameters.get("value"),
    Source: urlParameters.get("source"),
    Invitation: urlParameters.get("invitation"),
    CanceledStudyParticipationId: urlParameters.get("canceledStudyParticipationId"),
    UnsubscribeUserId: urlParameters.get("unsubscribeId"),
  };
  setLoading(true);
  await api
    .post("/form/form", form)
    .then((response) => {
      if (response.status === 200) {
        setLoading(false);
        let responseData = response.data;
        if (_.isEmpty(responseData)) history.replace("/pages/error");
        if (!responseData.AccessGranted) history.replace("/pages/accessdenied");
        else if (!_.isEmpty(responseData.FormId)) {
          if (urlParameters.has("canceledStudyParticipationId")) {
            urlParameters.delete("canceledStudyParticipationId");
            history.replace({
              search: urlParameters.toString(),
            });
          }

          dispatch({ type: GET_FORM_DATA, payload: response.data });
        }
      }
    })
    .catch((error) => {
      setLoading(false);
      toast.error(res(study.Culture, "ErrorOcurred"));
      history.replace("/pages/error");
    });
};
