import _ from "lodash";
import { getSectionCategories } from "../containers/categoryConditionContainer";
import { CategoryChangeValue } from "../Enums";

const totalCountCategoryName = ".TotalRepeatCount";
const currentCountCategoryName = ".CurrentRepeatCount";
const archivedCountCategoryName = ".ArchivedRepeatCount";

const findCategoryInPreviousPages = (numericCategories, sectionCategory, pageId) => {
  return _.find(numericCategories, (category) => {
    return category.CategoryID === sectionCategory.CategoryID && category.SectionID !== pageId;
  });
};
const findCategoryInCurrentPage = (numericCategories, sectionCategory, pageId) => {
  return _.find(numericCategories, (category) => {
    return category.CategoryID === sectionCategory.CategoryID && category.SectionID === pageId;
  });
};
const findSectionCategory = (categories, name) => {
  return _.find(categories, (category) => {
    return _.endsWith(_.toLower(category.CategoryName), _.toLower(name));
  });
};
const changeCategoryNumericValue = (numericValue, changeValue) => {
  switch (changeValue) {
    case CategoryChangeValue.Increase:
      numericValue = numericValue + 1;
      break;
    case CategoryChangeValue.Decrease:
      numericValue = numericValue - 1;
      break;
  }
  return numericValue;
};
const createNewCategory = (sectionCategory, sectionId, sectionSortOrder, numericValue) => {
  let category = { ...sectionCategory };
  category.NumericValue = numericValue;
  category["SectionID"] = sectionId;
  category["SectionSortOrder"] = sectionSortOrder;
  return category;
};

const addOrUpdateCategory = (
  draftState,
  sectionCategory,
  pageId,
  pageSortOrder,
  changeValue,
  numericCategories,
  shouldNotCheckPrevious
) => {
  let currentCategory = findCategoryInCurrentPage(numericCategories, sectionCategory, pageId);
  if (currentCategory) {
    currentCategory.NumericValue = changeCategoryNumericValue(currentCategory.NumericValue, changeValue);
  } else {
    let previousCategory = shouldNotCheckPrevious
      ? null
      : findCategoryInPreviousPages(numericCategories, sectionCategory, pageId);
    draftState.push(
      createNewCategory(
        sectionCategory,
        pageId,
        pageSortOrder,
        previousCategory
          ? changeCategoryNumericValue(previousCategory.NumericValue, changeValue)
          : changeCategoryNumericValue(0, changeValue)
      )
    );
  }
};

const numericCategoriesExport = {
  add_categories: (draftState, sectionCategories, pageId, pageSortOrder, shouldNotCheckPrevious) => {
    let currentSectionCount = findSectionCategory(sectionCategories, currentCountCategoryName);
    let totalSectionCount = findSectionCategory(sectionCategories, totalCountCategoryName);
    let archivedSectionCount = findSectionCategory(sectionCategories, archivedCountCategoryName);

    let numericCategories = getSectionCategories(draftState, pageSortOrder);

    addOrUpdateCategory(
      draftState,
      currentSectionCount,
      pageId,
      pageSortOrder,
      CategoryChangeValue.Increase,
      numericCategories,
      shouldNotCheckPrevious
    );
    addOrUpdateCategory(
      draftState,
      totalSectionCount,
      pageId,
      pageSortOrder,
      CategoryChangeValue.Increase,
      numericCategories,
      shouldNotCheckPrevious
    );
    addOrUpdateCategory(
      draftState,
      archivedSectionCount,
      pageId,
      pageSortOrder,
      null,
      numericCategories,
      shouldNotCheckPrevious
    );
  },
  update_category: (draftState, sectionCategories, pageId, pageSortOrder, isArchived) => {
    let currentSectionCount = findSectionCategory(sectionCategories, currentCountCategoryName);
    let totalSectionCount = findSectionCategory(sectionCategories, totalCountCategoryName);
    let archivedSectionCount = findSectionCategory(sectionCategories, archivedCountCategoryName);

    let numericCategories = getSectionCategories(draftState, pageSortOrder);

    if (isArchived) {
      addOrUpdateCategory(
        draftState,
        archivedSectionCount,
        pageId,
        pageSortOrder,
        CategoryChangeValue.Decrease,
        numericCategories
      );
    } else {
      addOrUpdateCategory(
        draftState,
        currentSectionCount,
        pageId,
        pageSortOrder,
        CategoryChangeValue.Decrease,
        numericCategories
      );
    }

    addOrUpdateCategory(
      draftState,
      totalSectionCount,
      pageId,
      pageSortOrder,
      CategoryChangeValue.Decrease,
      numericCategories
    );
  },
  set_repeat_archived: (draftState, sectionCategories, pageId, pageSortOrder) => {
    let currentSectionCount = findSectionCategory(sectionCategories, currentCountCategoryName);
    let archivedSectionCount = findSectionCategory(sectionCategories, archivedCountCategoryName);
    let totalSectionCount = findSectionCategory(sectionCategories, totalCountCategoryName);

    if (currentSectionCount && archivedSectionCount && totalSectionCount) {
      let numericCategories = getSectionCategories(draftState, pageSortOrder);

      addOrUpdateCategory(
        draftState,
        archivedSectionCount,
        pageId,
        pageSortOrder,
        CategoryChangeValue.Increase,
        numericCategories
      );
      addOrUpdateCategory(
        draftState,
        currentSectionCount,
        pageId,
        pageSortOrder,
        CategoryChangeValue.Decrease,
        numericCategories
      );
      addOrUpdateCategory(draftState, totalSectionCount, pageId, pageSortOrder, null, numericCategories);
    }
  },
  remove_repeat_archived: (draftState, sectionCategories, pageId, pageSortOrder) => {
    let currentSectionCount = findSectionCategory(sectionCategories, currentCountCategoryName);
    let archivedSectionCount = findSectionCategory(sectionCategories, archivedCountCategoryName);
    let totalSectionCount = findSectionCategory(sectionCategories, totalCountCategoryName);

    if (currentSectionCount && archivedSectionCount && totalSectionCount) {
      let numericCategories = getSectionCategories(draftState, pageSortOrder);

      addOrUpdateCategory(
        draftState,
        archivedSectionCount,
        pageId,
        pageSortOrder,
        CategoryChangeValue.Decrease,
        numericCategories
      );
      addOrUpdateCategory(
        draftState,
        currentSectionCount,
        pageId,
        pageSortOrder,
        CategoryChangeValue.Increase,
        numericCategories
      );
      addOrUpdateCategory(draftState, totalSectionCount, pageId, pageSortOrder, null, numericCategories);
    }
  },
  remove_added_numeric_categories: (draftState, sectionCategories, pageId) => {
    let currentSectionCount = findSectionCategory(sectionCategories, currentCountCategoryName);
    let archivedSectionCount = findSectionCategory(sectionCategories, archivedCountCategoryName);
    let totalSectionCount = findSectionCategory(sectionCategories, totalCountCategoryName);

    if (currentSectionCount && archivedSectionCount && totalSectionCount) {
      let currentCategory = findCategoryInCurrentPage(draftState, currentSectionCount, pageId);
      if (currentCategory) _.pull(draftState, currentCategory);

      let archivedCategory = findCategoryInCurrentPage(draftState, archivedSectionCount, pageId);
      if (archivedCategory) _.pull(draftState, archivedCategory);

      let totalCategory = findCategoryInCurrentPage(draftState, totalSectionCount, pageId);
      if (totalCategory) _.pull(draftState, totalCategory);
    }
  },
};

export default numericCategoriesExport;
