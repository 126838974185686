import { enableES5, produce } from "immer";
import _ from "lodash";
import { GET_STUDY_DATA } from "../actions/study/getStudyData";
import { CHANGE_WEBSITE_CONTENT } from "../actions/study/changeWebSiteContent";

import { default_culture } from "../assets/localization/resources";

const initState = {
  Culture: default_culture,
};
enableES5();
export default produce((draft, action) => {
  switch (action.type) {
    case GET_STUDY_DATA: {
      return action.payload;
    }
    case CHANGE_WEBSITE_CONTENT: {
      let websiteContentItem = draft.WebSiteContent.find((x) => x.ID === action.payload.ID);

      if (!_.isEmpty(websiteContentItem)) {
        websiteContentItem.Value = action.payload.Value;
      }
      return;
    }

    default:
      return;
  }
}, initState);
