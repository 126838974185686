import { updateOrInsertThenDispatch } from "../../assets/questionnaire/Utils/questionReferencesUtil";
import _ from "lodash";
export const MODIFY_MULTIPLE_CHOICE_ANSWERS = "MODIFY_MULTIPLE_CHOICE_ANSWERS";

let modifyMultipleChoiceAnswers =
  (
    section,
    repeatId,
    questionId,
    option,
    answers,
    isExclusive,
    archived,
    repeatNumber,
    parentRepeatNumber,
    fullName,
    parentSection
  ) =>
  (dispatch, getState) => {
    let { selectedPage, cultureCode } = getState().userQuestionnaire;
    let previousArchivedValue = getState().sections[section.ID].repeats[repeatId].archived;

    dispatch({
      type: MODIFY_MULTIPLE_CHOICE_ANSWERS,
      payload: {
        section,
        repeatId,
        questionId,
        option,
        answers,
        isExclusive,
        archived,
        cultureCode,
        selectedPage,
        previousArchivedValue,
        parentSection,
      },
    });

    let qRefMap = getState().userQuestionnaire.questionReferences;

    //check if need to dispatch update questionReference
    let keys = _.filter(Object.keys(qRefMap), (key) => {
      var ref = key.split("||")[0];
      return ref === fullName;
    });

    if (!_.isEmpty(keys))
      updateOrInsertThenDispatch(
        dispatch,
        qRefMap,
        keys,
        repeatNumber,
        parentRepeatNumber,
        fullName,
        answers,
        cultureCode,
        option,
        true,
        isExclusive,
        archived
      );
  };

export default modifyMultipleChoiceAnswers;
