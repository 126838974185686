export const ON_INIT_REPEAT_SECTION = "ON_INIT_REPEAT_SECTION";

let onInitRepeatSection = (section) => (dispatch, getState) => {
  let { selectedPage, previousMCAnswers } = getState().userQuestionnaire;
  let repeats = getState().sections[section.ID].repeats;

  dispatch({
    type: ON_INIT_REPEAT_SECTION,
    payload: { section, selectedPage, repeats, previousMCAnswers },
  });
};

export default onInitRepeatSection;
