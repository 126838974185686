import _ from "lodash";
import { formatDateAnswer } from "./questionComponentUtil";
import { defineOptionObject } from "../handleStateFunctions/question";
import { UPDATE_QUESTION_REF_MAP } from "../../../actions/tempStorage/questionReferences/updateRef";
import { getValidationRuleWithReferenceAsValue, getAnswerOptionValidationRuleReferenceAsValue } from "../validationRules/validationRulesUtil";
import { TranslationKey } from "../Enums";
import { getComponentTranslationsText } from "./questionComponentUtil";

export const _contextVariableREGEX = /\{\{[$]{0,1}([a-z0-9-_ ]*?)\.([a-z0-9-_,\[\] ]+?)\}\}/gi;
export const _sectionRepeatNrREGEX = /\{\{[a-z0-9-_ ]*?\.RepeatNr\}\}/gi;
export const _cascadingLinesREGEX = /\[[0-9, ]+\]$/;
export const _datePreviousQuestionnaireREGEX = /\participation\.datepreviousquestionnaire/;
export const _datePreviousQuestionnaireReferenceREGEX = /\{\{Participation\.DatePreviousQuestionnaire\}\}/;

const defaultQuestionRefCollection = (objKey) => {
  return { objKey, references: {} };
};

export const createReferenceMapForQuestion = (question, repeatNr, parentRepeatNr, sectionReferenceName, questionReferencesDictionary, cultureCode) => {
  if (_.isEmpty(questionReferencesDictionary)) return;

  let questionText = getComponentTranslationsText(cultureCode, question.Translations, TranslationKey.QuestionText);
  let questionHelpText = getComponentTranslationsText(cultureCode, question.Translations, TranslationKey.QuestionHelpText);

  let refs = [];
  let map = null;

  if (questionText) {
    let refFromText = questionText.match(_contextVariableREGEX);
    if (refFromText) {
      refFromText.forEach((ref) => {
        var key = trimReference(ref);
        if (!_.includes(refs, key)) refs.push(key);
      });
    }
  }

  if (questionHelpText) {
    let refFromHelpText = questionHelpText.match(_contextVariableREGEX);
    if (refFromHelpText) {
      refFromHelpText.forEach((ref) => {
        var key = trimReference(ref);
        if (!_.includes(refs, key)) refs.push(key);
      });
    }
  }

  let validationRules = [];
  if (question.QuestionDTO.AnswerOptions.length > 0) validationRules = getAnswerOptionValidationRuleReferenceAsValue(question.QuestionDTO.AnswerOptions);
  else validationRules = getValidationRuleWithReferenceAsValue(question.QuestionDTO.QuestionValidationRules);

  if (validationRules.length > 0) {
    _.map(validationRules, (x) => {
      let refFromValidationValueText = x.ValidationRuleValue.match(_contextVariableREGEX);
      if (refFromValidationValueText) {
        refFromValidationValueText.forEach((ref) => {
          var key = trimReference(ref);
          if (!_.includes(refs, key)) refs.push(key);
        });
      }
    });
  }

  if (!_.isEmpty(refs)) {
    let parentRepeatNum = parentRepeatNr == null ? "null" : parentRepeatNr;
    let key = `${question.ID}||${repeatNr}||${parentRepeatNum}`;
    map = defaultQuestionRefCollection(key);

    refs.forEach((r) => {
      let refItem;
      if (r.match(_datePreviousQuestionnaireREGEX) && r in questionReferencesDictionary) {
        refItem = _.first(questionReferencesDictionary[r]);
      } else {
        let referenceCollection = getUniqueFromDictionary(questionReferencesDictionary, r);

        if (!_.isEmpty(referenceCollection)) {
          refItem = _.find(referenceCollection, function (refCollectoinItem) {
            return refCollectoinItem.RepeatNumber === repeatNr && refCollectoinItem.ParentRepeatNumber === parentRepeatNr;
          });

          if (!refItem) {
            refItem = _.find(referenceCollection, function (refCollectoinItem) {
              return refCollectoinItem.RepeatNumber === parentRepeatNr && refCollectoinItem.ParentRepeatNumber === null;
            });
          }

          if (!refItem && (repeatNr > 0 || parentRepeatNr > 0) && referenceCollection.length == 1) {
            refItem = { ...referenceCollection[0], RepeatNumber: repeatNr, ParentRepeatNumber: parentRepeatNr };
          }
        }
      }

      map.references[r] = refItem ?? null;
    });
  }

  return map;
};

const getUniqueFromDictionary = (questionReferencesDictionary, refKey) => {
  var keys = _.filter(Object.keys(questionReferencesDictionary), (key) => {
    var ref = key.split("||")[0];
    return ref === refKey;
  });

  if (_.isEmpty(keys)) return null;

  let refCollection = [];

  _.forEach(keys, function (key) {
    var qReferences = questionReferencesDictionary[key];

    if (!_.isEmpty(qReferences)) {
      _.forEach(qReferences, (qRef) => {
        var found = _.find(refCollection, function (refCollectoinItem) {
          return refCollectoinItem.RepeatNumber === qRef.RepeatNumber && refCollectoinItem.ParentRepeatNumber === qRef.ParentRepeatNumber;
        });

        if (!found) refCollection.push(qRef);
      });
    }
  });

  return refCollection;
};

export const parseRererences = (text, questionRefMap, repeatNumber, parentRepeatNumber, sectionReferenceName) => {
  let qRefs = text.match(_contextVariableREGEX);

  if (qRefs) {
    qRefs.forEach((ref) => {
      var refVal = calculateRef(ref, questionRefMap, repeatNumber, parentRepeatNumber, sectionReferenceName);
      text = text.replace(ref, refVal);
    });
  }

  return text;
};

const calculateRef = (ref, questionRefMap, repeatNumber, parentRepeatNumber, sectionReferenceName) => {
  if (ref.match(_sectionRepeatNrREGEX)) return repeatNumber === 0 && parentRepeatNumber !== null ? parentRepeatNumber.toString() : repeatNumber.toString();

  let mapKey = trimReference(ref);
  let txtRpl = "n/a";

  if (questionRefMap && mapKey in questionRefMap) {
    var answersContainer = questionRefMap[mapKey];
    let answersFound = false;
    answersFound = answersContainer && answersContainer.RepeatNumber === repeatNumber && answersContainer.ParentRepeatNumber === parentRepeatNumber;

    //sectionReferenceName = sectionReferenceName != null ? sectionReferenceName.trim() : sectionReferenceName;
    if (!answersFound) answersFound = answersContainer && answersContainer.RepeatNumber === parentRepeatNumber && answersContainer.ParentRepeatNumber === null;

    if (answersContainer && ref.match(_datePreviousQuestionnaireReferenceREGEX)) answersFound = true;

    if (answersFound) {
      if (answersContainer.Answers) {
        let cascadingLines = extractCascadingLines(ref);
        txtRpl = formatAnswers(answersContainer.Answers, cascadingLines);
      } else if (answersContainer.MultipleChoiceAnswers) {
        txtRpl = formatAnswersMC(answersContainer.MultipleChoiceAnswers);
      }
    }
  }

  return txtRpl;
};

export const trimReference = (str) => {
  let trimed = str.replace("{{", "").replace("}}", "");
  trimed = trimed.replace(_cascadingLinesREGEX, "");

  return trimed.trim().toLowerCase();
};

export const extractCascadingLines = (ref) => {
  let trimed = ref.replace("{{", "").replace("}}", "");
  trimed = trimed.trim().toLowerCase();

  let match = trimed.match(_cascadingLinesREGEX);

  if (match && match.length) {
    let brackets = match[0];
    let digits = brackets.match(/\d+/g);

    if (digits && digits.length) {
      let digitsArray = [];
      digits.forEach((d) => {
        var zIndexLine = parseInt(d, 10);
        if (!isNaN(zIndexLine) && zIndexLine > 0) digitsArray.push(zIndexLine);
      });

      return digitsArray;
    }
  }

  return null;
};

export const formatAnswers = (answers, cascadingLines) => {
  let isCascading = _.some(answers, (x) => !_.isEmpty(x.CascadingListAnswerValue) || !_.isEmpty(x.AnswerValue.value));
  if (cascadingLines) answers = _.filter(answers, (x) => _.includes(cascadingLines, x.Order));

  let answer = "";

  if (isCascading) {
    if (answers.length === 1) {
      let answerValue = !_.isEmpty(answers[0].AnswerValue.value) ? answers[0].AnswerValue.value : answers[0].AnswerValue;
      answer = answer + ` ${answerValue}`;
    } else {
      _.orderBy(answers, "Order").forEach((a) => {
        let answerValue = _.isObjectLike(a.AnswerValue) ? a.AnswerValue.value : a.AnswerValue;
        answer = answer + `<div>${a.SubQuestionName}: ${answerValue}</div>`;
      });
    }
  } else {
    _.orderBy(answers, "Order").forEach((a) => {
      let answerValue = a.SubQuestionName.toLowerCase().includes("date") ? formatDateAnswer(a.AnswerValue) : a.AnswerValue;
      answer = _.isEmpty(answer) ? answer + answerValue : ";" + answer + answerValue;
    });
  }

  return answer;
};
export const formatAnswersMC = (answers) => {
  let answer = "";
  _.orderBy(answers, "order").forEach((a) => {
    if (_.isEmpty(a.optionText)) {
      let firstAnswer = "";
      let secondAnswer = "";
      if (a.answers != null) {
        if (!_.isEmpty(a.answers[0])) {
          firstAnswer = a.answers[0].SubQuestionName.toLowerCase().includes("date") ? formatDateAnswer(a.answers[0].AnswerValue) : a.answers[0].AnswerValue;
        }
        if (!_.isEmpty(a.answers[1])) {
          secondAnswer = a.answers[1].SubQuestionName.toLowerCase().includes("date") ? formatDateAnswer(a.answers[1].AnswerValue) : a.answers[1].AnswerValue;
        }
      }
      let firstText = a.firstText ? a.firstText : "";
      let secondText = a.secondText ? a.secondText : "";
      let lastText = a.lastText ? a.lastText : "";

      answer = answer + `; ${firstText} ${firstAnswer} ${secondText} ${secondAnswer} ${lastText}`;
    } else answer = answer + `${a.optionText};`;
  });
  return answer;
};

export const updateOrInsertThenDispatch = (dispatch, questionReferencesMap, mapKeys, repeatNumber, parentRepeatNumber, fullName, answers, cultureCode, option, checked, isExclusive, archived) => {
  _.forEach(mapKeys, function (key) {
    let qReferences = questionReferencesMap[key];

    if (key in questionReferencesMap) {
      let existingRefAnswer = _.find(qReferences, (qRef) => {
        return qRef != null && qRef.RepeatNumber === repeatNumber && qRef.ParentRepeatNumber === parentRepeatNumber;
      });

      if (_.isEmpty(existingRefAnswer)) {
        //if not found, try to find reference in level above -- IMPORTANT: will need to be changed when all paths of parentRepeatNrs will be added
        existingRefAnswer = _.find(qReferences, (qRef) => {
          return qRef != null && qRef.RepeatNumber === parentRepeatNumber;
        });
      }

      if (existingRefAnswer) {
        if (existingRefAnswer.Answers) {
          existingRefAnswer = { ...existingRefAnswer, Answers: definePreviousAnswers(answers) };
        } else if (existingRefAnswer.MultipleChoiceAnswers) {
          existingRefAnswer = {
            ...existingRefAnswer,
            MultipleChoiceAnswers: definePreviousOptionAnswers(option, checked, isExclusive, answers, existingRefAnswer.MultipleChoiceAnswers, cultureCode),
          };
        }

        questionReferencesMap[key] = _.map(qReferences, (x) => {
          if (x.RepeatNumber === repeatNumber && x.ParentRepeatNumber === parentRepeatNumber) x = existingRefAnswer;
          return x;
        });

        dispatch({
          type: UPDATE_QUESTION_REF_MAP,
          payload: {
            repeatNumber,
            parentRepeatNumber,
            fullName,
            prevAnswer: existingRefAnswer,
          },
        });
      } else {
        let prevAnswer = {};
        prevAnswer.RepeatNumber = repeatNumber;
        prevAnswer.ParentRepeatNumber = parentRepeatNumber;
        if (option) {
          prevAnswer.Archived = archived;
          prevAnswer.MultipleChoiceAnswers = definePreviousOptionAnswers(option, checked, isExclusive, answers, [], cultureCode);
        } else {
          prevAnswer.Answers = definePreviousAnswers(answers);
        }
        if (!questionReferencesMap[key]) questionReferencesMap[key] = [];
        questionReferencesMap[key].push(prevAnswer);

        dispatch({
          type: UPDATE_QUESTION_REF_MAP,
          payload: {
            repeatNumber,
            parentRepeatNumber,
            fullName,
            prevAnswer,
          },
        });
      }
    }
  });
};

const definePreviousAnswers = (answers) => {
  let transformedAnswers = [];
  _.forEach(answers, (a) => {
    var prevAns = {};
    if (a.AnswerValue && typeof a.AnswerValue == "object") {
      prevAns.CascadingListAnswerValue = { ...a.AnswerValue };
      prevAns.SubQuestionName = a.SubQuestionName;
      prevAns.Order = a.Order;
      prevAns.AnswerValue = a.AnswerValue.value;
    } else prevAns = { ...a };
    transformedAnswers.push(prevAns);
  });
  return transformedAnswers;
};

const definePreviousOptionAnswers = (option, checked, isExclusive, answers, existingAnswers, cultureCode) => {
  let transformedAnswers = [...existingAnswers];
  if (checked) {
    if (isExclusive) {
      transformedAnswers = [];
    }
    let optionObject = defineOptionObject(option, cultureCode);
    optionObject.answers = [...answers];
    transformedAnswers.push(optionObject);
  } else {
    transformedAnswers = _.filter(transformedAnswers, (optionAnswer) => {
      return optionAnswer.optionText !== option.AnswerText && optionAnswer.optionValue !== option.AnswerValue;
    });
  }
  return transformedAnswers;
};
