import { SET_QUESTIONNAIRE_NAME } from "../../actions/userQuestionnaire/setQuestionnaireName";

export default function (state = null, action) {
  switch (action.type) {
    case SET_QUESTIONNAIRE_NAME:
      return action.payload;
    default:
      return state;
  }
}
