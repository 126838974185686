import { enableES5, produce } from "immer";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import { GET_FAQ } from "../actions/faq/getFaq";
import { HANDLE_FAQ_DROP } from "../actions/faq/handleFaqDrop";
import { CHANGE_FAQ_CONTENT } from "../actions/faq/changeFaqContent";
import { DELETE_FAQ } from "../actions/faq/deleteFaq";
import { ADD_NEW_FAQ } from "../actions/faq/addNewFaq";

const initState = [];
enableES5();
export default produce((draft, action) => {
  switch (action.type) {
    case GET_FAQ: {
      return action.payload;
    }
    case HANDLE_FAQ_DROP: {
      let { dragId, dropId } = action.payload;
      const dragBox = _.find(draft, (x) => {
        return x.ID == dragId;
      });
      const dropBox = _.find(draft, (x) => {
        return x.ID == dropId;
      });
      const dragBoxOrder = dragBox.Order;
      const dropBoxOrder = dropBox.Order;
      dragBox.Order = dropBoxOrder;
      dropBox.Order = dragBoxOrder;
      return;
    }
    case CHANGE_FAQ_CONTENT: {
      let { faq, value, type } = action.payload;
      let draftFaq = _.find(draft, (x) => {
        return x.ID == faq.ID;
      });
      if (draftFaq) {
        if (type == "title") draftFaq.Title = value;
        if (type == "content") draftFaq.Content = value;
      }
      return;
    }
    case DELETE_FAQ: {
      let { faqId } = action.payload;
      let draftFaq = _.find(draft, (x) => {
        return x.ID == faqId;
      });
      if (draftFaq) {
        let faqOrder = draftFaq.Order;
        _.map(draft, (x) => {
          if (x.Order > faqOrder) {
            x.Order = x.Order - 1;
          }
        });
        draftFaq.Deleted = true;
      }
      return;
    }
    case ADD_NEW_FAQ: {
      let { studyId, culture } = action.payload;
      let notDeleted = _.filter(draft, (x) => {
        return x.Deleted == null || x.Deleted == false;
      });
      let maxOrderItem = _.maxBy(notDeleted, "Order");
      let faq = {
        ID: uuidv4(),
        Title: "What is Lorem Ipsum?",
        Content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        StudyId: studyId,
        CultureId: culture,
        Order: maxOrderItem ? maxOrderItem.Order + 1 : 1,
      };
      draft.push(faq);
      return;
    }
    default:
      return;
  }
}, initState);
