import nl from "./nl-NL.json";
import en from "./en-GB.json";
import de from "./de-DE.json";
import fr from "./fr-FR.json";
import it from "./it-IT.json";

export const default_culture = "nl-NL";

const resources = {
  "nl-NL": nl,
  "en-GB": en,
  "de-DE": de,
  "fr-FR": fr,
  "it-IT": it,
};

export const res = (culture, resKey) => {
  if (!culture) throw new Error("no culture provided for localization");
  if (!resKey) throw new Error("no resource key provided for localization");
  if (!(culture in resources)) throw new Error(`"no localization found for culture ${culture}"`);

  let culture_res = resources[culture];
  if (resKey in culture_res) return culture_res[resKey];
  else {
    console.warn(`no resource found for culture ${culture} and key:${resKey}`);
    return "";
  }
};
