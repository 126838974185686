import React from "react";
import Loader from "react-loader-spinner";

const Loading = ({ visible }) => {
  return (
    <div className="loading-wrapper">
      <Loader visible={visible} type="Watch" color="#00BFFF" height={100} width={100} timeout={7000} />
    </div>
  );
};

export default Loading;
