import api from "../../assets/common/api/api";
import _ from "lodash";
import moment from "moment";
import { selectPage } from "./selectPage";
import setCultureCode from "./setCultureCode";
import setLastOpenedPageId from "./setLastOpenedPageId";
import setCurrentDate from "./setCurrentDate";
import setCategoriesMap from "./setCategoriesMap";
import setTextCategories from "./setTextCategories";
import setNumericCategories from "./setNumericCategories";
import setSectionsConditions from "./categories/setSectionsConditions";
import setCompleted from "./setCompleted";
import setRedirectUrl from "./setRedirectUrl";
import setQuestionnaireName from "./setQuestionnaireName";
import setHighlightedPages from "./setHighlightedPages";
import setHoneypot from "./setHoneypot";
import { setLoading } from "../loading/setLoading";
import history from "../../assets/common/custom/history";
import setZIndexCategories from "./setZIndexCategories";
import setShowProgressBar from "./setShowProgressBar";
export const FETCH_TOP_SECTIONS_LIST = "FETCH_TOP_SECTIONS_LIST";

export const fetch_top_section_list = (userQuestionnaireId) => async (dispatch, getState) => {
  let response = {};
  dispatch(setLoading(true));
  await api
    .get("/Questionnaire/GetMenuNav", {
      params: {
        userQuestionnaireId,
        datetime: moment().format(),
      },
    })
    .then((res) => {
      response = res;
    });

  let {
    userQuestionnaire: {
      selectedPage,
      cultureCode,
      lastPageOpenedId,
      currentDate,
      categoriesMap,
      sectionsConditions,
      completed,
      redirectUrl,
      questionnaireName,
      highlightedPages,
    },
  } = getState();

  //first set the culture code in order to filter page translation by user questionnaire culture code
  if (cultureCode == null) dispatch(setCultureCode(response.data.cultureCode));

  dispatch(setTextCategories(response.data.textCategories));
  dispatch(setNumericCategories(response.data.numericCategories));
  dispatch(setShowProgressBar(response.data.showProgressBar));
  if (_.isEmpty(sectionsConditions)) dispatch(setSectionsConditions(response.data.sectionsConditions));

  dispatch({
    type: FETCH_TOP_SECTIONS_LIST,
    payload: response.data.topSections,
  });

  if (currentDate == null) dispatch(setCurrentDate(response.data.currentDate));
  if (categoriesMap == null) dispatch(setCategoriesMap(response.data.categoriesMap));
  if (completed == null) dispatch(setCompleted(response.data.completed));
  if (redirectUrl == null) dispatch(setRedirectUrl(response.data.redirectUrl));
  if (questionnaireName == null) dispatch(setQuestionnaireName(response.data.questionnaireName));
  if (_.isEmpty(highlightedPages)) dispatch(setHighlightedPages(response.data.highlightedPages));
  dispatch(setHoneypot(response.data.honeypot));
  dispatch(setZIndexCategories(response.data.zIndexCategories));

  if (selectedPage == null && response.data.topSections) {
    var topSectionsList = Object.values(JSON.parse(response.data.topSections));
    if (response.data.completed === true) {
      var thankYouPages = _.filter(topSectionsList, (val) => {
        return val.PageType === 1;
      });
      if (thankYouPages && thankYouPages.length > 0) dispatch(selectPage(_.sortBy(thankYouPages, "SortOrder")[0]));
      else history.push(response.data.redirectUrl === "" ? "/" : response.data.redirectUrl);
    }
    //if there is not selected page (initial opening of the questionnaire, select the first page)
    else {
      let lastVisitedPage = _.find(topSectionsList, {
        ID: response.data.lastTopSectionVisitedID,
      });
      let pageToDisplay = !_.isEmpty(lastVisitedPage) ? lastVisitedPage : _.sortBy(topSectionsList, "SortOrder")[0];
      dispatch(selectPage(pageToDisplay));
    }
  }
  if (lastPageOpenedId == null) dispatch(setLastOpenedPageId(response.data.lastPageOpenedId));

  dispatch(setLoading(false));
};
