import React, { Component, lazy } from "react";
import { connect } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CustomRouter from "../../assets/common/custom/customRouter";
import history from "../../assets/common/custom/history";
import Loading from "../content/loading";
import ScrollToTopOnRouteChange from "../../assets/common/custom/scrollToTopOnRouteChange";

import getStudyData from "../../actions/study/getStudyData";

const PregnantTheme = lazy(() => import("./pregnantTheme"));
const DefaultTheme = lazy(() => import("./defaultTheme"));
const ReportFormsTheme = lazy(() => import("./reportFormsTheme"));

class App extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.getStudyData();
  }

  switchThemes() {
    switch (window.flim_config.appStudyName) {
      case "pregnant": {
        return <PregnantTheme />;
      }
      case "reportforms": {
        return <ReportFormsTheme />;
      }
      default: {
        return <DefaultTheme />;
      }
    }
  }
  render() {
    return (
      <CustomRouter history={history}>
        <React.Suspense fallback={<Loading visible={true} />}>
          <HelmetProvider>
            <ScrollToTopOnRouteChange history={history}/>
            <Helmet>
              <title>{this.props.studyName}</title>
            </Helmet>
            {this.switchThemes()}
            <ToastContainer
              position="top-right"
              autoClose={6000}
              hideProgressBar={false}
              newestOnTop={false}
              rtl={false}
              draggable
              pauseOnHover
            />
          </HelmetProvider>
        </React.Suspense>
      </CustomRouter>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    studyName: state.study.Name,
  };
};

export default connect(mapStateToProps, { getStudyData })(App);
