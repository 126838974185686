import _ from "lodash";
export let pageTextCategories = [];
export let pageNumericCategories = [];

export const setPageCategories = (textCategories, numericCategories, pageSortOrder) => {
  pageTextCategories = [];
  _.orderBy(textCategories, "SectionSortOrder", "desc").forEach((category) => {
    if (
      !_.find(pageTextCategories, (x) => {
        return x.CategoryID === category.CategoryID;
      }) &&
      category.SectionSortOrder < pageSortOrder
    )
      pageTextCategories.push(category);
  });

  _.orderBy(numericCategories, "SectionSortOrder", "desc").forEach((category) => {
    pageNumericCategories = [];
    if (
      !_.find(pageNumericCategories, (x) => {
        return x.CategoryID === category.CategoryID;
      }) &&
      category.SectionSortOrder < pageSortOrder
    )
      pageNumericCategories.push(category);
  });
};
