import setUserQuestionnaireIdReducer from "./setUserQuestionnaireIdReducer";
import pageMenuNavReducer from "./pageMenuNavReducer";
import selectPageReducer from "./selectPageReducer";
import pageDesignReducer from "./pageDesignReducer";
import cultureCodeReducer from "./cultureCodeReducer";
import lastOpenedPageIdReducer from "./lastOpenedPageReducer";
import currentDateReducer from "./curentDateReducer";
import categoriesMapReducer from "./categoriesMapReducer";
import textCategoriesReducer from "./textCategoriesReducer";
import numericCategoriesReducer from "./numericCategoriesReducer";
import sectionsConditionsReducer from "./sectionsConditionsReducer";
import setCompletedReducer from "./setCompletedReducer";
import setRedirectUrlReducer from "./setRedirectUrlReducer";
import previousMCAnswersReducer from "./previousMCAnswersReducer";
import previousQuestionsReducer from "./previousQuestionsReducer";
import previousSectionsReducer from "./previousSectionsReducer";
import questionReferencesReducer from "./questionReferencesReducer";
import questionnaireNameReducer from "./setQuestionnaireName";
import highlightedPagesReducer from "./highlightedPagesReducer";
import honeypotReducer from "./honeypotReducer";
import zIndexCategoriesReducer from "./zIndexCategoriesReducer";
import showProggressBarReducer from "./showProgressBarReducer";
//export new object holder OR you can wrap these with combineReducers from 'redux';

export default (state = {}, action) => {
  return {
    id: setUserQuestionnaireIdReducer(state.id, action),
    cultureCode: cultureCodeReducer(state.cultureCode, action),
    lastPageOpenedId: lastOpenedPageIdReducer(state.lastPageOpenedId, action),
    pageList: pageMenuNavReducer(state.pageList, action),
    selectedPage: selectPageReducer(state.selectedPage, action),
    pageDesign: pageDesignReducer(state.pageDesign, action),
    currentDate: currentDateReducer(state.currentDate, action),
    categoriesMap: categoriesMapReducer(state.categoriesMap, action),
    textCategories: textCategoriesReducer(state.textCategories, action),
    numericCategories: numericCategoriesReducer(
      state.numericCategories,
      action
    ),
    sectionsConditions: sectionsConditionsReducer(
      state.sectionsConditions,
      action
    ),
    completed: setCompletedReducer(state.completed, action),
    redirectUrl: setRedirectUrlReducer(state.redirectUrl, action),
    previousMCAnswers: previousMCAnswersReducer(
      state.previousMCAnswers,
      action
    ),
    previousQuestions: previousQuestionsReducer(
      state.previousQuestions,
      action
    ),
    previousSections: previousSectionsReducer(state.previousSections, action),
    questionReferences: questionReferencesReducer(
      state.questionReferences,
      action
    ),
    questionnaireName: questionnaireNameReducer(
      state.questionnaireName,
      action
    ),
    highlightedPages: highlightedPagesReducer(state.highlightedPages, action),
    honeypot: honeypotReducer(state.honeypot, action),
    zIndexCategories: zIndexCategoriesReducer(state.zIndexCategories, action),
    showProgressBar: showProggressBarReducer(state.showProgressBar, action),
  };
};
