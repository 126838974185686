import api from "../../assets/common/api/api";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { setLoading } from "../loading/setLoading";

export const CHANGE_WEBSITE_CONTENT_UPLOADS = "CHANGE_WEBSITE_CONTENT_UPLOADS";

export const changeWebSiteContentUploads =
  (id, selectedFile, name, order, url, html, webContentType, deleted) => async (dispatch, getState) => {
    let { study } = getState();
    setLoading(true);
    let webContentId = id != null ? id : uuidv4();
    const formData = new FormData();
    formData.append("selectedFile", selectedFile);
    formData.append("id", webContentId);
    formData.append("webContentType", webContentType);
    formData.append("name", name);
    formData.append("value", encodeURI(html));
    formData.append("order", order);
    formData.append("url", url);
    formData.append("deleted", deleted);
    formData.append("studyId", study.StudyId);
    formData.append("culture", study.Culture);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    await api
      .post("/study/ChangeWebSiteContentUplodas", formData, config)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          if (response.data && response.data.success) {
            dispatch({
              type: CHANGE_WEBSITE_CONTENT_UPLOADS,
              payload: {
                id: webContentId,
                name: name,
                value: html,
                order: order,
                url: url,
                file: response.data.fileString,
                contentType: response.data.contentType,
                fileName: response.data.fileName,
                webContentType,
                culture: study.Culture,
              },
            });
          } else toast.error("Error while changing site content");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Error while changing site content");
      });
  };
