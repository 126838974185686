import api from "../../assets/common/api/api";
import { toast } from "react-toastify";
import _ from "lodash";
import history from "../../assets/common/custom/history";
import { setLoading } from "../loading/setLoading";
import { res } from "../../assets/localization/resources";

export const GET_FAQ = "GET_FAQ";

export const getFaq = () => async (dispatch, getState) => {
  let { study } = getState();
  setLoading(true);

  await api
    .get(`/frequentlyaskedquestions/GetFrequentlyAskedQuestions?studyId=${study.StudyId}&cultureId=${study.Culture}`)
    .then((response) => {
      setLoading(false);
      if (response.status === 200) {
        let responseData = response.data;
        if (_.isEmpty(responseData)) {
          toast.error(res(study.Culture, "FAQContentEmpty"));
        } else {
          dispatch({ type: GET_FAQ, payload: response.data });
        }
      }
    })
    .catch((error) => {
      setLoading(false);
      toast.error(res(study.Culture, "ErrorOcurred"));
      history.push("/pages/error");
    });
};
