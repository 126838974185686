import _ from "lodash";
import { enableES5, produce } from "immer";

import { INIT_QUESTION_REF_MAP } from "../../actions/tempStorage/questionReferences/initRef";
import { UPDATE_QUESTION_REF_MAP } from "../../actions/tempStorage/questionReferences/updateRef";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";

const initState = {};
enableES5();
export default produce((draft, action) => {
  switch (action.type) {
    case INIT_QUESTION_REF_MAP: {
      let { objKey, references } = action.payload;
      draft[objKey] = references;
      return;
    }
    case UPDATE_QUESTION_REF_MAP: {
      let { repeatNumber, parentRepeatNumber, fullName, prevAnswer } = action.payload;
      let keys = _.filter(Object.keys(draft), (key) => {
        let parentRepeatNum = parentRepeatNumber == null ? "null" : parentRepeatNumber;
        let matches = _.includes(key, `||${repeatNumber}||${parentRepeatNum}`);
        if (matches) return true;

        let parentsFound = _.endsWith(key, `||${repeatNumber}`); //fix when the questions are not in same section
        return parentsFound;
      });
      for (let key of keys) {
        if (draft[key][fullName] === undefined) continue;

        if (prevAnswer) {
          draft[key][fullName] = prevAnswer;
        }
      }

      //special scenario to cover referencing question from non repeating section into repeating section
      //requested by Lareb
      if (repeatNumber == 0 && parentRepeatNumber == null) {
        for (let key of Object.keys(draft)) {
          if (draft[key][fullName] === undefined) continue;

          if (!_.endsWith(key, `||${repeatNumber}||${parentRepeatNumber}`)) {
            if (prevAnswer) {
              const splitKey = key.split("||");
              draft[key][fullName] = { ...prevAnswer, RepeatNumber: parseInt(splitKey[1]), ParentRepeatNumber: splitKey[2] == "null" ? null : parseInt(splitKey[2]) };
            }
          }
        }
      }

      return;
    }
    case SET_USER_QUESTIONNAIRE_ID: {
      return initState;
    }
    default:
      return;
  }
}, initState);
