import { LOGIN_SUCCESS, LOGIN_FAILURE } from "../actions/authentication/authenticate";
import { LOGOUT } from "../actions/authentication/logout";
import { ON_COUNT_DOWN_FINISH } from "../actions/authentication/onCountdownFinish";

let user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { loggedIn: true, user, message: "", lockoutSecondsRemaining: 0 }
  : { loggedIn: false, user: null, message: "", lockoutSecondsRemaining: 0 };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, message: "success", loggedIn: true, user: action.payload.User };
    case LOGOUT:
    case LOGIN_FAILURE: {
      let { message, lockoutSecondsRemaining } = action.payload;
      return {
        ...state,
        loggedIn: false,
        user: null,
        message: message ?? "",
        lockoutSecondsRemaining: lockoutSecondsRemaining ?? 0,
      };
    }

    case ON_COUNT_DOWN_FINISH: {
      return {
        ...state,
        message: "",
        lockoutSecondsRemaining: 0,
      };
    }

    default:
      return state;
  }
}
