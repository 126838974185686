export const DELETE_REPEAT = "DELETE_REPEAT";

let deleteRepeat = (section, repeatId) => (dispatch, getState) => {
  let isArchived = getState().sections[section.ID].repeats[repeatId].archived;
  let { selectedPage } = getState().userQuestionnaire;

  dispatch({
    type: DELETE_REPEAT,
    payload: { section, repeatId, isArchived, selectedPage },
  });
};

export default deleteRepeat;
