import { SET_CULTURE_CODE } from "../../actions/userQuestionnaire/setCultureCode";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";

export default function (state = null, action) {
  switch (action.type) {
    case SET_CULTURE_CODE:
      return action.payload;
    case SET_USER_QUESTIONNAIRE_ID: {
      return null;
    }
    default:
      return state;
  }
}
