import _ from "lodash";
import moment from "moment";
import { checkDateInThePast } from "../validationRules/validationRules";
import { QuestionContextVariableSrcENUM, TranslationKey } from "../Enums";
import { parseRererences } from "./questionReferencesUtil";

// convert QuestionViewParameters from Array to Dictionary
export const getQuestionViewParameters = (questionDTO, cultureCode) => {
  var viewparams = [];

  if (questionDTO.QuestionView && questionDTO.QuestionView.Parameters) {
    var parameters = questionDTO.QuestionView.Parameters;

    if (parameters && parameters.length) {
      let params = {};

      _.each(parameters, (param) => {
        if (!_.isEmpty(param.QuestionParameterValues)) {
          var paramValue = _.first(param.QuestionParameterValues);

          let translation = getComponentTranslationsText(cultureCode, paramValue.Translations, TranslationKey.QuestionParameterValue);
          if (!_.isEmpty(translation)) params[param.Name] = translation;
          else params[param.Name] = "";
        }
      });

      viewparams[questionDTO.QuestionView.Name] = params;
    }
  }
  return viewparams;
};

/**
 *
 * @param {string} SubQuestionName sub question name of the answer i.e 'Text', 'Number', 'Date'
 * @param {string} AnswerValue
 * @param {int} order answer order
 */
export const singleAnswerObject = (SubQuestionName, AnswerValue, order) => {
  var answer = {
    SubQuestionName,
    AnswerValue,
    Order: order === undefined ? 1 : order,
  };
  return answer;
};

/**
 *
 * @param {string} SubQuestionName sub question name of the answer i.e 'Text', 'Number', 'Date'
 * @param {string} AnswerValue
 * @param {int} order answer order
 * @param {bool} IsConfirmButtonClick only available for custom birthday question
 */
export const singleBirthDayAnswerObject = (SubQuestionName, AnswerValue, order, IsConfirmButtonClick) => {
  var answer = {
    SubQuestionName,
    AnswerValue,
    IsConfirmButtonClick,
    Order: order === undefined ? 1 : order,
  };
  return answer;
};

/**
 *
 * @param {string} answers question answers list
 * @param {string} SubQuestionName sub question name of the answer i.e 'Text', 'Number', 'Date'
 */
export const getAnswerBySubQuestionName = (answers, subQuestionName) => {
  if (!answers) return null;
  return _.find(answers, {
    SubQuestionName: subQuestionName,
  });
};

/**
 *
 * @param {object} answers question answer
 */
export const defineDayMonthYear = (answer) => {
  if (answer && answer.length === 8) {
    return {
      day: answer.substring(6, 8),
      month: answer.substring(4, 6),
      year: answer.substring(0, 4),
    };
  }
  return null;
};

/**
 *
 * @param {array} options question answer options
 * @param {array} selectedOptionId selected answer option ID
 */
export const selectedAnswerOption = (options, selectedOptionId) => {
  return _.find(options, (option) => {
    return option.ID === selectedOptionId;
  });
};
/**
 *
 * @param {array} options question answer options
 * @param {object} selectedOption selected answer option
 * @param {bool} checked if selected option is checked (this property is only logical for checkbox input)
 * @param {array} selectedOptionAnswers answers provided for the selected answer option
 * @param {object} selectedOptionsIds selected answer options for the question
 */
export const shouldArchiveRepeat = (selectedOption, checked, exclusive, currentDate, options, questionAnswers, selectedOptionAnswers = []) => {
  let shouldArchive = calulateArchived(selectedOption, selectedOptionAnswers, currentDate);
  if (shouldArchive) return true;

  if (!exclusive && !checked) {
    let selectedOptionsIds = questionAnswers != null ? Object.keys(questionAnswers) : null;
    if (selectedOptionsIds) {
      let previousSelectedArchive = _.find(options, (option) => {
        return selectedOptionsIds.includes(option.ID) && option.ID !== selectedOption.ID && calulateArchived(option, questionAnswers[option.ID].answers, currentDate);
      });
      if (previousSelectedArchive) return true;
    }
  }

  if (
    _.find(options, (x) => {
      return x.ArchiveRepeatDateInPast || x.ArchiveRepeatWithIntegerRange || x.ArchiveRepeat;
    }) != null
  )
    return false;
  else return null;
};

const calulateArchived = (option, optionAnswers, currentDate) => {
  if (option.ArchiveRepeat) return true;

  if (optionAnswers) {
    if (option.ArchiveRepeatDateInPast) {
      if (optionAnswers.length === 0) return true;

      if (optionAnswers.length === 1) {
        let answer = _.find(optionAnswers, { SubQuestionName: "Text" });
        if (!_.isEmpty(answer) && !_.isEmpty(answer.AnswerValue) && checkDateInThePast(optionAnswers[0].AnswerValue, currentDate)) {
          return true;
        }

        let answerDate2 = _.find(optionAnswers, { SubQuestionName: "Date2" });
        if (!_.isEmpty(answerDate2) && !_.isEmpty(answerDate2.AnswerValue) && checkDateInThePast(answerDate2.AnswerValue, currentDate)) {
          return true;
        }
      }

      if (optionAnswers.length > 1 && _.isEmpty(optionAnswers[0].AnswerValue) && _.isEmpty(optionAnswers[1].AnswerValue)) return true;

      if (optionAnswers.length > 1 && !_.isEmpty(optionAnswers[1].AnswerValue) && checkDateInThePast(optionAnswers[1].AnswerValue, currentDate)) return true;
    }
    if (option.ArchiveRepeatWithIntegerRange) {
      if (optionAnswers.length === 0) return true;
      if (optionAnswers.length > 1 && _.isEmpty(optionAnswers[0].AnswerValue) && _.isEmpty(optionAnswers[1].AnswerValue)) return true;

      if (optionAnswers.length > 1) {
        const firstAnswer = _.find(optionAnswers, {
          SubQuestionName: "Number1",
        });
        const secondAnswer = _.find(optionAnswers, {
          SubQuestionName: "Number2",
        });
        if (!_.isEmpty(firstAnswer) && !_.isEmpty(secondAnswer) && _.isEmpty(firstAnswer.AnswerValue) && _.isEmpty(secondAnswer.AnswerValue)) return true;
        if (!_.isEmpty(firstAnswer) && !_.isEmpty(secondAnswer) && _.isEmpty(firstAnswer.AnswerValue) && !_.isEmpty(secondAnswer.AnswerValue)) return true;
      }

      const secondAnswer = _.find(optionAnswers, {
        SubQuestionName: "Number2",
      });
      if (optionAnswers.length === 1 && !_.isEmpty(secondAnswer)) return true;
    }
  }

  return false;
};

/**
 *
 * @param {array} answerOptionParameters answer option parameters
 * @param {string} key key to search specific answer option parameter
 */
export const getAnswerOptionParameterValue = (answerOptionParameters, key, cultureCode, translationKey) => {
  let parameter = _.find(answerOptionParameters, (parameter) => {
    return parameter.Name === key;
  });
  if (!_.isEmpty(parameter)) {
    return getComponentTranslationsText(cultureCode, parameter.Translations, translationKey);
  } else return null;
};

export const contextVariableReplace = (question, questionRefMap, repeatNumber, parentRepeatNumber, sectionReferenceName, cultureCode, questionContextVariableSrcENUM) => {
  //var qFullName = `${questionDTO.EnclosingSectionName}.${questionDTO.ShortName}`.toLowerCase().trim();

  var text =
    questionContextVariableSrcENUM === QuestionContextVariableSrcENUM.QuestionText
      ? getComponentTranslationsText(cultureCode, question.Translations, TranslationKey.QuestionText)
      : getComponentTranslationsText(cultureCode, question.Translations, TranslationKey.QuestionHelpText);

  if (text) text = parseRererences(text, questionRefMap, repeatNumber, parentRepeatNumber, sectionReferenceName);

  return text;
};

/**
 * resolve reference define in the validation rule as validation rule value
 * @param {string} validationRuleValue validation rule value
 * @param {object} questionRefMap question reference map
 * @param {int} repeatNumber repeat number in which question belongs
 * @param {int} parentRepeatNumber parent repeat number in which question belongs
 * @param {string} sectionReferenceName section reference name in which question belongs
 */
export const resolveValidationRuleValue = (validationRuleValue, questionRefMap, repeatNumber, parentRepeatNumber, sectionReferenceName) => {
  if (validationRuleValue) validationRuleValue = parseRererences(validationRuleValue, questionRefMap, repeatNumber, parentRepeatNumber, sectionReferenceName);

  return validationRuleValue;
};

/**
 *
 * @param {string} answer answer that need to be format as date
 */
export const formatDateAnswer = (answer) => {
  const answerDate = moment(answer, "YYYYMMDD");
  if (answerDate.isValid()) {
    return answerDate.format("DD-MM-YYYY");
  } else return answer;
};

/**
 * return parent section id if the current section has empty string as reference name and if section need to show follow up or new repeats
 * @param {object} section current section
 */
export const getParentSectionId = (section, parentSectionId) => {
  let sectionReferenceName = section != null && section.SectionDTO.ReferenceName != null ? section.SectionDTO.ReferenceName.trim() : null;
  return _.isEmpty(sectionReferenceName) && (section.SectionDTO.ShowOnlyInNewRepeatSection || section.SectionDTO.ShowOnlyInFollowUpRepeatSection) ? parentSectionId : null;
};
/**
 * return parent section if the current section has empty string as reference name and if section need to show follow up or new repeats
 * @param {object} section current section
 * @param {object} parentSection parent section
 */
export const getRepeatedSection = (section, parentSection) => {
  let sectionReferenceName = section != null && section.SectionDTO.ReferenceName != null ? section.SectionDTO.ReferenceName.trim() : null;
  return _.isEmpty(sectionReferenceName) && (section.SectionDTO.ShowOnlyInNewRepeatSection || section.SectionDTO.ShowOnlyInFollowUpRepeatSection) ? parentSection : section;
};

/**
 * return true if the array has at least one answer and AnswerValue is not null or empty string
 * @param {array} answers question answers
 */
export const checkIfQuestionHasAnswers = (answers) => {
  if (answers.length > 0 && _.every(answers, { AnswerValue: { value: "" } })) return false;

  if (answers.length > 0 && !_.some(answers, { AnswerValue: null }) && !_.some(answers, { AnswerValue: "" })) return true;
  return false;
};

/**
 * return true if the array has at least one non empty answer for "Other" textbox
 * @param {array} answers question answers
 */
export const checkIfCascadingQuesitonHasOtherAnswer = (answers) => {
  return (
    answers.length > 0 &&
    _.some(answers, (a) => {
      return a.AnswerValue.value !== "" && a.AnswerValue.isOtherText === true;
    })
  );
};

/**
 * return true if all cascading answers are empty
 * @param {answers} answers question answers
 */
export const checkcheckIfCascadingQuesitonAllAnswersEmpty = (answers) => {
  let isEmpty;

  if (answers.length > 0) {
    isEmpty = _.every(answers, (a) => {
      return _.isEmpty(a.AnswerValue.value);
    });
  } else isEmpty = true;

  return isEmpty;
};

export const fillCascadingEmptyAnswers = (currentLevelNr, levels, answers) => {
  var restLevels = _.filter(levels, (l) => l.SortOrder > currentLevelNr);

  restLevels.forEach((l) => {
    let subQuestionName = l.Name;
    let order = l.SortOrder + 1;
    let emptyAnswer = { itemId: null, value: "", isOtherText: false };

    answers.push(singleAnswerObject(subQuestionName, emptyAnswer, order)); //add emptyString for all unanswered levels
  });
};

/**
 * return translation for given culture code and key
 * @param {string} cultureCode culture code
 * @param {array} translations component translations
 * @param {enum(string)} key translation key
 */
export const getComponentTranslationsText = (cultureCode, translations, key) => {
  if (!translations) return "";
  if (translations.length === 0) return "";

  var translationResult = _.find(translations, (x) => {
    return x.Key === key && x.Culture === cultureCode;
  });

  if (!_.isEmpty(translationResult)) {
    return translationResult.TranslationText;
  } else {
    return "";
  }
};

export const getDescendantMCquestionsInSection = (sectionComponents) => {
  let mcQuestions = [];
  traverseComponentsExtractMcQuesitons(sectionComponents, mcQuestions);
  return mcQuestions;
};

function traverseComponentsExtractMcQuesitons(sectionComponents, mcQuestions) {
  var foundMcQuestions = sectionComponents.filter((c) => c.QuestionDTO != null && c.QuestionDTO.QuestionType === "MultipleChoiceQuestion");

  _.forEach(foundMcQuestions, (q) => mcQuestions.push(q));

  var sections = sectionComponents.filter((c) => c.SectionDTO != null);
  sections.forEach((s) => {
    traverseComponentsExtractMcQuesitons(s.SectionDTO.SectionContentTemplate.Components, mcQuestions);
  });
}
export const getHighlightedItemsToRemove = (section) => {
  let itemsToRemove = [];
  getHighlightedItems([section], itemsToRemove);
  return itemsToRemove;
};

function getHighlightedItems(sectionComponents, itemsToRemove) {
  var sections = sectionComponents.filter((c) => c.SectionDTO != null);
  sections.forEach((s) => {
    itemsToRemove.push(s.ID);
    getHighlightedItems(s.SectionDTO.SectionContentTemplate.Components, itemsToRemove);
  });
}

export const currentSelectedOptionIsExclusive = (options, answers) => {
  let optionIds = answers ? Object.keys(answers) : [];
  let selectedOptions = _.filter(options, (option) => {
    return optionIds.includes(option.ID);
  });
  if (selectedOptions.length === 1 && selectedOptions[0].Exclusive) return true;
  return false;
};
