import api from "../../assets/common/api/api";
import { setLoading } from "../loading/setLoading";
export const GET_STUDY_DATA = "GET_STUDY_DATA";

let getStudyData = () => async (dispatch, getState) => {
  setLoading(true);
  const response = await api.get(`/study/GetStudyData`);
  if (response.status === 200) {
    setLoading(false);
    dispatch({ type: GET_STUDY_DATA, payload: response.data });
  }
};

export default getStudyData;
