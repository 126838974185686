import { TranslationKey } from "../Enums";
import { getAnswerOptionParameterValue, getComponentTranslationsText } from "../Utils/questionComponentUtil";
import { trimReference } from "../Utils/questionReferencesUtil";
import _ from "lodash";

const defaultQuestionObject = (
  component,
  referenceName,
  repeatNumber,
  parentRepeatNumber,
  previousQuestions,
  cultureCode
) => {
  let previousQuestion = null;
  if (!_.isEmpty(previousQuestions)) {
    previousQuestion =
      previousQuestions[
        `${component.QuestionDTO.EnclosingSectionName}||${component.QuestionDTO.ShortName}||${repeatNumber}||${
          parentRepeatNumber == null ? 0 : parentRepeatNumber
        }`
      ];
    if (_.isEmpty(previousQuestion) && _.isEmpty(referenceName.trim()))
      previousQuestion =
        previousQuestions[
          `${component.QuestionDTO.EnclosingSectionName}||${component.QuestionDTO.ShortName}||${parentRepeatNumber}||0`
        ];
  }

  let answers = {};
  let duplicateOptions = null;

  if (!_.isEmpty(previousQuestion)) {
    if (previousQuestion.Answers != null) {
      answers = _.map(previousQuestion.Answers, (answer) => {
        if (answer.CascadingListAnswerValue != null)
          return {
            SubQuestionName: answer.SubQuestionName,
            AnswerValue: { ...answer.CascadingListAnswerValue },
            Order: answer.Order,
          };
        return answer;
      });
    }
    if (previousQuestion.MultipleChoiceAnswers != null) {
      for (let answer of previousQuestion.MultipleChoiceAnswers) {
        let option = _.find(component.QuestionDTO.AnswerOptions, (option) => {
          return option.AnswerValue === answer.optionValue && !answer.duplicated;
        });
        if (option == null && answer.duplicated) {
          option = _.find(previousQuestion.DuplicateAnswerOptions, (option) => {
            return option.AnswerValue === answer.optionValue && option.SortOrder === answer.order;
          });
        }
        if (option != null) {
          answers = { ...answers, [option.ID]: defineOptionObject(option, cultureCode) };
          if (!_.isEmpty(answer.answers)) answers[option.ID].answers = [...answer.answers];
        }
      }
    }

    if (previousQuestion.DuplicateAnswerOptions != null) {
      duplicateOptions = [...previousQuestion.DuplicateAnswerOptions];
    }
  }

  var questionObject = {
    id: component.ID,
    answers,
  };

  if (duplicateOptions != null) questionObject = { ...questionObject, duplicateOptions };
  return questionObject;
};

const defaultHiddenQuestionObject = (id) => {
  return {
    id,
    answers: [],
  };
};

const defaultSummaryQuestionObject = (component, previousQuestions) => {
  let sectionName = component.QuestionDTO.TargetSectionName;
  let answers = [];
  if (!_.isEmpty(previousQuestions)) {
    _.forEach(component.QuestionDTO.SummaryQuestionColumnDefinitions, (column) => {
      if (!_.isEmpty(column.Content)) {
        let questionShortName = trimReference(column.Content);
        let keys = _.filter(Object.keys(previousQuestions), (key) => {
          return _.startsWith(key, `${sectionName}||${questionShortName}||`);
        });
        for (let key of keys) {
          let previousQuestion = previousQuestions[key];
          if (!_.isEmpty(previousQuestion)) {
            answers = [...answers, previousQuestion];
          }
        }
      }
    });
  }

  return {
    id: component.ID,
    answers,
  };
};

export const defineOptionObject = (option, cultureCode) => {
  if (option.ShowQuestionTypeAsAnswerOption) {
    let firstText = getAnswerOptionParameterValue(
      option.AnswerOptionParameters,
      "First text",
      cultureCode,
      TranslationKey.AnswersParameterValue
    );
    let lastText = getAnswerOptionParameterValue(
      option.AnswerOptionParameters,
      "Last text",
      cultureCode,
      TranslationKey.AnswersParameterValue
    );
    let secondText = getAnswerOptionParameterValue(
      option.AnswerOptionParameters,
      "Second text",
      cultureCode,
      TranslationKey.AnswersParameterValue
    );
    return {
      firstText: firstText ?? "",
      secondText: secondText ?? "",
      lastText: lastText ?? "",
      optionValue: option.AnswerValue,
      order: option.SortOrder,
      answers: [],
    };
  } else {
    let answerOptionText = getComponentTranslationsText(
      cultureCode,
      option.Translations,
      TranslationKey.AnswerOptionText
    );
    return {
      optionValue: option.AnswerValue,
      optionText: answerOptionText,
      order: option.SortOrder,
      answers: [],
    };
  }
};
export function modifyAnswers(draftState, sectionId, repeatId, questionId, answers) {
  if (!draftState[sectionId])
    throw new Error(`handleStateFunctions.question.modifyAnswers: state for id:${sectionId} is missing`);

  if (!draftState[sectionId].repeats[repeatId])
    throw new Error(`handleStateFunctions.question.modifyAnswers: repeatsState for id:${repeatId} is missing`);

  if (!draftState[sectionId].repeats[repeatId].questions[questionId])
    throw new Error(`handleStateFunctions.question.modifyAnswers: questionState for id:${questionId} is missing`);

  draftState[sectionId].repeats[repeatId].questions[questionId].answers = answers;
}

export function modifyMultipleChoiceOption(
  draftState,
  sectionId,
  repeatId,
  questionId,
  option,
  checked,
  isExclusive,
  archived,
  cultureCode,
  parentSectionId
) {
  if (!draftState[sectionId])
    throw new Error(`handleStateFunctions.question.modifyAnswers: state for id:${sectionId} is missing`);

  if (!draftState[sectionId].repeats[repeatId])
    throw new Error(`handleStateFunctions.question.modifyAnswers: repeatsState for id:${repeatId} is missing`);

  if (!draftState[sectionId].repeats[repeatId].questions[questionId])
    throw new Error(`handleStateFunctions.question.modifyAnswers: questionState for id:${questionId} is missing`);

  if (archived != null) {
    draftState[sectionId].repeats[repeatId].archived = archived;
    draftState[sectionId].repeats[repeatId].archivedInCurrentQuestionnaire = true;
    if (parentSectionId) {
      draftState[parentSectionId].repeats[repeatId].archived = archived;
      draftState[parentSectionId].repeats[repeatId].archivedInCurrentQuestionnaire = true;
    }
  }

  if (checked) {
    if (isExclusive) {
      draftState[sectionId].repeats[repeatId].questions[questionId].answers = {
        [option.ID]: defineOptionObject(option, cultureCode),
      };
    } else {
      draftState[sectionId].repeats[repeatId].questions[questionId].answers[option.ID] = defineOptionObject(
        option,
        cultureCode
      );
    }
  } else {
    let omitedAnswers = _.omit(draftState[sectionId].repeats[repeatId].questions[questionId].answers, option.ID);
    draftState[sectionId].repeats[repeatId].questions[questionId].answers = omitedAnswers;
  }
}

export function modifyMultipleChoiceAnswers(
  draftState,
  sectionId,
  repeatId,
  questionId,
  option,
  answers,
  isExclusive,
  archived,
  cultureCode,
  parentSectionId
) {
  if (!draftState[sectionId])
    throw new Error(`handleStateFunctions.question.modifyMultipleChoiceAnswers: state for id:${sectionId} is missing`);

  if (!draftState[sectionId].repeats[repeatId])
    throw new Error(
      `handleStateFunctions.question.modifyMultipleChoiceAnswers: repeatsState for id:${repeatId} is missing`
    );

  if (!draftState[sectionId].repeats[repeatId].questions[questionId])
    throw new Error(
      `handleStateFunctions.question.modifyMultipleChoiceAnswers: questionState for id:${questionId} is missing`
    );

  if (!draftState[sectionId].repeats[repeatId].questions[questionId].answers[option.ID]) {
    modifyMultipleChoiceOption(
      draftState,
      sectionId,
      repeatId,
      questionId,
      option,
      true,
      isExclusive,
      archived,
      cultureCode,
      parentSectionId
    );
  }

  draftState[sectionId].repeats[repeatId].questions[questionId].answers[option.ID].answers = answers;

  if (archived != null) {
    draftState[sectionId].repeats[repeatId].archived = archived;
    draftState[sectionId].repeats[repeatId].archivedInCurrentQuestionnaire = true;
    if (parentSectionId) {
      draftState[parentSectionId].repeats[repeatId].archived = archived;
      draftState[parentSectionId].repeats[repeatId].archivedInCurrentQuestionnaire = true;
    }
  }
}

export function defineQuestionsInRepeat(section, number, parentRepeatNumber, previousQuestions, cultureCode) {
  let questions = {};
  _.map(section.SectionDTO.SectionContentTemplate.Components, (component) => {
    if (component.QuestionDTO) {
      questions = {
        ...questions,
        [component.ID]:
          component.QuestionDTO.QuestionType === "HiddenQuestion"
            ? defaultHiddenQuestionObject(component.ID)
            : component.QuestionDTO.QuestionType === "SummaryQuestion"
            ? defaultSummaryQuestionObject(component, previousQuestions)
            : defaultQuestionObject(
                component,
                section.SectionDTO.ReferenceName,
                number,
                parentRepeatNumber,
                previousQuestions,
                cultureCode
              ),
      };
    }
  });
  return questions;
}
