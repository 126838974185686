import _ from "lodash";
import { enableES5, produce } from "immer";

import {
  checkIfQuestionHasAnswers,
  checkIfCascadingQuesitonHasOtherAnswer,
  checkcheckIfCascadingQuesitonAllAnswersEmpty,
  getHighlightedItemsToRemove,
} from "../../assets/questionnaire/Utils/questionComponentUtil";
import { MODIFY_ANSWERS } from "../../actions/question/modifyAnswers";
import { ADD_MC_HIGHLIGHT_QUESTION } from "../../actions/question/addMCHighlightQuestion";
import { SET_HIGHLIGHTED_PAGES } from "../../actions/userQuestionnaire/setHighlightedPages";
import { SHOW_OR_HIDE_SECTION } from "../../actions/section/showOrHideSection";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";
import { ADD_INIT_HIGHLIGHT_QUESTION } from "../../actions/question/addInitHighlighQuestion";
import { DELETE_REPEAT } from "../../actions/section/deleteRepeat";

const initState = {};
enableES5();
export default produce((draft, action) => {
  switch (action.type) {
    case SET_HIGHLIGHTED_PAGES: {
      if (!action.payload) return {};
      return JSON.parse(action.payload);
    }
    case ADD_INIT_HIGHLIGHT_QUESTION:
    case MODIFY_ANSWERS: {
      let { sectionId, repeatId, question, answers, parentRepeatNumber, selectedPage } = action.payload;
      let item = `${sectionId}-${parentRepeatNumber == null ? "null" : parentRepeatNumber}-${repeatId}-${question.ID}`;

      if (
        question.QuestionDTO.QuestionType === "OpenQuestion" ||
        question.QuestionDTO.QuestionType === "FileUpload" ||
        question.QuestionDTO.QuestionType === "CascadingListQuestion"
      ) {
        let founded = !draft[selectedPage.id]
          ? false
          : _.find(draft[selectedPage.id].correction, (x) => {
              return x == item;
            }) != null;

        let isAnswered = false;
        if (question.QuestionDTO.QuestionType !== "CascadingListQuestion") isAnswered = checkIfQuestionHasAnswers(answers);
        else {
          isAnswered = checkIfCascadingQuesitonHasOtherAnswer(answers);

          if (!isAnswered && question.QuestionDTO.ComponentName.toLowerCase().trim() === "z-index") {
            isAnswered = checkcheckIfCascadingQuesitonAllAnswersEmpty(answers);
          }
        }

        if (isAnswered && !founded) {
          if (!draft[selectedPage.id]) draft[selectedPage.id] = { correction: [], coding: [] };
          draft[selectedPage.id].correction.push(item);
        }
        if (founded && !isAnswered)
          draft[selectedPage.id].correction = _.filter(draft[selectedPage.id].correction, (x) => {
            return x != item;
          });
      }
      if (!_.isEmpty(question.QuestionDTO.CodeStandard.ID)) {
        let founded = !draft[selectedPage.id]
          ? false
          : _.find(draft[selectedPage.id].coding, (x) => {
              return x == item;
            }) != null;
        let isAnswered = checkIfQuestionHasAnswers(answers);
        if (isAnswered && !founded) {
          if (!draft[selectedPage.id]) draft[selectedPage.id] = { correction: [], coding: [] };
          draft[selectedPage.id].coding.push(item);
        }
        if (founded && !isAnswered)
          draft[selectedPage.id].coding = _.filter(draft[selectedPage.id].coding, (x) => {
            return x != item;
          });
      }
      return;
    }
    case ADD_MC_HIGHLIGHT_QUESTION: {
      let { question, questionAnswers, sectionId, repeatId, selectedPage, parentRepeatNumber } = action.payload;
      let item = `${sectionId}-${parentRepeatNumber == null ? "null" : parentRepeatNumber}-${repeatId}-${question.ID}`;
      let optionIds = questionAnswers ? Object.keys(questionAnswers) : null;

      let selectedOptions = _.filter(question.QuestionDTO.AnswerOptions, (x) => {
        return (
          ((x.ShowQuestionTypeAsAnswerOption && x.AdditionalQuestionType == "OpenQuestion") || x.ShowAdditionalTextField) &&
          optionIds.includes(x.ID) &&
          !x.Exclusive
        );
      });
      let founded = !draft[selectedPage.id]
        ? false
        : _.find(draft[selectedPage.id].correction, (x) => {
            return x == item;
          }) != null;

      if (!_.isEmpty(selectedOptions)) {
        if (!founded) {
          if (!draft[selectedPage.id]) draft[selectedPage.id] = { correction: [], coding: [] };
          draft[selectedPage.id].correction.push(item);
        }
      }
      if (founded && _.isEmpty(selectedOptions))
        draft[selectedPage.id].correction = _.filter(draft[selectedPage.id].correction, (x) => {
          return x != item;
        });

      if (!_.isEmpty(question.QuestionDTO.CodeStandard.ID)) {
        let founded = !draft[selectedPage.id]
          ? false
          : _.find(draft[selectedPage.id].coding, (x) => {
              return x == item;
            }) != null;

        let hasExclusive = _.find(question.QuestionDTO.AnswerOptions, (x) => x.Exclusive && optionIds.includes(x.ID)) != null;
        console.log(optionIds);

        if (!_.isEmpty(optionIds) && !hasExclusive) {
          if (!founded) {
            if (!draft[selectedPage.id]) draft[selectedPage.id] = { correction: [], coding: [] };
            draft[selectedPage.id].coding.push(item);
          }
        }
        if (founded && (_.isEmpty(optionIds) || hasExclusive))
          draft[selectedPage.id].coding = _.filter(draft[selectedPage.id].coding, (x) => {
            return x != item;
          });
      }
      return;
    }
    case SHOW_OR_HIDE_SECTION: {
      let { section, parentRepeatNumber, visible, selectedPage } = action.payload;
      //nested repeating todo if needed
      if (!visible) {
        //if section is hiding

        if (draft[selectedPage.id]) {
          let sectionsToRemove = getHighlightedItemsToRemove(section);
          _.forEach(sectionsToRemove, (x) => {
            let item = `${x}-${parentRepeatNumber == null ? "null" : parentRepeatNumber}`;
            let itemsToRemoveCorrection = _.filter(draft[selectedPage.id].correction, (y) => {
              return _.startsWith(y, item);
            });
            if (itemsToRemoveCorrection.length > 0) {
              draft[selectedPage.id].correction = _.difference(draft[selectedPage.id].correction, itemsToRemoveCorrection);
            }
            let itemsToRemoveCoding = _.filter(draft[selectedPage.id].coding, (y) => {
              return _.startsWith(y, item);
            });
            if (itemsToRemoveCoding.length > 0) draft[selectedPage.id].coding = _.difference(draft[selectedPage.id].coding, itemsToRemoveCoding);
          });
        }
      }
      return;
    }
    case DELETE_REPEAT: {
      let { repeatId, selectedPage } = action.payload;

      if (draft[selectedPage.id]) {
        let deletedCorrection = _.filter(draft[selectedPage.id].correction, (x) => {
          return _.includes(x, repeatId);
        });
        if (deletedCorrection.length > 0) {
          draft[selectedPage.id].correction = _.difference(draft[selectedPage.id].correction, deletedCorrection);
        }

        let deletedCoding = _.filter(draft[selectedPage.id].coding, (x) => {
          return _.includes(x, repeatId);
        });

        if (deletedCoding.length > 0) draft[selectedPage.id].coding = _.difference(draft[selectedPage.id].coding, deletedCoding);
      }
      return;
    }
    case SET_USER_QUESTIONNAIRE_ID:
      return initState;
    default:
      return;
  }
}, initState);
