import api from "../../assets/common/api/api";
import { toast } from "react-toastify";
import { setLoading } from "../loading/setLoading";

export const CHANGE_WEBSITE_CONTENT = "CHANGE_WEBSITE_CONTENT";

export const changeWebSiteContent = (webSiteContent, value) => async (dispatch, getState) => {
  setLoading(true);
  let object = { ...webSiteContent, Value: value };
  await api
    .post("/study/ChangeWebSiteContent", object)
    .then((response) => {
      if (response.status === 200) {
        setLoading(false);
        if (response.data) {
          dispatch({ type: CHANGE_WEBSITE_CONTENT, payload: object });
        } else toast.error("Error while changing site content");
      }
    })
    .catch((error) => {
      setLoading(false);
      toast.error("Error while changing site content");
    });
};
