import api from "../../assets/common/api/api";
import { grantPushPermission } from "../../assets/common/pwaUtil";

export const SUBSCRIBE_PUSH_NOTIFICATIONS = "SUBSCRIBE_PUSH_NOTIFICATIONS";

async function postAndDispatch(dispatch, params) {
  let ShowPushNotificationPopup;

  await api
    .post("/questionnaires/AllowUserNotifications/", null, {
      params: { ...params },
    })
    .then((response) => {
      ShowPushNotificationPopup = false;
    })
    .catch((error) => {
      ShowPushNotificationPopup = true;
    });

  dispatch({
    type: SUBSCRIBE_PUSH_NOTIFICATIONS,
    payload: { ShowPushNotificationPopup },
  });
}

export const subscribePushNotifications = (allow) => async (dispatch, getState) => {
  let params = {
    allow,
    endpoint: "",
    p256dh: "",
    auth: "",
  };

  if (allow) {
    grantPushPermission(async (grantObj) => {
      switch (grantObj.status) {
        case "granted":
          await postAndDispatch(dispatch, { allow: true, ...grantObj.subscription });
          break;
        case "default":
          break;
        case "denied":
          await postAndDispatch(dispatch, { ...params, allow: false });
          break;
        default:
          break;
      }
    });
  } else {
    await postAndDispatch(dispatch, { ...params, allow: false });
  }
};
