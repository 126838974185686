import { GET_FOLLOWUP_DATA } from "../actions/form/getFollowupData";

export function followup(state = {}, action) {
  switch (action.type) {
    case GET_FOLLOWUP_DATA:
      return action.payload;
    default:
      return state;
  }
}
