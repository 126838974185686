import _ from "lodash";
import { LogicalOperator, CategoryOperator } from "../Enums";

/**
 * calulate anwer option condition based on previous set categories
 * @param {object} categoryCondtion answer option condition
 * @param {array} textCategories
 * @param {array} numericCategories
 */
export const shouldHideAnswerOption = (categoryCondtion, textCategories, numericCategories) => {
  if (!categoryCondtion) return false;

  let textCategoryResult = evaluateCondition(categoryCondtion, CategoryOperator.Equals, false, textCategories, null);
  if (!textCategoryResult)
    return evaluateCondition(categoryCondtion, CategoryOperator.NumericEquals, false, numericCategories, null);
  return textCategoryResult;
};

/**
 * Get categories set in previous pages with sort order smaller or equal to the current page sort order
 * @param {array} categories user questionnaire categories
 * @param {int} sortOrder page sort order
 * @param {int} operator operator for previous categories
 */
export const getSectionCategories = (categories, sortOrder, operator = null) => {
  let previousSectionsCategories = _.filter(categories, (category) => {
    if (operator === CategoryOperator.Smaller) return category.SectionSortOrder < sortOrder;
    else return category.SectionSortOrder <= sortOrder;
  });

  let sectionCategories = [];
  _.orderBy(previousSectionsCategories, "SectionSortOrder", "desc").forEach((category) => {
    if (!_.find(sectionCategories, ["CategoryID", category.CategoryID])) sectionCategories.push(category);
  });
  return sectionCategories;
};
/**
 * Get not relevant categories
 * @param {array} categories user questionnaire categories
 * @param {object} sectionsConditions sections conditions store
 */
export const getNotRelevantCategories = (categories, sectionsConditionsStore) => {
  let notVisiblePages = Object.keys(
    _.pickBy(sectionsConditionsStore, (value) => {
      if (value.visible === false) return value;
    })
  );

  return _.filter(categories, (category) => {
    return notVisiblePages.includes(category.SectionID);
  });
};
/**
 *
 * @param {object} categoryCondition condition to be evaluate
 * @param {int} categoryOperator condition operator
 * @param {bool} negate
 * @param {array} textCategories text categories for calulation
 * @param {array} numericCategories numeric categories for calculation
 */
export const evaluateCondition = (categoryCondition, categoryOperator, negate, textCategories, numericCategories) => {
  switch (categoryOperator) {
    case CategoryOperator.Equals:
      return evaluateEquals(categoryCondition, textCategories, negate);
    case CategoryOperator.NotEquals:
      return evaluateEquals(categoryCondition, textCategories, true);
    case CategoryOperator.Became:
      return evaluateBecame(categoryCondition, textCategories);
    case CategoryOperator.BecameNot:
      return evaluateBecameNot(categoryCondition, textCategories);
    case CategoryOperator.Bigger:
      return evaluateBigger(categoryCondition, numericCategories);
    case CategoryOperator.BiggerEquals:
      return evaluateBiggerEquals(categoryCondition, numericCategories);
    case CategoryOperator.Smaller:
      return evaluateSmaller(categoryCondition, numericCategories);
    case CategoryOperator.SmallerEquals:
      return evaluateSmallerEquals(categoryCondition, numericCategories);
    case CategoryOperator.NumericEquals:
      return evaluateNumericEquals(categoryCondition, textCategories);
  }
  return true;
};
const evaluateNumericEquals = (categoryCondition, categories) => {
  let category = _.find(categories, (category) => {
    return category.CategoryID === categoryCondition.CategoryID;
  });

  let matchFound = false;
  if (category && category.NumericValue === categoryCondition.Value) {
    matchFound = true;
  }
  return matchFound;
};
const evaluateEquals = (categoryCondition, categories, negate) => {
  let category = _.find(categories, (category) => {
    return category.CategoryID === categoryCondition.CategoryID;
  });
  if (!category && categoryCondition.isDefault) return true;

  let matchFound = false;
  if (category && category.Value === categoryCondition.Value) {
    matchFound = true;
  }
  if (negate) return !matchFound;
  return matchFound;
};

const evaluateBecame = (categoryCondition, categories) => {
  let matchFound = _.some(categories, (category) => {
    return (
      category.SectionID != null &&
      category.CategoryID === categoryCondition.CategoryID &&
      category.Value === categoryCondition.Value
    );
  });

  return matchFound;
};

const evaluateBecameNot = (categoryCondition, categories) => {
  let matchFound = _.some(categories, (category) => {
    return (
      category.SectionID != null &&
      category.CategoryID === categoryCondition.CategoryID &&
      category.Value !== categoryCondition.Value &&
      !category.SetAsDefault
    );
  });
  return matchFound;
};
const evaluateBigger = (categoryCondition, categories) => {
  let category = _.find(categories, (category) => {
    return category.CategoryID === categoryCondition.CategoryID;
  });
  let numericValue = 0;
  if (category) numericValue = category.NumericValue;
  return numericValue > categoryCondition.Value;
};
const evaluateBiggerEquals = (categoryCondition, categories) => {
  let category = _.find(categories, (category) => {
    return category.CategoryID === categoryCondition.CategoryID;
  });

  let numericValue = 0;
  if (category) numericValue = category.NumericValue;

  return numericValue >= categoryCondition.Value;
};
const evaluateSmaller = (categoryCondition, categories) => {
  let category = _.find(categories, (category) => {
    return category.CategoryID === categoryCondition.CategoryID;
  });

  let numericValue = 0;
  if (category) numericValue = category.NumericValue;
  return numericValue < categoryCondition.Value;
};
const evaluateSmallerEquals = (categoryCondition, categories) => {
  let category = _.find(categories, (category) => {
    return category.CategoryID === categoryCondition.CategoryID;
  });

  let numericValue = 0;
  if (category) numericValue = category.NumericValue;
  return numericValue <= categoryCondition.Value;
};

/**
 * Evaluate section visibility for all defined category conditions
 * @param {object} sectionConditions section conditions
 */
export const evaluateConditions = (sectionConditions) => {
  let properties = sectionConditions.properties;

  let evaluations = Object.values(sectionConditions.categories);
  let firstEvaluations = _.map(evaluations, (evaluation) => {
    let categoryConditionEvaulations = _.reduce(
      evaluation,
      (result, condition) => {
        if (condition.isFirstSet) {
          result.push(condition.evaluated);
        }
        return result;
      },
      []
    );
    return evaluateLogicalOperator(properties.logicalOperator1, categoryConditionEvaulations);
  });

  let secondEvaluations = _.map(evaluations, (evaluation) => {
    let categoryConditionEvaulations = _.reduce(
      evaluation,
      (result, condition) => {
        if (!condition.isFirstSet) {
          result.push(condition.evaluated);
        }
        return result;
      },
      []
    );
    if (_.isEmpty(categoryConditionEvaulations)) return null;
    return evaluateLogicalOperator(properties.logicalOperator2, categoryConditionEvaulations);
  });

  let firstEvaluationsResult = false;
  let secondEvaluationResult = false;

  if (firstEvaluations.length > 0) {
    firstEvaluationsResult = evaluateLogicalOperator(properties.logicalOperator1, firstEvaluations);
  }
  if (secondEvaluations.length > 0) {
    secondEvaluationResult = evaluateLogicalOperator(
      properties.logicalOperator2,
      _.filter(secondEvaluations, (x) => {
        return x != null;
      })
    );
  }

  if (
    properties.secondCategoryConditionOperator != null &&
    _.filter(secondEvaluations, (x) => {
      return x == null;
    }).length !== secondEvaluations.length
  )
    return evaluateLogicalOperator(properties.secondCategoryConditionOperator, [
      firstEvaluationsResult,
      secondEvaluationResult,
    ]);
  else return firstEvaluationsResult;
};
/**
 * Evaluate section visibility based on logical operator
 * @param {int} logicalOperator section conditions
 * @param {array} evaluationResults calulated section conditions results
 */
export const evaluateLogicalOperator = (logicalOperator, evaluationResults) => {
  if (logicalOperator === LogicalOperator.Or)
    return _.some(evaluationResults, (element) => {
      return element === true;
    });

  if (logicalOperator === LogicalOperator.And)
    return _.every(evaluationResults, (element) => {
      return element === true;
    });
};
