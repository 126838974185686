/**
 *check if the email address is valid email address (regex)
 * @param {string} emailAddress question answer
 */
export const checkEmailAddress = (emailAddress) => {
  if (typeof emailAddress === "string") emailAddress = emailAddress.trim();

  if (
    /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~()]+[a-zA-Z\-0-9\._!#$%&'*+-/=?^_`{|}~()]*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      emailAddress
    )
  ) {
    return true;
  }
  return false;
};
