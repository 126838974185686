import setShouldModifyCategories from "../../tempStorage/setShouldModifyCategories";

export const SET_TEXT_CATEGORIES_TO_DEFAULT_VALUE = "SET_TEXT_CATEGORIES_TO_DEFAULT_VALUE";

let setTextCategoriesToDefaultValue = (options, answers) => (dispatch, getState) => {
  let { shouldModifyCategories } = getState().tempStorage;
  let sectionId = getState().userQuestionnaire.selectedPage.id;

  if (shouldModifyCategories) {
    dispatch({
      type: SET_TEXT_CATEGORIES_TO_DEFAULT_VALUE,
      payload: { sectionId, options, answers },
    });
    dispatch(setShouldModifyCategories(false));
  }
};

export default setTextCategoriesToDefaultValue;
