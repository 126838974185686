export const ADD_INIT_HIGHLIGHT_QUESTION = "ADD_INIT_HIGHLIGHT_QUESTION";

let addInitHighlighQuestion = (sectionId, repeatId, question, answers, parentRepeatNumber) => (dispatch, getState) => {
  let { selectedPage } = getState().userQuestionnaire;

  dispatch({
    type: ADD_INIT_HIGHLIGHT_QUESTION,
    payload: { sectionId, repeatId, question, answers, parentRepeatNumber, selectedPage },
  });
};

export default addInitHighlighQuestion;
