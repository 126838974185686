const monthNL = [
  { text: "Januari", value: "01" },
  { text: "Februari", value: "02" },
  { text: "Maart", value: "03" },
  { text: "April", value: "04" },
  { text: "Mei", value: "05" },
  { text: "Juni", value: "06" },
  { text: "Juli", value: "07" },
  { text: "Augustus", value: "08" },
  { text: "September", value: "09" },
  { text: "Oktober", value: "10" },
  { text: "November", value: "11" },
  { text: "December", value: "12" },
];

const monthEN = [
  { text: "January", value: "01" },
  { text: "February", value: "02" },
  { text: "March", value: "03" },
  { text: "April", value: "04" },
  { text: "May", value: "05" },
  { text: "June", value: "06" },
  { text: "July", value: "07" },
  { text: "August", value: "08" },
  { text: "September", value: "09" },
  { text: "October", value: "10" },
  { text: "November", value: "11" },
  { text: "December", value: "12" },
];
const monthIT = [
  { text: "Gennaio", value: "01" },
  { text: "Febbraio", value: "02" },
  { text: "Marzo", value: "03" },
  { text: "Aprile", value: "04" },
  { text: "Maggio", value: "05" },
  { text: "Giugno", value: "06" },
  { text: "Luglio", value: "07" },
  { text: "Agosto", value: "08" },
  { text: "Settembre", value: "09" },
  { text: "Ottobre", value: "10" },
  { text: "Novembre", value: "11" },
  { text: "Dicembre", value: "12" },
];

const monthFR = [
  { text: "Janvier  ", value: "01" },
  { text: "Février", value: "02" },
  { text: "Mars", value: "03" },
  { text: "Avril", value: "04" },
  { text: "Mai", value: "05" },
  { text: "Juin", value: "06" },
  { text: "Juillet", value: "07" },
  { text: "Août", value: "08" },
  { text: "Septembre", value: "09" },
  { text: "Octobre", value: "10" },
  { text: "Novembre", value: "11" },
  { text: "Décembre", value: "12" },
];

const monthDE = [
  { text: "Januar", value: "01" },
  { text: "Februar", value: "02" },
  { text: "März", value: "03" },
  { text: "April", value: "04" },
  { text: "Mai", value: "05" },
  { text: "Juni", value: "06" },
  { text: "Juli", value: "07" },
  { text: "August", value: "08" },
  { text: "September", value: "09" },
  { text: "Oktober", value: "10" },
  { text: "November", value: "11" },
  { text: "Dezember", value: "12" },
];

const inviteFriendEN = {
  ReceiverEmail: "Receiver Email",
  ReceiverName: "Receiver Name",
  SenderName: "Sender Name",
};

const inviteFriendNL = {
  ReceiverEmail: "Email ontvanger",
  ReceiverName: "Naam ontvanger",
  SenderName: "Naam verzender",
};

const inviteFriendIT = {
  ReceiverEmail: "E-mail del destinatario",
  ReceiverName: "Nome del destinatario",
  SenderName: "Nome del mittente",
};

const inviteFriendFR = {
  ReceiverEmail: "e-mail du destinataire",
  ReceiverName: "Nom du destinataire",
  SenderName: "Nom de l'expéditeur",
};

const inviteFriendDE = {
  ReceiverEmail: "E-Mail-Adresse Empfänger",
  ReceiverName: "Name des Empfängers",
  SenderName: "Name des Absenders",
};

const customBirthdayEN = {
  XYearsOld: "Please confirm that you are {0} years old.",
  ConfirmAge: "Confirm Age",
};

const customBirthdayNL = {
  XYearsOld: "Uw leeftijd is {0}.",
  ConfirmAge: "Leeftijd bevestigen",
};

const customBirthdayIT = {
  XYearsOld: "Conferma che hai {0} anni.",
  ConfirmAge: "Conferma",
};

const customBirthdayFR = {
  XYearsOld: "Merci de confirmer que vous avez {0} ans.",
  ConfirmAge: "Confirmer l'âge",
};

const customBirthdayDE = {
  XYearsOld: "Bitte bestätigen, dass Sie {0} Jahre alt sind.",
  ConfirmAge: "Alter bestätigen",
};

const healthSliderEN = {
  HealthSliderBestHealth: "The best health you can imagine",
  HealthSliderLine1: "<p>We would like to know how good or bad your health is TODAY.</p>",
  HealthSliderLine2: "<p>The scale is numbered from 0 to 100.<p>",
  HealthSliderLine3:
    "<p>100 means the <u>best</u> health you can imagine.<br/>0 means the <u>worst</u> health you can imagine.</p>",
  HealthSliderLine4: "<p>Please click on the scale to indicate how your health is TODAY.</p>",
  HealthSliderWorstHealth: "The worst health you can image",
  HealthSliderYouHealthToday: "YOUR HEALTH TODAY",
};

const healthSliderNL = {
  HealthSliderBestHealth: "De beste gezondheid die u zich kunt voorstellen",
  HealthSliderLine1: "<p>We willen weten hoe goed of slecht uw gezondheid VANDAAG is.</p>",
  HealthSliderLine2: "<p>Deze meetschaal loopt van 0 tot 100.</p>",
  HealthSliderLine3:
    "<p>100 staat voor de <u>beste</u> gezondheid die u zich kunt voorstellen.<br/>0 staat voor de <u>slechtste</u> gezondheid die u zich kunt voorstellen.</p>",
  HealthSliderLine4: "<p>Klik op de meetschaal om aan te geven hoe uw gezondheid VANDAAG is.</p>",
  HealthSliderWorstHealth: "De slechtste gezondheid die u zich kunt voorstellen",
  HealthSliderYouHealthToday: "UW GEZONDHEID VANDAAG",
};

const healthSliderIT = {
  HealthSliderBestHealth: "Il miglior stato di salute che tu possa immaginare.",
  HealthSliderLine1: "<p>Vorremmo sapere quanto è buona o cattiva la tua salute OGGI.</p>",
  HealthSliderLine2: "<p>La scala è numerata da 0 a 100.<p>",
  HealthSliderLine3:
    "<p>100 indica il <u> miglior </u> stato di salute che puoi immaginare. <br/> 0 indica il <u> peggior </u> stato di salute che puoi immaginare.</p>",
  HealthSliderLine4: "<p>fai clic su un punto della scala per indicare com'è il tuo stato di salute OGGI<p/> ",
  HealthSliderWorstHealth:
    "Il peggior stato di salute che tu possa immaginare. La peggior salute che tu possa immaginare",
  HealthSliderYouHealthToday: "LA TUA SALUTE OGGI",
};

const healthSliderFR = {
  HealthSliderBestHealth: "Le meilleur état de santé que vous puissiez imaginer",
  HealthSliderLine1: "<p>Nous aimerions savoir à quel point votre état de santé est bon ou mauvais AUJOURD'HUI.</p>",
  HealthSliderLine2: "<p> L'échelle s'etend de 0 à 100. <p>",
  HealthSliderLine3:
    "<p> 100 signifie le <u> meilleur </u> état de santé que vous puissiez imaginer. <br/> 0 signifie le <u> pire </u> état de santé que vous puissiez imaginer. </p>",
  HealthSliderLine4: "<p>Veuillez cliquer sur l'échelle pour indiquer votre état de santé AUJOURD'HUI. </p>",
  HealthSliderWorstHealth: "Le plus mauvais état de santé que vous puissiez imaginer",
  HealthSliderYouHealthToday: "VOTRE ETAT DE SANTE AUJOURD'HUI",
};

const healthSliderDE = {
  HealthSliderBestHealth: "Die beste Gesundheit, die Sie sich vorstellen können",
  HealthSliderLine1: "<p>Wir würden gerne wissen, wie gut oder schlecht es Ihnen HEUTE geht.</p>",
  HealthSliderLine2: "<p>Die Skala reicht von 0 bis 100.<p>",
  HealthSliderLine3:
    "<p>100 bedeutet die <u>beste</u> Gesundheit, die Sie sich vorstellen können.<br/>0 bedeutet die <u>schlechteste</u> Gesundheit, die Sie sich vorstellen können.</p>",
  HealthSliderLine4: "<p>Klicken Sie auf die Skala, um anzugeben, wie Ihr Gesundheitszustand HEUTE ist.</p>",
  HealthSliderWorstHealth: "Die schlechteste Gesundheit, die Sie sich vorstellen können",
  HealthSliderYouHealthToday: "IHRE GESUNDHEIT HEUTE",
};

const latencyEN = [
  { text: "Seconds", value: "1" },
  { text: "Minutes", value: "2" },
  { text: "Hours", value: "3" },
  { text: "Days", value: "4" },
  { text: "Weeks", value: "5" },
  { text: "Months", value: "6" },
  { text: "Years", value: "7" },
];

const latencyNL = [
  { text: "Seconden", value: "1" },
  { text: "Minuten", value: "2" },
  { text: "Uur", value: "3" },
  { text: "Dagen", value: "4" },
  { text: "Weken", value: "5" },
  { text: "Maanden", value: "6" },
  { text: "Jaren", value: "7" },
];

const latencyIT = [
  { text: "Secondi", value: "1" },
  { text: "Minuti", value: "2" },
  { text: "Ore", value: "3" },
  { text: "Giorni", value: "4" },
  { text: "Settimane", value: "5" },
  { text: "Mesi", value: "6" },
  { text: "Anni", value: "7" },
];

const latencyFR = [
  { text: "Secondes", value: "1" },
  { text: "Minutes", value: "2" },
  { text: "Heures", value: "3" },
  { text: "Jours", value: "4" },
  { text: "Semaines", value: "5" },
  { text: "Mois", value: "6" },
  { text: "Années", value: "7" },
];

const latencyDE = [
  { text: "Sekunden", value: "1" },
  { text: "Minuten", value: "2" },
  { text: "Stunden", value: "3" },
  { text: "Tage", value: "4" },
  { text: "Wochen", value: "5" },
  { text: "Monate", value: "6" },
  { text: "Jahre", value: "7" },
];

const cascadingPopUpEN = {
  Title: "Specify drug use",
  Description:
    "You can select the medicine below. As you start typing the name of the medicine, the choice narrows. If the medicine you have used is not listed, you can type the name.",
  NoResults: "No results found",
  Search: "Search",
};
const cascadingPopUpNL = {
  Title: "Specificeer geneesmiddelgebruik",
  Description:
    "U kunt het geneesmiddel hieronder selecteren. Als u begint met typen van de naam van het geneesmiddel, wordt de keuze kleiner. Staat het geneesmiddel dat u gebruikt heeft er niet bij, dan kunt u de naam intypen en vervolgens op OK klikken.",
  NoResults: "Geen suggesties gevonden",
  Search: "Zoeken",
};

const cascadingPopUpIT = {
  Title: "Indicare l'uso di farmaci",
  Description:
    "Puoi selezionare il nome del farmaco scorrendo il menù a tendina. Inizia a digitare il nome del farmaco per restringere la tua ricerca. Se il farmaco utilizzato non è presente nell'elenco, puoi inserirlo come testo libero.",
  NoResults: "Nessun risultato trovato",
  Search: "Cerca",
};

const cascadingPopUpFR = {
  Title: "Préciser le traitement utilisé",
  Description:
    "Vous pouvez sélectionner le traitement ci-dessous. Lorsque vous commencez à saisir le nom du médicament, le choix se précise. Si le médicament que vous avez pris n'est pas dans la liste, vous pouvez le saisir.",
  NoResults: "Aucun résultat trouvé",
  Search: "Chercher",
};

const cascadingPopUpDE = {
  Title: "Medikamenteneinnahme spezifizieren",
  Description:
    "Sie können das Medikament nachfolgend auswählen. Wenn Sie beginnen, den Namen des Medikaments einzugeben, wird die Auswahl kleiner. Wenn das Medikament, das Sie eingenommen haben, nicht dabei ist, können Sie den Namen eingeben.",
  NoResults: "Keine Resultate gefunden",
  Search: "Suchen",
};

const openQuestionCharactersLeftEN = {
  CharactersRemaining: "You have {0} characters left",
};
const openQuestionCharactersLeftNL = {
  CharactersRemaining: "U heeft nog {0} tekens over",
};

const openQuestionCharactersLeftIT = {
  CharactersRemaining: "Hai {0} caratteri rimanenti",
};

const openQuestionCharactersLeftFR = {
  CharactersRemaining: "Il reste {0} caractère(s)",
};

const openQuestionCharactersLeftDE = {
  CharactersRemaining: "Sie haben noch {0} Zeichen übrig",
};

const fileUploadEN = {
  DefaulDrugHeretMsg: "Drag your file (s) here or click to upload",
  AllowedTypesMsg: "Not all files are uploaded, the following files are accepted {0}",
  FileSizeMax: "File is too large, maximum allowed {0}.",
};
const fileUploadNL = {
  DefaulDrugHeretMsg: "Versleep hier uw bestand(en) of klik om te uploaden",
  AllowedTypesMsg:
    "Een of meer bestanden worden niet ondersteund. Deze worden niet geupload als u verder gaat. Toegestaan: {0}",
  FileSizeMax: "Bestand is te groot, maximaal toegestaan {0}.",
};

const fileUploadIT = {
  DefaulDrugHeretMsg: "Trascina qui i tuoi file o clicca per caricarli",
  AllowedTypesMsg: "Non tutti i file sono caricati, I seguenti file sono accettati: {0}",
  FileSizeMax: "Il file è troppo grande, dimensione massima consentita {0}.",
};

const fileUploadFR = {
  DefaulDrugHeretMsg: "Faites glisser votre (vos) fichier(s) ou cliquer sur télécharger",
  AllowedTypesMsg: "Tous les fichiers ne sont pas téléchargés, les fichiers suivants sont acceptés {0}",
  FileSizeMax: "Le fichier est trop volumineux, la taille maximale autorisée est {0}.",
};

const fileUploadDE = {
  DefaulDrugHeretMsg: "Ziehen Sie Ihre Datei(en) hierher oder klicken Sie zum Hochladen",
  AllowedTypesMsg: "Es wurden nicht alle Dateien hochgeladen, die folgenden Dateien wurden akzeptiert {0}",
  FileSizeMax: "Datei ist zu groß, maximal erlaubt {0}.",
};

const validationErrorMessageEN = {
  QuestionRequiredMessage: "You have not yet answered this question.",
  BirthdayConfirmClicked: "You must confirm your age.",
  SendGridMailInvalid: "The email address may not be correct, please check it carefully.",
  SectionRequiredMesaage: "This section is mandatory.",
  ValidationSummaryMessage: "Not all fields are filled in correctly. Please see the messages.",
  FormVerificationCodeMissing: "You must enter Verification code",
  CodeIncorrect: "Code Incorrect.",
  EmailCodeVerifyMsgNotDelivered:
    "We have sent you the verification code but it may have been declined. Please check your email address and try again if you have not received the code.",
};

const validationErrorMessageNL = {
  QuestionRequiredMessage: "U heeft deze vraag nog niet ingevuld.",
  BirthdayConfirmClicked: "U dient uw leeftijd te bevestigen.",
  SendGridMailInvalid: "Het emailadres is mogelijk niet correct, controleer deze alstublieft goed.",
  SectionRequiredMesaage: "Deze sectie is verplicht.",
  ValidationSummaryMessage: "Niet alle velden zijn correct ingevuld. Hierboven kunt u zien om welke velden het gaat.",
  FormVerificationCodeMissing: "Vul eerst de verificatiecode in",
  CodeIncorrect: "De verificatiecode is onjuist.",
  EmailCodeVerifyMsgNotDelivered:
    "Wij hebben de verificatie code naar je verzonden maar deze is mogelijk geweigerd. Controleer je e-mailadres en probeer het opnieuw als je de code niet hebt ontvangen.",
};

const validationErrorMessageIT = {
  QuestionRequiredMessage: "Non hai ancora risposto a questa domanda.",
  BirthdayConfirmClicked: "Conferma la tua età.",
  SendGridMailInvalid: "L'indirizzo e-mail potrebbe non essere corretto, controllarlo attentamente.",
  SectionRequiredMesaage: "Questa sezione è obbligatoria.",
  ValidationSummaryMessage:
    "Almeno un campo non è stato compilato correttamente. Per favore ricontrolla che tutti i campi obbligatori siano stati compilati.",
  FormVerificationCodeMissing: "Devi inserire il codice di verifica",
  CodeIncorrect: "Codice errato.",
  EmailCodeVerifyMsgNotDelivered:
    "We have sent you the verification code but it may have been declined. Please check your email address and try again if you have not received the code.",
};

const validationErrorMessageFR = {
  QuestionRequiredMessage: "Vous n'avez pas encore répondu à cette question.",
  BirthdayConfirmClicked: "Vous devez confirmer votre âge.",
  SendGridMailInvalid: "L'adresse e-mail n'est peut-être pas correcte, veuillez la vérifier attentivement.",
  SectionRequiredMesaage: "Cette section est obligatoire.",
  ValidationSummaryMessage:
    "Tous les champs ne sont pas renseignés correctement. Merci de lire les messages d'erreurs.",
  FormVerificationCodeMissing: "Vous devez entrer le code de vérification",
  CodeIncorrect: "Code incorrect.",
  EmailCodeVerifyMsgNotDelivered:
    "We have sent you the verification code but it may have been declined. Please check your email address and try again if you have not received the code.",
};

const validationErrorMessageDE = {
  QuestionRequiredMessage: "Sie haben diese Frage noch nicht beantwortet.",
  BirthdayConfirmClicked: "Sie müssen Ihr Alter bestätigen.",
  SendGridMailInvalid: "Die E-Mail-Adresse ist möglicherweise nicht korrekt, bitte überprüfen Sie sie sorgfältig.",
  SectionRequiredMesaage: "Dieser Teil ist obligatorisch.",
  ValidationSummaryMessage: "Nicht alle Felder wurden korrekt ausgefüllt. Siehe Meldungen.",
  FormVerificationCodeMissing: "Sie müssen den Bestätigungscode eingeben",
  CodeIncorrect: "Code falsch.",
  EmailCodeVerifyMsgNotDelivered:
    "We have sent you the verification code but it may have been declined. Please check your email address and try again if you have not received the code.",
};

const commonMessageEN = {
  Day: "Day",
  Month: "Month",
  Year: "Year",
  DownloadQuestionnaire: "Download questionnaire",
  DownloadQuestionnaireRF: "Download questionnaire",
  ButtonNext: "Next",
  ButtonBack: "Back",
  ButtonFinish: "Finish",
  ButtonSubmit: "Submit",
  AdditionalCheck: "As additional check please fill in the characters from the image",
  DidntMatch: "The characters did not match, please try again.",
  RefreshNote: "(Note: If you cannot read the numbers in the image, click on image to reload to generate a new one.)",
  ConfirmPassword: "Confirm password",
  NewVerificatoinCodeSent: "New code sent to your email address",
  EnterEmailAddressAgain: "Please enter your email address again",
};

const commonMessageNL = {
  Day: "Dag",
  Month: "Maand",
  Year: "Jaar",
  DownloadQuestionnaire: "Download vragenlijst",
  DownloadQuestionnaireRF: "Download formulier",
  ButtonNext: "Volgende",
  ButtonBack: "Vorige",
  ButtonFinish: "Verzenden",
  ButtonSubmit: "Controleren",
  AdditionalCheck: "Neem als extra controle de tekens uit onderstaande afbeelding over",
  DidntMatch: "De tekens kwamen niet overeen. Probeer het alstublieft nogmaals.",
  RefreshNote: "(Opmerking: als u de cijfers niet kunt lezen klik dan op de afbeelding voor een nieuwe cijferreeks.)",
  ConfirmPassword: "Wachtwoord bevestigen",
  NewVerificatoinCodeSent: "Er is een nieuwe verificatiecode verzonden",
  EnterEmailAddressAgain: "Voer uw e-mailadres opnieuw in",
};

const commonMessageIT = {
  Day: "Giorno",
  Month: "Mese",
  Year: "Anno",
  DownloadQuestionnaire: "Scarica il tuo questionario",
  DownloadQuestionnaireRF: "Scarica il tuo questionario",
  ButtonNext: "Avanti",
  ButtonBack: "Indietro",
  ButtonFinish: "Fine",
  ButtonSubmit: "Inviare",
  AdditionalCheck: "Come controllo aggiuntivo, inserire i caratteri presenti nell'immagine",
  DidntMatch: "I caratteri non corrispondono, riprovare.",
  RefreshNote:
    "(Nota: se non riesci a leggere i numeri nell'immagine, clicca sull'immagine per ricaricarla e generarne una nuova.)",
  ConfirmPassword: "Conferma password",
  NewVerificatoinCodeSent: "Nuovo codice inviato al tuo indirizzo email",
  EnterEmailAddressAgain: "Inserisci di nuovo il tuo indirizzo email",
};

const commonMessageFR = {
  Day: "Jour",
  Month: "Mois",
  Year: "An",
  DownloadQuestionnaire: "Télécharger le questionnaire",
  DownloadQuestionnaireRF: "Télécharger le questionnaire",
  ButtonNext: "Suivant",
  ButtonBack: "Précédent",
  ButtonFinish: "Terminer",
  ButtonSubmit: "Envoyer",
  AdditionalCheck: "En complément, veuillez compléter les caractères de l'image",
  DidntMatch: "Les caractères ne correspondant pas, veuillez réessayer.",
  RefreshNote:
    "(Remarque: si vous ne pouvez pas lire les nombres dans l'image, cliquez sur l'image pour en générer une nouvelle.)",
  ConfirmPassword: "Confirmez le mot de passe",
  NewVerificatoinCodeSent: "Nouveau code envoyé à votre adresse e-mail",
  EnterEmailAddressAgain: "Veuillez saisir à nouveau votre adresse e-mail",
};

const commonMessageDE = {
  Day: "Tag",
  Month: "Monat",
  Year: "Jahr",
  DownloadQuestionnaire: "Fragebogen herunterladen",
  DownloadQuestionnaireRF: "Fragebogen herunterladen",
  ButtonNext: "Weiter",
  ButtonBack: "Zurück",
  ButtonFinish: "Vervollständigen",
  ButtonSubmit: "Versenden",
  AdditionalCheck: "Bitte geben Sie die Zeichen aus dem Bild als zusätzliche Kontrolle ein",
  DidntMatch: "Die Zeichen stimmten nicht überein, bitte versuchen Sie es erneut.",
  RefreshNote:
    "(Hinweis: Wenn Sie die Zahlen im Bild nicht lesen können, klicken Sie auf „Bild neu laden“, um ein neues Bild zu erzeugen).",
  ConfirmPassword: "Passwort bestätigen",
  NewVerificatoinCodeSent: "Neuer Code an Ihre E-Mail-Adresse gesendet",
  EnterEmailAddressAgain: "Bitte geben Sie Ihre E-Mail-Adresse erneut ein",
};

const resources = {
  "nl-NL": {
    months: monthNL,
    inviteFriend: inviteFriendNL,
    customBirthday: customBirthdayNL,
    healthSlider: healthSliderNL,
    latency: latencyNL,
    cascadingQuestionPopUp: cascadingPopUpNL,
    openQuestionCharactersLeft: openQuestionCharactersLeftNL,
    validationErrorMessage: validationErrorMessageNL,
    fileUpload: fileUploadNL,
    commonMessage: commonMessageNL,
  },
  "en-GB": {
    months: monthEN,
    inviteFriend: inviteFriendEN,
    customBirthday: customBirthdayEN,
    healthSlider: healthSliderEN,
    latency: latencyEN,
    cascadingQuestionPopUp: cascadingPopUpEN,
    openQuestionCharactersLeft: openQuestionCharactersLeftEN,
    validationErrorMessage: validationErrorMessageEN,
    fileUpload: fileUploadEN,
    commonMessage: commonMessageEN,
  },
  "it-IT": {
    months: monthIT,
    inviteFriend: inviteFriendIT,
    customBirthday: customBirthdayIT,
    healthSlider: healthSliderIT,
    latency: latencyIT,
    cascadingQuestionPopUp: cascadingPopUpIT,
    openQuestionCharactersLeft: openQuestionCharactersLeftIT,
    validationErrorMessage: validationErrorMessageIT,
    fileUpload: fileUploadIT,
    commonMessage: commonMessageIT,
  },
  "fr-FR": {
    months: monthFR,
    inviteFriend: inviteFriendFR,
    customBirthday: customBirthdayFR,
    healthSlider: healthSliderFR,
    latency: latencyFR,
    cascadingQuestionPopUp: cascadingPopUpFR,
    openQuestionCharactersLeft: openQuestionCharactersLeftFR,
    validationErrorMessage: validationErrorMessageFR,
    fileUpload: fileUploadFR,
    commonMessage: commonMessageFR,
  },
  "de-DE": {
    months: monthDE,
    inviteFriend: inviteFriendDE,
    customBirthday: customBirthdayDE,
    healthSlider: healthSliderDE,
    latency: latencyDE,
    cascadingQuestionPopUp: cascadingPopUpDE,
    openQuestionCharactersLeft: openQuestionCharactersLeftDE,
    validationErrorMessage: validationErrorMessageDE,
    fileUpload: fileUploadDE,
    commonMessage: commonMessageDE,
  },
};

export default resources;
