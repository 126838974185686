import { GET_FORM_DATA } from "../actions/form/getFormData";
import { EMAIL_VERIFY_BY_CODE } from "../actions/tempStorage/setEmailVerifyByCode";

export function form(state = {}, action) {
  switch (action.type) {
    case GET_FORM_DATA:
      return action.payload;
    case EMAIL_VERIFY_BY_CODE: {
      return { ...state, CodeVerificationSent: action.payload.isSent };
    }
    default:
      return state;
  }
}
