import { getSectionCategories } from "../../../assets/questionnaire/containers/categoryConditionContainer";

export const CALCULATE_SECTION_VISIBILITY = "CALCULATE_SECTION_VISIBILITY";

export default function calculateSectionVisibility(sortOrder, categoriesMap, textCategories, numericCategories) {
  let changedTextCategories = getSectionCategories(textCategories, sortOrder);
  let changedNumericCategories = getSectionCategories(numericCategories, sortOrder);

  return {
    type: CALCULATE_SECTION_VISIBILITY,
    payload: { changedTextCategories, sortOrder, categoriesMap, changedNumericCategories },
  };
}
