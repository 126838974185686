export const ValidDate = {
  ValidationName: "Valid Date",
  ValidationRuleValue: "",
  AdditionalInfo: "",
  IsCustom: true,
  PreviousAnswerValue: "",
  IsRangeRule: false,
  ValidationMessage: {
    "nl-NL": "Geen geldige datum.",
    "en-GB": "Not valid date.",
    "it-IT": "Data non valida.",
    "fr-FR": "Date non valide.",
    "de-DE": "Nicht gültiges Datum.",
  },
};

export const ValidYear = {
  ValidationName: "Valid Year",
  ValidationRuleValue: "",
  AdditionalInfo: "",
  IsCustom: true,
  PreviousAnswerValue: "",
  IsRangeRule: false,
  ValidationMessage: {
    "nl-NL": "Vul alstublieft een geldige datum in.",
    "en-GB": "Please enter valid date.",
    "it-IT": "Si prega di inserire una data valida.",
    "fr-FR": "Veuillez saisir une date valide.",
    "de-DE": "Bitte geben Sie ein gültiges Datum ein.",
  },
};

export const ValidDay = {
  ValidationName: "Valid Day",
  ValidationRuleValue: 2,
  AdditionalInfo: "",
  IsCustom: true,
  PreviousAnswerValue: "",
  IsRangeRule: false,
  ValidationMessage: {
    "nl-NL": "Geen geldige dag.",
    "en-GB": "Not valid day.",
    "it-IT": "Giorno non valido.",
    "fr-FR": "Jour non valide.",
    "de-DE": "Nicht gültiger Tag.",
  },
};

export const YearsOldNotValid = {
  ValidationName: "Years Old Not Valid",
  ValidationRuleValue: 150,
  AdditionalInfo: "",
  IsCustom: true,
  PreviousAnswerValue: "",
  IsRangeRule: false,
  ValidationMessage: {
    "nl-NL": "Jaar is niet geldig.",
    "en-GB": "Year is not valid.",
    "it-IT": "L'anno non è valido.",
    "fr-FR": "L'année n'est pas valide.",
    "de-DE": "Jahr ist nicht gültig.",
  },
};

export const YearMaxCharacters = {
  ValidationName: "Year Max Characters",
  ValidationRuleValue: 4,
  AdditionalInfo: "",
  IsCustom: true,
  PreviousAnswerValue: "",
  IsRangeRule: false,
  ValidationMessage: {
    "nl-NL": "Jaar is niet geldig.",
    "en-GB": "Year is not valid.",
    "it-IT": "L'anno non è valido.",
    "fr-FR": "L'année n'est pas valide.",
    "de-DE": "Jahr ist nicht gültig.",
  },
};

export const YearShouldNotBeInFuture = {
  ValidationName: "Year Should Not Be In Future",
  ValidationRuleValue: "",
  AdditionalInfo: "",
  IsCustom: true,
  PreviousAnswerValue: "",
  IsRangeRule: false,
  ValidationMessage: {
    "nl-NL": "Het jaar moet in het verleden zijn.",
    "en-GB": "Year must be in past.",
    "it-IT": "L'anno deve essere passato.",
    "fr-FR": "L'année doit être passée.",
    "de-DE": "Jahr muss in der Vergangenheit liegen.",
  },
};

export const ValidPassword = {
  ValidationName: "Valid Password",
  ValidationRuleValue: "",
  AdditionalInfo: "",
  IsCustom: true,
  PreviousAnswerValue: "",
  IsRangeRule: true,
  ValidationMessage: {
    "nl-NL": "De wachtwoorden zijn niet gelijk aan elkaar.",
    "en-GB": "The passwords do not match.",
    "it-IT": "La password non coincide",
    "fr-FR": "Le mot de passe ne correspond pas",
    "de-DE": "Die Passwörter stimmen nicht überein.",
  },
};
export const ValidZIndexFirstLevel = {
  ValidationName: "Valid ZIndex First Level",
  ValidationRuleValue: "",
  AdditionalInfo: "",
  IsCustom: true,
  PreviousAnswerValue: "",
  IsRangeRule: false,
  ValidationMessage: {
    "nl-NL": "De eerste vraag is verplicht.",
    "en-GB": "The first question is mandatory.",
    "it-IT": "La prima domanda è obbligatoria.",
    "fr-FR": "La première question est obligatoire.",
    "de-DE": "Die Passwörter stimmen nicht überein.",
  },
};
