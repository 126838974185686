import _ from "lodash";
import { enableES5, produce } from "immer";

import { SET_NUMERIC_CATEGORIES } from "../../actions/userQuestionnaire/setNumericCategories";
import { ADD_REPEAT } from "../../actions/section/addRepeat";
import { DELETE_REPEAT } from "../../actions/section/deleteRepeat";
import { MODIFY_MULTIPLE_CHOICE_OPTION } from "../../actions/question/modifyMultipleChoiceOption";
import { MODIFY_MULTIPLE_CHOICE_ANSWERS } from "../../actions/question/modifyMultipleChoiceAnswers";
import { SHOW_OR_HIDE_SECTION } from "../../actions/section/showOrHideSection";
import { ON_INIT_REPEAT_SECTION } from "../../actions/userQuestionnaire/categories/onInitRepeatedSection";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";

import { getRepeatedSection } from "../../assets/questionnaire/Utils/questionComponentUtil";
import handleCategoryChange from "../../assets/questionnaire/handleStateFunctions/numericCategories";

const initState = [];
enableES5();
export default produce((draft, action) => {
  switch (action.type) {
    case SET_NUMERIC_CATEGORIES: {
      if (!action.payload) return [];
      return JSON.parse(action.payload);
    }
    case ADD_REPEAT: {
      let { section, selectedPage } = action.payload;
      handleCategoryChange.add_categories(
        draft,
        section.SectionDTO.CategoryValues,
        selectedPage.id,
        selectedPage.sortOrder
      );
      return;
    }
    case DELETE_REPEAT: {
      let { section, isArchived, selectedPage } = action.payload;
      handleCategoryChange.update_category(
        draft,
        section.SectionDTO.CategoryValues,
        selectedPage.id,
        selectedPage.sortOrder,
        isArchived
      );
      return;
    }
    case MODIFY_MULTIPLE_CHOICE_OPTION:
    case MODIFY_MULTIPLE_CHOICE_ANSWERS: {
      let { section, archived, selectedPage, previousArchivedValue, parentSection } = action.payload;
      let sectionToCalculate = getRepeatedSection(section, parentSection);

      if (sectionToCalculate && sectionToCalculate.SectionDTO.Repeated) {
        if (!previousArchivedValue && archived == true)
          handleCategoryChange.set_repeat_archived(
            draft,
            sectionToCalculate.SectionDTO.CategoryValues,
            selectedPage.id,
            selectedPage.sortOrder
          );
        if (previousArchivedValue && archived == false) {
          handleCategoryChange.remove_repeat_archived(
            draft,
            sectionToCalculate.SectionDTO.CategoryValues,
            selectedPage.id,
            selectedPage.sortOrder
          );
        }
      }
      return;
    }
    case SHOW_OR_HIDE_SECTION: {
      let { section, selectedPage, visible } = action.payload;

      if (section.SectionDTO.Repeated) {
        if (!visible) {
          handleCategoryChange.remove_added_numeric_categories(
            draft,
            section.SectionDTO.CategoryValues,
            selectedPage.id
          );
        } else {
          let sectionCategory = _.find(draft, (category) => {
            return _.startsWith(category.CategoryName, section.ReferenceName) && category.SectionID == selectedPage.id;
          });
          if (!sectionCategory) {
            _.times(section.SectionDTO.InitialNumberOfRepeats, (inx) => {
              handleCategoryChange.add_categories(
                draft,
                section.SectionDTO.CategoryValues,
                selectedPage.id,
                selectedPage.sortOrder
              );
            });
          }
        }
      }
      return;
    }
    case ON_INIT_REPEAT_SECTION: {
      let { section, selectedPage, repeats, previousMCAnswers } = action.payload;
      let sectionCategory = _.find(draft, (category) => {
        return (
          _.startsWith(_.toLower(category.CategoryName), _.toLower(section.SectionDTO.ReferenceName)) &&
          category.SectionID == selectedPage.id
        );
      });
      if (!_.isEmpty(repeats) && !sectionCategory) {
        let numberOfRepeats = Object.keys(repeats).length;
        if (section.SectionDTO.NumericQuestionRepeatID && !_.isEmpty(previousMCAnswers)) {
          let answer = previousMCAnswers[section.SectionDTO.NumericQuestionRepeatID];
          if (answer) numberOfRepeats = parseInt(answer);
        }
        //add numeric categories based on repeats
        _.times(numberOfRepeats, (inx) => {
          handleCategoryChange.add_categories(
            draft,
            section.SectionDTO.CategoryValues,
            selectedPage.id,
            selectedPage.sortOrder,
            section.SectionDTO.NumericQuestionRepeatID && !_.isEmpty(previousMCAnswers)
          );
        });

        //check how many repeats are arhived and set correct value to arhived numeric category
        let archivedRepeats = _.filter(Object.values(repeats), (x) => {
          return x.archived == true;
        });
        if (archivedRepeats.length > 0) {
          _.times(archivedRepeats.length, (inx) => {
            handleCategoryChange.set_repeat_archived(
              draft,
              section.SectionDTO.CategoryValues,
              selectedPage.id,
              selectedPage.sortOrder
            );
          });
        }
      } else if (!_.isEmpty(repeats) && section.SectionDTO.NumericQuestionRepeatID && !_.isEmpty(previousMCAnswers)) {
        let answer = previousMCAnswers[section.SectionDTO.NumericQuestionRepeatID];
        //check if some repeat is arhived, if yes then answers were added, do not change categories
        let arhivedRepeats = _.filter(repeats, (r) => {
          return r.archived == true;
        });

        if (answer && arhivedRepeats.length == 0) {
          handleCategoryChange.remove_added_numeric_categories(
            draft,
            section.SectionDTO.CategoryValues,
            selectedPage.id
          );
          let numberOfRepeats = parseInt(answer);
          _.times(numberOfRepeats, (inx) => {
            handleCategoryChange.add_categories(
              draft,
              section.SectionDTO.CategoryValues,
              selectedPage.id,
              selectedPage.sortOrder,
              true
            );
          });
        }
      } else if (
        !_.isEmpty(repeats) &&
        sectionCategory &&
        section.SectionDTO.InitialNumberOfRepeats > 0 &&
        section.SectionDTO.NumericQuestionRepeatID == null
      ) {
        let sectionCategories = _.filter(draft, (category) => {
          return (
            _.startsWith(_.toLower(category.CategoryName), _.toLower(section.SectionDTO.ReferenceName)) &&
            category.SectionID == selectedPage.id &&
            category.NumericValue == 0
          );
        });
        if (sectionCategories.length == 3) {
          _.times(section.SectionDTO.InitialNumberOfRepeats, (inx) => {
            handleCategoryChange.add_categories(
              draft,
              section.SectionDTO.CategoryValues,
              selectedPage.id,
              selectedPage.sortOrder,
              section.SectionDTO.NumericQuestionRepeatID && !_.isEmpty(previousMCAnswers)
            );
          });
        }
      }
      return;
    }
    case SET_USER_QUESTIONNAIRE_ID:
      return initState;
  }
}, initState);
