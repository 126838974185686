export const MODIFY_CASCADING_DRUG_CATEGORY_VALUES = "MODIFY_CASCADING_DRUG_CATEGORY_VALUES";

let modifyCascadingDrugCategoryValues = (previousAnswer, newAnswer) => (dispatch, getState) => {
  let { selectedPage, zIndexCategories } = getState().userQuestionnaire;

  dispatch({
    type: MODIFY_CASCADING_DRUG_CATEGORY_VALUES,
    payload: {
      previousAnswer,
      newAnswer,
      selectedPage,
      zIndexCategories,
    },
  });
};

export default modifyCascadingDrugCategoryValues;
