export const REMOVE_CATEGORIES_FROM_NOT_VISIBLE_PAGES = "REMOVE_CATEGORIES_FROM_NOT_VISIBLE_PAGES";

let removeCategoriesFromNotVisiblePages = (notRelevantCategories) => (dispatch, getState) => {
  dispatch({
    type: REMOVE_CATEGORIES_FROM_NOT_VISIBLE_PAGES,
    payload: { notRelevantCategories },
  });
};

export default removeCategoriesFromNotVisiblePages;
