export const SET_PREVIOUS_SELECTED_CATEGORIES_TO_DEFAULT_VALUE = "SET_PREVIOUS_SELECTED_CATEGORIES_TO_DEFAULT_VALUE";
export const ADD_OPTION_CATEGORIES = "ADD_OPTION_CATEGORIES";
export const SET_OPTION_CATEGORIES_TO_DEFAULT_VALUE = "SET_OPTION_CATEGORIES_TO_DEFAULT_VALUE";

let onTextCategoriesChange = (selectedOption, checked, options, answers, exclusive) => (dispatch, getState) => {
  let { selectedPage } = getState().userQuestionnaire;
  let sectionId = selectedPage.id;
  let sectionSortOrder = selectedPage.sortOrder;

  if (checked) {
    if (exclusive) {
      dispatch({
        type: SET_PREVIOUS_SELECTED_CATEGORIES_TO_DEFAULT_VALUE,
        payload: { selectedOption, sectionId, checked, options, answers, exclusive },
      });
    }
    dispatch({
      type: ADD_OPTION_CATEGORIES,
      payload: { selectedOption, sectionId, sectionSortOrder },
    });
  } else {
    dispatch({
      type: SET_OPTION_CATEGORIES_TO_DEFAULT_VALUE,
      payload: { selectedOption, sectionId },
    });
  }
};

export default onTextCategoriesChange;
