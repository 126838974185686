export const pwaA2hs = () => {
  let deferredPrompt;
  const addBtn = document.querySelector("#btn-homescreen");
  addBtn.style.display = "none";

  window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    deferredPrompt = e;
    addBtn.style.display = "block";
  });

  addBtn.addEventListener("click", (e) => {
    addBtn.style.display = "none";
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      //choiceResult.outcome
      deferredPrompt = null;
    });
  });
};

function getRegister(callBack) {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      callBack(registration);
    });
  }
}

function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export const isPushSupported = () =>
  "Notification" in window && "serviceWorker" in navigator && "PushManager" in window;

export const grantPushPermission = (onUserAction) => {
  if (isPushSupported()) {
    let appServerKey = window.flim_config.VAPIDpublicKey;
    Notification.requestPermission(function (status) {
      if (status === "granted") {
        getRegister((reg) => {
          if (reg != null) {
            reg.pushManager.getSubscription().then(function (sub) {
              if (sub === null) {
                reg.pushManager
                  .subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: appServerKey,
                  })
                  .then(function (sub) {
                    var subscription = {
                      endpoint: sub.endpoint,
                      p256dh: arrayBufferToBase64(sub.getKey("p256dh")),
                      auth: arrayBufferToBase64(sub.getKey("auth")),
                    };
                    onUserAction({ status, subscription });
                  })
                  .catch(function (e) {
                    console.error("Unable to subscribe to push", e);
                  });
              } else {
                var subscription = {
                  endpoint: sub.endpoint,
                  p256dh: arrayBufferToBase64(sub.getKey("p256dh")),
                  auth: arrayBufferToBase64(sub.getKey("auth")),
                };
                onUserAction({ status, subscription });
              }
            });
          }
        });
      } else onUserAction({ status, subscription: null });
    });
  }
};
