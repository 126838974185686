import _ from "lodash";
import { evaluateCondition, evaluateConditions } from "../containers/categoryConditionContainer";

const defineCondition = (
  draftState,
  sectionId,
  categoryCondition,
  categoryOperator,
  negate,
  isFirstSet,
  textCategories,
  numericCategories
) => {
  if (!draftState[sectionId].categories[categoryCondition.CategoryID])
    draftState[sectionId].categories[categoryCondition.CategoryID] = [];

  draftState[sectionId].categories[categoryCondition.CategoryID].push({
    condition: { categoryOperator, categoryCondition, negate },
    isFirstSet,
    evaluated: evaluateCondition(categoryCondition, categoryOperator, negate, textCategories, numericCategories),
  });
};

const sectionCategoriesHandleState = {
  add_category_condition: (draftState, sectionId, condition, isFirstSet, textCategories, numericCategories) => {
    if (!_.isEmpty(condition.CategoryValue1.Value)) {
      defineCondition(
        draftState,
        sectionId,
        condition.CategoryValue1,
        condition.CategoryOperator1,
        condition.Negate1,
        isFirstSet,
        textCategories,
        numericCategories
      );
    }

    if (!_.isEmpty(condition.CategoryValue2.Value)) {
      defineCondition(
        draftState,
        sectionId,
        condition.CategoryValue2,
        condition.CategoryOperator2,
        condition.Negate2,
        isFirstSet,
        textCategories,
        numericCategories
      );
    }

    if (!_.isEmpty(condition.CategoryValue3.Value)) {
      defineCondition(
        draftState,
        sectionId,
        condition.CategoryValue3,
        condition.CategoryOperator3,
        condition.Negate3,
        isFirstSet,
        textCategories,
        numericCategories
      );
    }
    if (!_.isEmpty(condition.CategoryValue4.Value)) {
      defineCondition(
        draftState,
        sectionId,
        condition.CategoryValue4,
        condition.CategoryOperator4,
        condition.Negate4,
        isFirstSet,
        textCategories,
        numericCategories
      );
    }
  },
  calculate_section_condition: (draftState, sectionId, textCategories, numericCategories) => {
    if (!_.isEmpty(draftState[sectionId].categories)) {
      _.forEach(Object.keys(draftState[sectionId].categories), (categoryId) => {
        for (let i = 0; i < draftState[sectionId].categories[categoryId].length; i++) {
          draftState[sectionId].categories[categoryId][i].evaluated = evaluateCondition(
            draftState[sectionId].categories[categoryId][i].condition.categoryCondition,
            draftState[sectionId].categories[categoryId][i].condition.categoryOperator,
            draftState[sectionId].categories[categoryId][i].condition.negate,
            textCategories,
            numericCategories
          );
        }
      });
      draftState[sectionId].visible = evaluateConditions(draftState[sectionId]);
    }
  },
  calculate_condition: (draftState, sections, sortOrder, categoryID, textCategories, numericCategories) => {
    for (let section of sections) {
      if (draftState[section]) {
        let sectionSortOrder = draftState[section].sortOrder;
        if (draftState[section] && sectionSortOrder > sortOrder && draftState[section].categories[categoryID]) {
          for (let i = 0; i < draftState[section].categories[categoryID].length; i++) {
            draftState[section].categories[categoryID][i].evaluated = evaluateCondition(
              draftState[section].categories[categoryID][i].condition.categoryCondition,
              draftState[section].categories[categoryID][i].condition.categoryOperator,
              draftState[section].categories[categoryID][i].condition.negate,
              textCategories,
              numericCategories
            );
          }

          draftState[section].visible = evaluateConditions(draftState[section]);
        }
      }
    }
  },
  calculate_condition_not_relevant_categories: (draftState, sections, sortOrder, categoryID) => {
    for (let section of sections) {
      if (draftState[section]) {
        let sectionSortOrder = draftState[section].sortOrder;
        if (draftState[section] && sectionSortOrder > sortOrder && draftState[section].categories[categoryID]) {
          for (let i = 0; i < draftState[section].categories[categoryID].length; i++) {
            draftState[section].categories[categoryID][i].evaluated = false;
          }

          draftState[section].visible = evaluateConditions(draftState[section]);
        }
      }
    }
  },
};

export default sectionCategoriesHandleState;
