import { updateOrInsertThenDispatch } from "../../assets/questionnaire/Utils/questionReferencesUtil";
import _ from "lodash";
export const MODIFY_ANSWERS = "MODIFY_ANSWERS";

let modifyAnswers =
  (sectionId, repeatId, question, answers, repeatNumber, parentRepeatNumber, fullName) => (dispatch, getState) => {
    let { selectedPage, cultureCode } = getState().userQuestionnaire;
    dispatch({
      type: MODIFY_ANSWERS,
      payload: { sectionId, repeatId, question, answers, parentRepeatNumber, selectedPage },
    });

    let qRefMap = getState().userQuestionnaire.questionReferences;

    //check if need to dispatch update questionReference
    let keys = _.filter(Object.keys(qRefMap), (key) => {
      var ref = key.split("||")[0];
      return ref === fullName;
    });

    if (!_.isEmpty(keys))
      updateOrInsertThenDispatch(
        dispatch,
        qRefMap,
        keys,
        repeatNumber,
        parentRepeatNumber,
        fullName,
        answers,
        cultureCode
      );
  };

export default modifyAnswers;
