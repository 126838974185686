import { EMAIL_VERIFY_BY_CODE } from "../../actions/tempStorage/setEmailVerifyByCode";
import { FETCH_PAGE } from "../../actions/userQuestionnaire/selectPage";

export default function (state = null, action) {
  switch (action.type) {
    case EMAIL_VERIFY_BY_CODE: {
      return action.payload.email;
    }
    case FETCH_PAGE: {
      let { codeValidation } = action.payload.data;
      if (codeValidation && codeValidation.email) return codeValidation.email;
      else return state;
    }
    default:
      return state;
  }
}
