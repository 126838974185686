import { SET_ZINDEX_CATEGORIES } from "../../actions/userQuestionnaire/setZIndexCategories";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";

export default function (state = [], action) {
  switch (action.type) {
    case SET_ZINDEX_CATEGORIES:
      return action.payload;
    case SET_USER_QUESTIONNAIRE_ID: {
      return [];
    }
    default:
      return state;
  }
}
