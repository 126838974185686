import _ from "lodash";

import { ADD_ERROR_MSG } from "../../actions/tempStorage/customErrorMsg/addMessage";
import { DELETE_ERROR_MSG } from "../../actions/tempStorage/customErrorMsg/deleteMessage";
import { CLEAR_ALL_ERROR_MSGS } from "../../actions/tempStorage/customErrorMsg/clearAllMessage";
import { FETCH_PAGE } from "../../actions/userQuestionnaire/selectPage";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";

export default function (state = {}, action) {
  switch (action.type) {
    case ADD_ERROR_MSG: {
      let { key, message } = action.payload;
      return { ...state, [key]: message };
    }
    case DELETE_ERROR_MSG: {
      let { key } = action.payload;
      if (state[key]) return _.omit(state, key);
      return state;
    }
    case CLEAR_ALL_ERROR_MSGS: {
      return {};
    }
    case FETCH_PAGE: {
      return {};
    }
    case SET_USER_QUESTIONNAIRE_ID: {
      return {};
    }
    default:
      return state;
  }
}
