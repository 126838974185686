import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import { defineQuestionsInRepeat } from "./question";

const defaultSectionRepeatObject = (id) => {
  return {
    id,
    parentRepeatId: null,
    number: 0,
    repeatable: false,
    questions: {},
  };
};

const sectionRepeatHandleStateExport = {
  add: (
    draftState,
    section,
    repeatId,
    parentRepeatId,
    parentRepeatNumber,
    number,
    repeatable,
    previousQuestions,
    followup,
    cultureCode
  ) => {
    let sectionId = section.ID;
    let sectionsState = draftState[sectionId];
    if (!sectionsState) throw new Error(`handleStateFunctions.sectionRepeat.add: state for id:${sectionId} is missing`);

    var repeatsState = sectionsState.repeats;

    if (!repeatsState[repeatId]) {
      let questions = defineQuestionsInRepeat(section, number, parentRepeatNumber, previousQuestions, cultureCode);

      let newRepeat = {
        ...defaultSectionRepeatObject(repeatId),
        parentRepeatId,
        parentRepeatNumber,
        number,
        followup,
        repeatable,
        questions,
      };

      if (repeatable) {
        newRepeat = { ...newRepeat, customID: uuidv4(), repeatTransferCount: 1 };
      }

      draftState[sectionId].repeats[repeatId] = newRepeat;
    }
  },
  addInitialRepeats: (
    draftState,
    section,
    parentRepeatId,
    parentRepeatNumber,
    previousSections,
    previousQuestions,
    previousMCAnswers,
    followup,
    cultureCode
  ) => {
    let sectionId = section.ID;
    let sectionState = draftState[sectionId];
    if (!sectionState)
      throw new Error(`handleStateFunctions.sectionRepeat.addInitialRepeats: state for id:${sectionId} is missing`);

    let previousRepeats = previousSections ? previousSections[section.SectionDTO.ReferenceName] : null;
    if (section.SectionDTO.UsePreviousAnswers && !_.isEmpty(previousRepeats)) {
      let repeatable = true;
      let repeats = _.filter(previousRepeats, (repeat) => {
        return repeat.parentRepeatNumber === parentRepeatNumber;
      });
      for (let repeat of repeats) {
        let { number, archived, archivedInCorrection, archivedPublished } = repeat;
        let repeatId = uuidv4();
        let questions = defineQuestionsInRepeat(section, number, parentRepeatNumber, previousQuestions, cultureCode);

        let newRepeat = {
          ...defaultSectionRepeatObject(repeatId),
          parentRepeatId,
          parentRepeatNumber,
          number,
          archived: archivedPublished && archivedInCorrection != null ? archivedInCorrection : archived,
          followup: true,
          repeatable,
          questions,
          customID: repeat.customID,
          repeatTransferCount: repeat.repeatTransferCount ? repeat.repeatTransferCount + 1 : 1,
        };
        draftState[sectionId].repeats[repeatId] = newRepeat;
      }
    } else {
      let repeatsState = sectionState.repeats;
      if (section.SectionDTO.NumericQuestionRepeatID && !_.isEmpty(previousMCAnswers)) {
        let answer = previousMCAnswers[section.SectionDTO.NumericQuestionRepeatID];
        if (answer) {
          draftState[sectionId].repeats = addManualInitialRepeats(
            section,
            repeatsState,
            parentRepeatId,
            parentRepeatNumber,
            parseInt(answer),
            followup,
            previousQuestions,
            cultureCode
          );
          draftState[sectionId].repeats = repeatsState;
        }
      } else {
        draftState[sectionId].repeats = addManualInitialRepeats(
          section,
          repeatsState,
          parentRepeatId,
          parentRepeatNumber,
          section.SectionDTO.InitialNumberOfRepeats,
          followup,
          previousQuestions,
          cultureCode
        );
        draftState[sectionId].repeats = repeatsState;
      }
    }
  },
  update: (state) => state, //not implemented
  delete: (draftState, sectionId, id, sectionComponents) => {
    var stateSection = draftState[sectionId];
    if (!stateSection)
      throw new Error(`handleStateFunctions.sectionRepeat.delete: state for id:${sectionId} is missing`);

    var descendantSectionIds = [];
    traverseComponents(sectionComponents, descendantSectionIds);

    if (!stateSection.repeats[id])
      throw new Error(`handleStateFunctions.sectionRepeat.delete: repeatsState for id:${id} is missing`);

    reCalculateOrder(draftState, stateSection, id, descendantSectionIds);

    let deletedRepeatIds = [id];
    let omitedRepeatState = _.omit(stateSection.repeats, id);

    _.each(descendantSectionIds, (sectionId) => {
      var stateSectionDescendant = draftState[sectionId];

      if (stateSectionDescendant) {
        var deletedDescendantRepeatIds = [];

        _.each(deletedRepeatIds, (repeatId) => {
          Object.values(stateSectionDescendant.repeats).forEach((descendantRepeat) => {
            if (descendantRepeat.parentRepeatId === repeatId || descendantRepeat.parentRepeatId == null)
              deletedDescendantRepeatIds.push(descendantRepeat.id);
          });
        });

        stateSectionDescendant.repeats = _.omit(stateSectionDescendant.repeats, deletedDescendantRepeatIds);

        //if there are no repeats, distroy the stateSectionDescendant
        if (_.isEmpty(stateSectionDescendant.repeats)) draftState = _.omit(draftState, sectionId);

        deletedRepeatIds = _.union(deletedRepeatIds, deletedDescendantRepeatIds);
      }
    });
    draftState[sectionId].repeats = omitedRepeatState;
  },
};

function traverseComponents(sectionComponents, sectionIds) {
  var sections = sectionComponents.filter((c) => c.SectionDTO != null);
  sections.forEach((s) => {
    sectionIds.push(s.ID);

    traverseComponents(s.SectionDTO.SectionContentTemplate.Components, sectionIds);
  });
}

export function findMaxRepeatNr(state, sectionId, parentRepeatId) {
  var sectionState = state[sectionId];
  if (!sectionState)
    throw new Error(`handleStateFunctions.sectionRepeat.findMaxRepeatNr: state for id:${sectionId} is missing`);

  var maxRepeat = _.chain(sectionState.repeats)
    .values()
    .filter((item) => {
      return item.parentRepeatId === parentRepeatId;
    })
    .maxBy("number")
    .value();
  return maxRepeat ? maxRepeat.number + 1 : 1;
}

export function defineRepeatId(sectionId, parentRepeatId, repetable) {
  return repetable ? uuidv4() : parentRepeatId ? parentRepeatId : sectionId;
}

function reCalculateOrder(draftState, stateSection, repeatId, descendantSectionIds) {
  let repeat = stateSection.repeats[repeatId];
  const changedRepeats = [];
  _.chain(stateSection.repeats)
    .values()
    .filter((r) => {
      return r.parentRepeatId === repeat.parentRepeatId && r.number > repeat.number;
    })
    .each((r) => {
      --r.number;
      changedRepeats.push(r);
    })
    .value();

  _.each(descendantSectionIds, (sectionId) => {
    var stateSectionDescendant = draftState[sectionId];
    if (stateSectionDescendant) {
      _.chain(stateSectionDescendant.repeats)
        .values()
        .filter((r) => {
          return changedRepeats.some((obj) => obj["id"] === r.parentRepeatId);
        })
        .each((r) => {
          const changedRepeat = _.find(changedRepeats, { id: r.parentRepeatId });
          if (changedRepeat) {
            r.parentRepeatNumber = changedRepeat.number;
          }
        })
        .value();
    }
  });
}
function addManualInitialRepeats(
  section,
  repeatsState,
  parentRepeatId,
  parentRepeatNumber,
  count,
  followup,
  previousQuestions,
  cultureCode
) {
  _.times(count, (inx) => {
    let repeatId = uuidv4();
    let number = inx + 1;
    let questions = defineQuestionsInRepeat(section, number, parentRepeatNumber, previousQuestions, cultureCode);

    let newRepeat = {
      ...defaultSectionRepeatObject(repeatId),
      parentRepeatId,
      parentRepeatNumber,
      number,
      followup,
      repeatable: true,
      questions,
      customID: uuidv4(),
      repeatTransferCount: 1,
    };

    repeatsState[repeatId] = newRepeat;
  });
  return repeatsState;
}

export default sectionRepeatHandleStateExport;
