import onInitRepeatSection from "../userQuestionnaire/categories/onInitRepeatedSection";

export const INIT_SECTION_DATA = "INIT_SECTION_DATA";
let initSectionData =
  (section, parentRepeatId, parentSectionId, parentRepeatNumber, parentRepeatFollowup) => (dispatch, getState) => {
    let { requiredItems } = getState().tempStorage;
    let sectionCondition = getState().userQuestionnaire.sectionsConditions[section.ID];
    let { previousMCAnswers, previousQuestions, previousSections, cultureCode } = getState().userQuestionnaire;
    dispatch({
      type: INIT_SECTION_DATA,
      payload: {
        section,
        parentRepeatId,
        parentRepeatNumber,
        parentRepeatFollowup,
        parentSectionId,
        requiredItems,
        sectionCondition,
        previousMCAnswers,
        previousSections,
        previousQuestions,
        cultureCode,
      },
    });

    if (section.SectionDTO.Repeated && !section.SectionDTO.UsePreviousAnswers) dispatch(onInitRepeatSection(section));
  };

export default initSectionData;
