import { SET_REDIRECTURL } from "../../actions/userQuestionnaire/setRedirectUrl";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";

export default function (state = null, action) {
  switch (action.type) {
    case SET_REDIRECTURL:
      return action.payload;
    case SET_USER_QUESTIONNAIRE_ID:
      return null;
    default:
      return state;
  }
}
