export const INIT_CONDITIONAL_SECTION = "INIT_CONDITIONAL_SECTION";

let initConditionalSection = (
  sectionId,
  sortOrder,
  categoryShowCondition,
  secondCategoryShowCondition,
  secondCategoryConditionOperator,
  isTopSection
) => (dispatch, getState) => {
  let { textCategories, numericCategories } = getState().userQuestionnaire;
  let pageSortOrder = !isTopSection ? getState().userQuestionnaire.selectedPage.sortOrder : sortOrder;

  dispatch({
    type: INIT_CONDITIONAL_SECTION,
    payload: {
      sectionId,
      pageSortOrder,
      categoryShowCondition,
      secondCategoryShowCondition,
      secondCategoryConditionOperator,
      textCategories,
      numericCategories,
      isTopSection,
    },
  });
};

export default initConditionalSection;
