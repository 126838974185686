import api from "../../assets/common/api/api";
import { setLoading } from "../loading/setLoading";
import _ from "lodash";

export const GET_QUESTION_IMAGE_CONTENT = "GET_QUESTION_IMAGE_CONTENT";

export const getQuestionImageContent = (questionId) => async (dispatch, getState) => {
  setLoading(true);
  let { id } = getState().userQuestionnaire;
  await api
    .get(`/QuestionImage/GetQuestionImageContent?questionId=${questionId}&userQuestionnaireId=${id}`)
    .then((response) => {
      if (response.status === 200) {
        setLoading(false);
        let responseData = response.data;
        if (!_.isEmpty(responseData.Content)) {
          dispatch({ type: GET_QUESTION_IMAGE_CONTENT, payload: response.data });
        }
      }
    })
    .catch((error) => {
      setLoading(false);
    });
};
