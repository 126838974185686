import api from "../../assets/common/api/api";

export const FETCH_CASCADING_DRUGS = "FETCH_CASCADING_DRUGS";

export const fetchCascadingDrugs = (componentName, componentId, levelName, groupName) => async (dispatch, getState) => {
  const response = await api.get("/CascadingList/GetCascadingListItems", {
    params: {
      componentId: componentId,
      levelName: encodeURI(levelName),
      groupName: encodeURI(groupName),
    },
  });

  dispatch({
    type: FETCH_CASCADING_DRUGS,
    payload: {
      componentName,
      groupName,
      drugs: response.data,
    },
  });
};
