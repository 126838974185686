import _ from "lodash";
import { validationRulesCommands, validationRulesRangeCommands } from "./validationRules";
import { defineDayMonthYear, getComponentTranslationsText } from "../Utils/questionComponentUtil";
import resources from "../resources";
import { PageNavigationENUM, TranslationKey } from "../Enums";
import { resolveValidationRuleValue } from "../Utils/questionComponentUtil";
import { trimReference, _contextVariableREGEX } from "../Utils/questionReferencesUtil";
import { ValidZIndexFirstLevel } from "./customValidationRules";

/**
 *validate question answer by question validation rules and custom rules if defined
 * @param {array} questionRules question answers
 * @param {string} answers question answers
 * @param {string} currentDate application current date (this date is set in admin panel)
 * @param {bool} isRangeItem is range question (integer or date range question)
 * @param {array} customRules question custom rules
 * @param {string} cultureCode
 * @param {string} validationRulesReferences reference answers if refenece is define in the validation rule
 * @param {bool} isZIndexQuestion is z-index question type
 */
export const validateAnswers = (
  questionRules,
  answers,
  currentDate,
  isRangeItem,
  customRules,
  cultureCode,
  validationRulesReferences,
  isZIndexQuestion
) => {
  const rules = customRules ? _.union(customRules, questionRules) : questionRules;
  const regularRules = rules.filter((x) => {
    return !x.IsRangeRule;
  });

  for (let rule of regularRules) {
    if (!validateAnswersByRule(rule, answers, currentDate, validationRulesReferences))
      return rule.IsCustom
        ? rule.ValidationMessage[cultureCode]
        : replaceValidationRuleMessageReference(
            getComponentTranslationsText(
              cultureCode,
              rule.ValidationMessageTranslations,
              TranslationKey.ValidationRule
            ),
            validationRulesReferences
          );
  }

  //if the question is range question, then validation should be done on both answers
  if (isRangeItem && answers[0] && answers[1]) {
    var firstAnswer = _.find(answers, { Order: 1 });
    var secondAnswer = _.find(answers, { Order: 2 });
    const rangeRules = rules.filter((x) => {
      return x.IsRangeRule;
    });
    let validationMessage = validateRangeAnswers(rangeRules, firstAnswer, secondAnswer, cultureCode);
    if (validationMessage) return validationMessage;
  }

  if (isZIndexQuestion) {
    if (answers.length > 0) {
      const firstAnswer = _.find(answers, { Order: 1 });
      if (!firstAnswer) return ValidZIndexFirstLevel.ValidationMessage[cultureCode];
    }
  }
  return null;
};

/**
 *validate range answers by rules
 * @param {array} rules rules defined from admin panel
 * @param {object} answer1 first answer
 * @param {object} answer2 second answer
 */
const validateRangeAnswers = (rules, answer1, answer2, cultureCode) => {
  for (let rule of rules) {
    if (!validationRulesRangeCommands(rule.ValidationName, answer1.AnswerValue, answer2.AnswerValue))
      return rule.IsCustom
        ? rule.ValidationMessage[cultureCode]
        : getComponentTranslationsText(cultureCode, rule.ValidationMessageTranslations, TranslationKey.ValidationRule);
  }
  return null;
};

/**
 *validate answers by some rule
 * @param {array} rule  validatioin rule
 * @param {array} answers  answers
 * @param {string} currentDate application current date
 * @param {string} validationRulesReferences reference answers (if reference is define in validation rule)
 */
const validateAnswersByRule = (rule, answers, currentDate, validationRulesReferences) => {
  if (!rule) return null;

  for (let answer of answers) {
    let isValid = !_.isEmpty(answer.AnswerValue)
      ? validationRulesCommands(
          rule.ValidationName,
          rule.ValidationRuleValue,
          answer.AnswerValue,
          currentDate,
          validationRulesReferences,
          rule.AdditionalInfo
        )
      : true;
    if (!isValid) return false;
  }
  return true;
};
/**
 *get specific validation rule by name
 * @param {array} validationRules validation rules
 * @param {string} ruleName validation rule name
 */
export const getSpecificValidationRule = (validationRules, ruleName) => {
  return _.find(validationRules, (rule) => {
    return rule.ValidationName === ruleName;
  });
};

/**
 *
 * @param {object} maxCharactersRule validation rule
 * @param {string} answerValue answer value
 * @param {string} cultureCode culture code (user questionnaire language)
 */
export const calculateCharactersLeft = (maxCharactersRule, answerValue, cultureCode) => {
  if (maxCharactersRule) {
    const message = resources[cultureCode].openQuestionCharactersLeft.CharactersRemaining;
    return message.replace("{0}", maxCharactersRule.ValidationRuleValue - answerValue.length);
  }
  return null;
};

/**
 * validate page on save
 * @param {array} requiredItems required items
 * @param {object} navigationDirection
 * @param {object} sectionState state with the recorded answers
 * @param {string} cultureCode user questionnaire culture code
 */
export const validatePage = (requiredItems, navigationDirection, sectionsState, cultureCode) => {
  if (
    navigationDirection !== PageNavigationENUM.BACK &&
    navigationDirection !== PageNavigationENUM.DIRECT_TO_SPECIFIC_PAGE
  ) {
    let invalidData = validateRequiredItems(requiredItems, sectionsState);

    if (invalidData.length > 0)
      return { invalidData, message: resources[cultureCode].validationErrorMessage.QuestionRequiredMessage };
  }

  let invalidBirthdayQuestion = validateCustomBirthdayQuestion(requiredItems, sectionsState);
  if (invalidBirthdayQuestion.length > 0)
    return {
      invalidData: invalidBirthdayQuestion,
      message: resources[cultureCode].validationErrorMessage.BirthdayConfirmClicked,
    };
  return null;
};

/**
 * validate required items, checks if all required questions are answered
 * @param {array} requiredItems required items
 * @param {object} sectionState state with the recorded answers
 */
const validateRequiredItems = (requiredItems, sectionState) => {
  let invalidData = [];
  for (let item of requiredItems) {
    let answers = sectionState[item.sectionId].repeats[item.repeatId].questions[item.questionId].answers;
    if (_.isEmpty(answers)) {
      invalidData.push(item);
      continue;
    }

    if (!_.isArray(answers)) {
      if (!hasMultipleChoiceQuestionAnswers(answers, item.options)) invalidData.push(item);
    } else if (
      (item.questionType === "IntegerQuestion" || item.questionType === "DecimalQuestion") &&
      item.options &&
      item.options.length > 1 &&
      _.find(item.options, { InvalidAnswerOption: true }) != null
    ) {
      if (!hasIntegerDecimalQuestionWithAnswerOptionsAnswers(answers, item)) invalidData.push(item);
    } else {
      if (!hasQuestionAnswers(answers, !item.questionType.includes("Range"), item.questionType.includes("Date")))
        invalidData.push(item);
    }
  }
  return invalidData;
};

const hasIntegerDecimalQuestionWithAnswerOptionsAnswers = (answers, item) => {
  if (answers.length === 1 || answers.length === 0) return false;

  let isFirstAnswerAnswered = isAnswerAnswered(
    _.find(answers, (answer) => {
      return answer.Order === 1;
    }),
    false,
    false,
    false
  );
  if (!isFirstAnswerAnswered) return false;

  let secondAnswer = _.find(answers, (answer) => {
    return answer.Order === 2;
  });

  if (_.isEmpty(secondAnswer)) return false;

  let answerOption = _.find(item.options, (option) => {
    return option.AnswerValue === secondAnswer.AnswerValue;
  });

  if (answerOption.InvalidAnswerOption) return false;

  return true;
};

/**
 * validate multiple choice question
 * @param {object} answers answered options
 * @param {array} option multiple choice answer option
 */
const hasMultipleChoiceQuestionAnswers = (answers, options) => {
  let optionIds = answers ? Object.keys(answers) : null;
  if (_.isEmpty(optionIds)) return false;

  let selectedOptions = _.filter(options, (option) => {
    return optionIds.includes(option.ID) && option.ShowQuestionTypeAsAnswerOption;
  });

  for (let option of selectedOptions) {
    let validateMonth = option.AdditionalQuestionType !== "DateQuestionYearOnly";
    let validateDay =
      option.AdditionalQuestionType !== "DateQuestionYearOnly" &&
      option.AdditionalQuestionType !== "DateQuestionDropdown";
    if (
      !hasQuestionAnswers(
        answers[option.ID].answers,
        !option.AdditionalQuestionType.includes("Range") && option.AdditionalQuestionType !== "Latency",
        option.AdditionalQuestionType.includes("Date"),
        validateDay,
        validateMonth
      )
    )
      return false;
  }
  return true;
};
/**
 * check if the item has answers
 * @param {array} answers item answers
 * @param {bool} validateOnlyFirstAnswer if the item is not range question, then only first answer should be check
 * @param {bool} isDate if the item is date type question
 * @param {bool} validateDay if the item is date type question and day part should be validate
 * @param {bool} validateMonth if the item is date type question and month part should be validate
 */
const hasQuestionAnswers = (answers, validateOnlyFirstAnswer, isDate, validateDay = true, validateMonth = true) => {
  if (validateOnlyFirstAnswer)
    return isAnswerAnswered(
      _.find(answers, (answer) => {
        return answer.Order === 1;
      }),
      isDate,
      validateDay,
      validateMonth
    );
  else {
    if (answers.length === 1 || answers.length === 0) return false;
    for (let answer of answers) {
      if (!isAnswerAnswered(answer, isDate, validateDay, validateMonth)) return false;
    }
  }
  return true;
};

/**
 * check if the answer has value
 * @param {object} answer item answer
 * @param {bool} isDate if the item is date type question
 * @param {bool} validateDay if the item is date type question and day part should be validate
 * @param {bool} validateMonth if the item is date type question and month part should be validate
 */
const isAnswerAnswered = (answer, isDate, validateDay, validateMonth) => {
  if (_.isEmpty(answer)) return false;
  if (_.isEmpty(answer.AnswerValue)) return false;
  if (isDate && !isDateAnswered(answer.AnswerValue, validateDay, validateMonth)) return false;

  if (_.isObject(answer.AnswerValue)) {
    if (_.isEmpty(answer.AnswerValue.value)) return false;
  }

  return true;
};

/**
 * check if the date answer has value
 * @param {object} answer item answer
 * @param {bool} validateDay if the item is date type question and day part should be validate
 * @param {bool} validateMonth if the item is date type question and month part should be validate
 */
const isDateAnswered = (answerValue, validateDay, validateMonth) => {
  let answerObj = defineDayMonthYear(answerValue);
  if (!answerObj) return false;

  if (answerObj.year === "0000") return false;
  if (validateMonth && answerObj.month === "00") return false;
  if (validateDay && answerObj.day === "00") return false;
  return true;
};

/**
 * check if custom birthday question have confirm button click
 * @param {array} requiredItems required items (custom birthday question is always required question)
 * @param {object} sectionState state with the recorded answers
 */
const validateCustomBirthdayQuestion = (requiredItems, sectionState) => {
  let invalidBirthdayQuestions = [];
  let requiredBirthdayItems = _.filter(requiredItems, (item) => {
    return item.questionType === "CustomBirthdayQuestion";
  });
  if (requiredBirthdayItems.length > 0) {
    for (let item of requiredBirthdayItems) {
      let answers = sectionState[item.sectionId].repeats[item.repeatId].questions[item.questionId].answers;

      let answer = _.first(answers);
      if (answer && !answer.IsConfirmButtonClick) invalidBirthdayQuestions.push(item);
    }
  }
  return invalidBirthdayQuestions;
};

/**
 * replace validation rules that have references as validation rule value
 * @param {array} validationRules validation rule define for the item
 * @param {object} referenceMap item reference map
 * @param {int} repeatNumber repeat number
 * @param {int} parentRepeatNumber parent repeat number
 * @param {object} sectionReferenceName section reference name
 */
export const replaceValidationRuleReferences = (
  validationRules,
  referenceMap,
  repeatNumber,
  parentRepeatNumber,
  sectionReferenceName
) => {
  let referenceAnswers = [];
  if (_.isEmpty(referenceMap)) return referenceAnswers;

  let appliedValidationRules = getValidationRuleWithReferenceAsValue(validationRules);
  if (appliedValidationRules.length > 0) {
    _.map(appliedValidationRules, (x) => {
      let refValue = resolveValidationRuleValue(
        x.ValidationRuleValue,
        referenceMap,
        repeatNumber,
        parentRepeatNumber,
        sectionReferenceName
      );
      if (x.ValidationRuleValue.match(_contextVariableREGEX)) {
        let validationRuleValueTrim = trimReference(x.ValidationRuleValue);
        if (!_.isEmpty(validationRuleValueTrim))
          referenceAnswers.push({ reference: validationRuleValueTrim, value: refValue });
      }
    });
  }
  return referenceAnswers;
};

/**
 * return applied validation rules with reference value as validation rule
 * @param {array} validationRules validation rule define for the item
 */
export const getValidationRuleWithReferenceAsValue = (validationRules) => {
  return _.filter(
    validationRules,
    (x) =>
      x.IsApplied &&
      (x.ValidationName === "Date before answer to other date question" ||
        x.ValidationName === "Date after answer to other date question")
  );
};

/**
 * return applied validation rules with reference value as validation rule to question answer options
 * @param {array} answerOptions question answer options
 */
export const getAnswerOptionValidationRuleReferenceAsValue = (answerOptions) => {
  let validationRulesWithReferences = [];
  _.forEach(answerOptions, (option) => {
    if (option.AnswerOptionValidationRules.length > 0) {
      let validationRules = _.filter(
        option.AnswerOptionValidationRules,
        (r) =>
          r.IsApplied &&
          (r.ValidationName === "Date before answer to other date question" ||
            r.ValidationName === "Date after answer to other date question")
      );
      if (validationRules.length > 0)
        validationRulesWithReferences = _.concat(validationRulesWithReferences, validationRules);
    }
  });
  return validationRulesWithReferences;
};

/**
 * return validation rule message with replaced context variables (if exists)
 * @param {string} validationRuleMessage validation rule error message
 */
const replaceValidationRuleMessageReference = (validationRuleMessage, validationRulesReferences) => {
  let qRefs = validationRuleMessage.match(_contextVariableREGEX);
  if (qRefs && !_.isEmpty(validationRulesReferences)) {
    let trimReferenceValue = trimReference(qRefs[0]);
    let reference = _.find(validationRulesReferences, { reference: trimReferenceValue });
    if (_.isEmpty(reference) || _.isEmpty(reference.value)) return validationRuleMessage;

    return validationRuleMessage.replace(qRefs[0], reference.value);
  } else return validationRuleMessage;
};
