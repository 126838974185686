import api from "../../assets/common/api/api";
import { setLoading } from "../loading/setLoading";
export const SET_TOOLBOX_DATA = "SET_TOOLBOX_DATA";
export const SET_STORY_DATA = "SET_STORY_DATA";

export const getWebSiteContentUploads = (webContentType) => async (dispatch, getState) => {
  setLoading(true);
  await api
    .get(`/study/GetWebSiteContentUploads?webContentType=${webContentType}`)
    .then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setLoading(false);
        if (webContentType == 5) {
          dispatch({ type: SET_TOOLBOX_DATA, payload: response.data });
        }
        if (webContentType == 6) {
          dispatch({ type: SET_STORY_DATA, payload: response.data });
        }
      }
    })
    .catch((error) => {
      setLoading(false);
    });
};
