import { INIT_SECTION_DATA } from "../../actions/section/initSectionData";
import { ADD_REPEAT } from "../../actions/section/addRepeat";
import { DELETE_REPEAT } from "../../actions/section/deleteRepeat";
import { MODIFY_ANSWERS } from "../../actions/question/modifyAnswers";
import { FETCH_PAGE } from "../../actions/userQuestionnaire/selectPage";
import { MODIFY_MULTIPLE_CHOICE_ANSWERS } from "../../actions/question/modifyMultipleChoiceAnswers";
import { MODIFY_MULTIPLE_CHOICE_OPTION } from "../../actions/question/modifyMultipleChoiceOption";
import { SHOW_OR_HIDE_SECTION } from "../../actions/section/showOrHideSection";
import { CHANGE_REPEATS } from "../../actions/question/changeRepeats";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";
import { changeRepeatsWithMCQuestion } from "../../assets/questionnaire/handleStateFunctions/section";
import { getParentSectionId } from "../../assets/questionnaire/Utils/questionComponentUtil";

import handleSection from "../../assets/questionnaire/handleStateFunctions/section";
import handleSectionRepeat from "../../assets/questionnaire/handleStateFunctions/sectionRepeat";
import {
  modifyAnswers,
  modifyMultipleChoiceOption,
  modifyMultipleChoiceAnswers,
} from "../../assets/questionnaire/handleStateFunctions/question";
import {
  registerCondition,
  calculateSectionVisibilityWhileInit,
} from "../../assets/questionnaire/containers/sectionConditionsContainer";

import { findMaxRepeatNr, defineRepeatId } from "../../assets/questionnaire/handleStateFunctions/sectionRepeat";
import { enableES5, produce } from "immer";
import _ from "lodash";

const initState = {};
enableES5();
//sections reducers
export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_PAGE: {
      return JSON.parse(action.payload.data.reduxStateStoreJson);
    }
    case INIT_SECTION_DATA: {
      let {
        section,
        parentRepeatId,
        parentRepeatNumber,
        parentRepeatFollowup,
        parentSectionId,
        requiredItems,
        sectionCondition,
        previousMCAnswers,
        previousSections,
        previousQuestions,
        cultureCode,
      } = action.payload;

      registerCondition(section);
      handleSection.add(
        draft,
        section,
        parentRepeatId,
        parentRepeatNumber,
        parentRepeatFollowup,
        sectionCondition,
        previousMCAnswers,
        previousSections,
        previousQuestions,
        cultureCode
      );

      //calculate visibility
      draft[section.ID].questionConditionVisibility = true;
      let repeatId = parentRepeatId ? parentRepeatId : parentSectionId; //the repeatId where the conditional answers belongs
      let storeData = { sections: draft, requiredItems };
      var actionValue = calculateSectionVisibilityWhileInit(storeData, section.ID, parentSectionId, repeatId);

      if (actionValue) {
        let { section, parentRepeatId, visible, existingRepeats, cultureCode } = actionValue;
        handleSection.show_or_hide(
          draft,
          section,
          parentRepeatId,
          parentRepeatNumber,
          parentRepeatFollowup,
          visible,
          existingRepeats,
          previousSections,
          previousQuestions,
          sectionCondition,
          cultureCode
        );
      }

      return;
    }
    case ADD_REPEAT: {
      let { section, parentRepeatId, parentRepeatNumber, previousQuestions, cultureCode } = action.payload;
      let repeatable = section.SectionDTO.Repeated;
      let number = findMaxRepeatNr(draft, section.ID, parentRepeatId);
      let repeatId = defineRepeatId(section.ID, parentRepeatId, repeatable);

      handleSectionRepeat.add(
        draft,
        section,
        repeatId,
        parentRepeatId,
        parentRepeatNumber,
        number,
        repeatable,
        previousQuestions,
        false,
        cultureCode
      );
      return;
    }
    case DELETE_REPEAT: {
      let section = action.payload.section;
      let repeatId = action.payload.repeatId;

      handleSectionRepeat.delete(draft, section.ID, repeatId, section.SectionDTO.SectionContentTemplate.Components);
      return;
    }
    case MODIFY_ANSWERS: {
      let { sectionId, repeatId, question, answers } = action.payload;
      modifyAnswers(draft, sectionId, repeatId, question.ID, answers);
      return;
    }
    case MODIFY_MULTIPLE_CHOICE_OPTION: {
      let { section, repeatId, questionId, option, checked, isExclusive, archived, parentSection, cultureCode } =
        action.payload;
      let parentSectionId = getParentSectionId(section, parentSection != null ? parentSection.ID : null);
      modifyMultipleChoiceOption(
        draft,
        section.ID,
        repeatId,
        questionId,
        option,
        checked,
        isExclusive,
        archived,
        cultureCode,
        parentSectionId
      );
      return;
    }
    case MODIFY_MULTIPLE_CHOICE_ANSWERS: {
      let { section, repeatId, questionId, option, answers, isExclusive, archived, parentSection, cultureCode } =
        action.payload;
      let parentSectionId = getParentSectionId(section, parentSection != null ? parentSection.ID : null);
      modifyMultipleChoiceAnswers(
        draft,
        section.ID,
        repeatId,
        questionId,
        option,
        answers,
        isExclusive,
        archived,
        cultureCode,
        parentSectionId
      );
      return;
    }
    case SHOW_OR_HIDE_SECTION: {
      let {
        section,
        parentRepeatId,
        parentRepeatNumber,
        followup,
        visible,
        repeats,
        previousQuestions,
        previousSections,
        sectionsConditions,
        cultureCode,
      } = action.payload;
      let previousSection = previousSections[section.SectionDTO.ReferenceName];
      let sectionConditions = sectionsConditions[section.ID];
      handleSection.show_or_hide(
        draft,
        section,
        parentRepeatId,
        parentRepeatNumber,
        followup,
        visible,
        repeats,
        previousSection,
        previousQuestions,
        sectionConditions,
        cultureCode
      );
      return;
    }
    case CHANGE_REPEATS: {
      let { section, questionId, optionValue, repeatId, cultureCode } = action.payload;
      let sections = _.filter(section.SectionDTO.SectionContentTemplate.Components, (item) => {
        return item.SectionDTO != null && item.SectionDTO.NumericQuestionRepeatID == questionId;
      });
      let repeat = draft[section.ID].repeats[repeatId];
      let parentRepeatId = repeat.repeatable ? repeatId : repeat.parentRepeatId != null ? repeat.parentRepeatId : null;
      let parentRepeatNumber = repeat.repetable
        ? repeat.number
        : repeat.parentRepeatId != null
        ? repeat.parentRepeatNumber
        : null;
      for (let sectionItem of sections) {
        changeRepeatsWithMCQuestion(draft, sectionItem, parentRepeatId, parentRepeatNumber, optionValue, cultureCode);
      }
      return;
    }
    case SET_USER_QUESTIONNAIRE_ID: {
      return initState;
    }
    default:
      return;
  }
}, initState);
