export const ADD_MC_HIGHLIGHT_QUESTION = "ADD_MC_HIGHLIGHT_QUESTION";

let addMCHighlighQuestion = (questionAnswers, question, sectionId, repeatId, parentRepeatNumber) => (
  dispatch,
  getState
) => {
  let { selectedPage } = getState().userQuestionnaire;

  dispatch({
    type: ADD_MC_HIGHLIGHT_QUESTION,
    payload: { questionAnswers, question, sectionId, repeatId, selectedPage, parentRepeatNumber },
  });
};

export default addMCHighlighQuestion;
