import { createReferenceMapForQuestion } from "../../../assets/questionnaire/Utils/questionReferencesUtil";

export const INIT_QUESTION_REF_MAP = "INIT_QUESTION_REF_MAP";

export const initQuestionRef = (question, repeatNr, parentRepeatNr, sectionReferenceName) => (dispatch, getState) => {
  let questionReferencesDictionary = getState().userQuestionnaire.questionReferences;
  let cultureCode = getState().userQuestionnaire.cultureCode;
  var map = createReferenceMapForQuestion(
    question,
    repeatNr,
    parentRepeatNr,
    sectionReferenceName,
    questionReferencesDictionary,
    cultureCode
  );

  if (map) {
    dispatch({
      type: INIT_QUESTION_REF_MAP,
      payload: map,
    });
  }
};
