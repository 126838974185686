import cascadingComponentReducer from "./cascadingComponentReducer";
import invalidItemsReducer from "./invalidItemsReducer";
import requiredItemsReducer from "./requiredItemsReducer";
import shouldModifyCategoriesReducer from "./shouldModifyCategoriesReducer";
import customErrorMsgReducer from "./customErrorMessagesReducer";
import questionReferencesMapReducer from "./questionReferencesMapReducer";
import questionsImagesReducer from "./questionsImagesReducer";
import emailVerifyReducer from "./emailVerifyReducer";
import formVerificationReducer from "./formVerificationReducer";

export default (state = {}, action) => {
  return {
    cascadingComponent: cascadingComponentReducer(state.cascadingComponent, action),
    invalidItems: invalidItemsReducer(state.invalidItems, action),
    requiredItems: requiredItemsReducer(state.requiredItems, action),
    shouldModifyCategories: shouldModifyCategoriesReducer(state.shouldModifyCategories, action),
    customErrorMsgs: customErrorMsgReducer(state.customErrorMsgs, action),
    questionReferencesMap: questionReferencesMapReducer(state.questionReferencesMap, action),
    questionsImages: questionsImagesReducer(state.questionsImages, action),
    emailVerify: emailVerifyReducer(state.emailVerify, action),
    formVerification: formVerificationReducer(state.formVerification, action),
  };
};
