export const PageNavigationENUM = Object.freeze({
  SAME: 0,
  NEXT: 1,
  BACK: 2,
  DIRECT_TO_SPECIFIC_PAGE: 3,
});

export const SectionViewType = Object.freeze({
  Normal: 0,
  Matrix: 1,
  PagedMatrix: 2,
  GridQuestion: 3,
});

export const GridQuestionAnswerOptionType = Object.freeze({
  RadioButtons: 0,
  Checkboxes: 1,
});

export const LogicalOperator = Object.freeze({
  Or: 0,
  And: 1,
});

export const CategoryOperator = Object.freeze({
  Equals: 0,
  NotEquals: 7,
  Became: 1,
  Smaller: 2,
  SmallerEquals: 3,
  Bigger: 4,
  BiggerEquals: 5,
  BecameNot: 6,
  NumericEquals: 8,
});

export const CategoryChangeValue = Object.freeze({
  Increase: 0,
  Decrease: 1,
});

export const QuestionContextVariableSrcENUM = Object.freeze({
  QuestionText: 0,
  HelpText: 1,
});

export const SummaryShowItemType = Object.freeze({
  OnlyRelevantItems: 0,
  OnlyArchivedItems: 1,
  AllItems: 2,
});

export const TranslationKey = Object.freeze({
  QuestionText: "QuestionText",
  QuestionHelpText: "QuestionHelpText",
  ValidationRule: "ValidationRule",
  SectionTitle: "SectionTitle",
  AnswerOptionText: "AnswerOptionText",
  QuestionParameterValue: "QuestionParameterValue",
  AnswersParameterValue: "AnswersParameterValue",
  AddRepeatButtonText: "AddRepeatButtonText",
  DeleteRepeatButtonText: "DeleteRepeatButtonText",
  SummaryZeroElementsText: "SummaryZeroElementsText",
  SummaryOptionColumnTitle: "SummaryOptionColumnTitle",
});
