export const SET_FORM_VERIFICATION_CODE = "SET_FORM_VERIFICATION_CODE";
export const SET_FORM_VERIFICATION_MSG = "SET_FORM_VERIFICATION_MSG";

export const setFormVerificationCode = (code) => {
  return {
    type: SET_FORM_VERIFICATION_CODE,
    payload: { code, message: null },
  };
};

export const setFormVerificationMessage = (message, msgType) => {
  return {
    type: SET_FORM_VERIFICATION_MSG,
    payload: { msgTxt: message, msgType },
  };
};
