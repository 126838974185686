import { FETCH_PAGE } from "../../actions/userQuestionnaire/selectPage";
import { SET_USER_QUESTIONNAIRE_ID } from "../../actions/userQuestionnaire/setUserQuestionnaireId";

export default (state = null, action) => {
  switch (action.type) {
    case FETCH_PAGE:
      return {
        id: action.payload.data.pageTemplate.ID,
        sortOrder: action.payload.sortOrder,
        pageType: action.payload.data.pageTemplate.SectionDTO.PageType,
      };
    case SET_USER_QUESTIONNAIRE_ID:
      return null;
    default:
      return state;
  }
};
