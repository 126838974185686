import {
  SET_FORM_VERIFICATION_CODE,
  SET_FORM_VERIFICATION_MSG,
} from "../../actions/tempStorage/setFormVerificationCode";
import { FETCH_PAGE } from "../../actions/userQuestionnaire/selectPage";

export default function (state = null, action) {
  switch (action.type) {
    case SET_FORM_VERIFICATION_CODE: {
      return action.payload;
    }
    case SET_FORM_VERIFICATION_MSG: {
      return { ...state, message: action.payload };
    }
    case FETCH_PAGE: {
      return null;
    }
    default:
      return state;
  }
}
