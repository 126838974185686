import _ from "lodash";
import moment from "moment";
import { defineDayMonthYear } from "../Utils/questionComponentUtil";
import { trimReference, _contextVariableREGEX } from "../Utils/questionReferencesUtil";
import { checkEmailAddress } from "../../common/emailValidator";

export const validationRulesCommands = (key, ruleValue, answer, currentDate, validationRulesReferences, additionalInfo) => {
  const validationRules = {
    "Minimum value": () => checkMimimumValueRule(ruleValue, answer),
    "Maximum value": () => checkMaximumValueRule(ruleValue, answer),
    "Minimum decimal value": () => checkMimimumDecimalValueRule(ruleValue, answer),
    "Maximum decimal value": () => checkMaximumDecimalValueRule(ruleValue, answer),
    "Maximum number of characters": () => checkMaximumNumberOfCharactersRule(ruleValue, answer),
    "Date in the future": () => checkDateInTheFuture(answer, currentDate),
    "Date in the past": () => checkDateInThePast(answer, currentDate),
    "Date in the past or today": () => checkDateInThePastOrToday(answer, currentDate),
    "Maximum days in future": () => checkMaxDaysInTheFuture(ruleValue, answer, currentDate),
    "Maximum days in the past": () => checkMaxDaysInThePast(ruleValue, answer, currentDate),
    "Minimum days in past": () => checkMinDaysInThePast(ruleValue, answer, currentDate),
    "Date before answer to other date question": () => checkDateBeforeAnswerToOtherDateQuestion(validationRulesReferences, answer, ruleValue),
    "Date after answer to other date question": () => checkDateAfterAnswerToOtherDateQuestion(validationRulesReferences, answer, ruleValue),
    "Valid 11-proof number": () => checkValid11ProofNumber(answer),
    "Valid email address": () => checkEmailAddressRule(answer, additionalInfo),
    "Minimum date value": () => checkMinimumDaysValues(ruleValue, answer),
    "Maximum date value": () => checkMaximumDaysValues(ruleValue, answer),
    "Valid Dutch Postal Code": () => checkValidDutchPostalCodeRule(answer),
    "Acceptable age": () => checkAgeValue(ruleValue, answer),
    "Valid Date": () => validDate(answer),
    "Valid Day": () => validDay(answer),
    "Years Old Not Valid": () => yearsOldNotValid(ruleValue, answer),
    "Year Should Not Be In Future": () => yearInFuture(answer, currentDate),
    "Year Max Characters": () => yearMaxCharacters(answer),
    "Valid Year": () => validYear(answer),
  };

  if (!validationRules[key]) return true;
  return validationRules[key]();
};

export const validationRulesRangeCommands = (key, answer1, answer2) => {
  const validationRules = {
    "First number is smaller or equal to the second number": () => checkFirstNumberSmallerOrEqualNumberSecondValue(answer1, answer2),
    "First date is smaller or equal to the second date": () => checkFirstDateSmallerOrEqualDateSecondValue(answer1, answer2),
    "Valid Password": () => validPassword(answer1, answer2),
  };
  if (!validationRules[key]) return true;
  return validationRules[key]();
};

/**
 *check if the answer is bigger or equal then the validation rule defined value
 * @param {string} ruleValue validation rule value (value to compare with)
 * @param {string} answer question answer
 */
const checkMimimumValueRule = (ruleValue, answer) => {
  return _.parseInt(answer) >= _.parseInt(ruleValue);
};

/**
 *check if the answer is smaller or equal then the validation rule defined value
 * @param {string} ruleValue validation rule value (value to compare with)
 * @param {string} answer question answer
 */
const checkMaximumValueRule = (ruleValue, answer) => {
  return _.parseInt(answer) <= _.parseInt(ruleValue);
};

/**
 *check if the answer is bigger or equal then the validation rule defined value
 * @param {string} ruleValue validation rule value (value to compare with)
 * @param {string} answer question answer
 */
const checkMimimumDecimalValueRule = (ruleValue, answer) => {
  if (answer.indexOf !== undefined && ruleValue) {
    var correctedAnswer = answer.indexOf(",") !== -1 ? answer.replace(",", ".") : answer;
    var correctedRuleValue = ruleValue.indexOf(",") !== -1 ? ruleValue.replace(",", ".") : ruleValue;

    return parseFloat(correctedAnswer) >= parseFloat(correctedRuleValue);
  }
  return true;
};

/**
 *check if the answer is smaller or equal then the validation rule defined value
 * @param {string} ruleValue validation rule value (value to compare with)
 * @param {string} answer question answer
 */
const checkMaximumDecimalValueRule = (ruleValue, answer) => {
  if (answer.indexOf !== undefined && ruleValue) {
    var correctedAnswer = answer.indexOf(",") !== -1 ? answer.replace(",", ".") : answer;
    var correctedRuleValue = ruleValue.indexOf(",") !== -1 ? ruleValue.replace(",", ".") : ruleValue;

    return parseFloat(correctedAnswer) <= parseFloat(correctedRuleValue);
  }
  return true;
};

/**
 *check if the answer length is smaller or equal then the validation rule defined value
 * @param {string} ruleValue validation rule value (value to compare with)
 * @param {string} answer question answer
 */
const checkMaximumNumberOfCharactersRule = (ruleValue, answer) => {
  return answer.length <= ruleValue;
};

/**
 *check if the answer is valid dutch postal code (regex)
 * @param {string} answer question answer
 */
const checkValidDutchPostalCodeRule = (answer) => {
  return /^[1-9][0-9]{3} ?(?!sa|sd|ss|SA|SD|SS)[A-Za-z]{2}$/.test(answer);
};

/**
 *check if the answer is valid email address (regex)
 * @param {string} answer question answer
 * @param {string} additionalInfo validation rule additional info
 */
const checkEmailAddressRule = (answer, additionalInfo) => {
  if (typeof answer === "string") answer = answer.trim();
  if (checkEmailAddress(answer)) {
    var domain = answer.substring(answer.lastIndexOf("@") + 1);
    var invalidEmailDomains = additionalInfo != null ? additionalInfo.split(";") : null;

    if (invalidEmailDomains != null && invalidEmailDomains.indexOf(domain) === -1) return true;
    else return false;
  }
  return false;
};

/**
 *check if the answer is valid 11-proof number (regex)
 * @param {string} answer question answer
 */
const checkValid11ProofNumber = (answer) => {
  const RITSNUMMER_CHAR_COUNT = 9;
  var weighedSum = 0;
  var numbersOnly = true;

  if (answer.length === RITSNUMMER_CHAR_COUNT) {
    for (var i = 0, len = answer.length; i < len; i++) {
      var number = _.parseInt(answer[i], 10);
      if (isNaN(number)) {
        numbersOnly = false;
        break;
      }
      var j = RITSNUMMER_CHAR_COUNT - i;
      if (i === answer.length - 1) j = -1;
      weighedSum += j * number;
    }
  }
  return answer.length === RITSNUMMER_CHAR_COUNT && numbersOnly && weighedSum > 0 && weighedSum % 11 === 0;
};

/**
 *check if the answer is bigger or equal max validation rule value days in the future
 * @param {string} ruleValue validation rule value (value to compare with)
 * @param {string} answer question answer
 * @param {string} currentDate application current date time
 */
const checkMaxDaysInTheFuture = (ruleValue, answer, currentDate) => {
  const answerDate = moment(answer, "YYYYMMDD");

  let currentDateObject = moment(currentDate, "YYYYMMDD").add(_.parseInt(ruleValue), "days");
  return answerDate.isSameOrBefore(currentDateObject);
};

/**
 *check if the answer is smaller or equal max validation rule value days in the future
 * @param {string} ruleValue validation rule value (value to compare with)
 * @param {string} answer question answer
 * @param {string} currentDate application current date time
 */
const checkMaxDaysInThePast = (ruleValue, answer, currentDate) => {
  const answerDate = moment(answer, "YYYYMMDD");

  let currentDateObject = moment(currentDate, "YYYYMMDD").subtract(_.parseInt(ruleValue), "days");
  return answerDate.isSameOrAfter(currentDateObject);
};

/**
 * check if minimum days in the past
 * @param {string} ruleValue validation rule value (value to compare with)
 * @param {string} answer question answer
 * @param {string} currentDate application current date time
 */
const checkMinDaysInThePast = (ruleValue, answer, currentDate) => {
  const answerDate = moment(answer, "YYYYMMDD");

  const currentDateObject = moment(currentDate, "YYYYMMDD").subtract(_.parseInt(ruleValue), "days");
  return answerDate.isSameOrBefore(currentDateObject);
};

/**
 *check if the answer is smaller or equal max validation rule value days in the future
 * @param {string} answer question answer
 * @param {string} currentDate application current date time
 */
export const checkDateInThePast = (answer, currentDate) => {
  const currentDateObject = moment(currentDate, "YYYYMMDD");
  const answerDate = moment(answer, "YYYYMMDD");

  return answerDate.isBefore(currentDateObject);
};

/**
 *check if the date is in the past (including today)
 * @param {string} answer question answer
 * @param {string} currentDate application current date time
 */
const checkDateInThePastOrToday = (answer, currentDate) => {
  const currentDateObject = moment(currentDate, "YYYYMMDD");
  const answerDate = moment(answer, "YYYYMMDD");

  return answerDate.isSameOrBefore(currentDateObject);
};

/**
 *check if the date is in the future
 * @param {string} answer question answer
 * @param {string} currentDate application current date time
 */
const checkDateInTheFuture = (answer, currentDate) => {
  const currentDateObject = moment(currentDate, "YYYYMMDD");
  const answerDate = moment(answer, "YYYYMMDD");

  return answerDate.isAfter(currentDateObject);
};

/**
 *check if the date bigger or equal then the minimum validation rule value
 * @param {string} ruleValue validation rule value (value to compare with)
 * @param {string} answer question answer
 */
const checkMinimumDaysValues = (ruleValue, answer) => {
  const minDate = ruleValue ? moment(ruleValue, "YYYYMMDD") : null;

  if (!minDate) return true;

  const answerDate = moment(answer, "YYYYMMDD");
  return answerDate.isSameOrAfter(minDate);
};

/**
 *check if the date smaller or equal then the minimum validation rule value
 * @param {string} ruleValue validation rule value (value to compare with)
 * @param {string} answer question answer
 */
const checkMaximumDaysValues = (ruleValue, answer) => {
  const maxDate = ruleValue ? moment(ruleValue, "YYYYMMDD") : null;

  if (!maxDate == null) return true;

  const answerDate = moment(answer, "YYYYMMDD");
  return answerDate.isSameOrBefore(maxDate);
};

/**
 *check if first number is smaller or equal then the second number
 * @param {string} answer1 first question range answer
 * @param {string} answer2 second question range answer
 */
const checkFirstNumberSmallerOrEqualNumberSecondValue = (answer1, answer2) => {
  if (!answer1 || !answer2) return true;
  return _.parseInt(answer1) <= _.parseInt(answer2);
};

/**
 *check if first date is smaller or equal then the second date
 * @param {string} answer1 first question range answer
 * @param {string} answer2 second question range answer
 */
const checkFirstDateSmallerOrEqualDateSecondValue = (answer1, answer2) => {
  if (!answer1 || !answer2) return true;
  const date1 = moment(answer1, "YYYYMMDD");
  const date2 = moment(answer2, "YYYYMMDD");
  return date1.isSame(date2) || date2.isAfter(date1);
};

/**
 *check if the answer is before answer form another date question
 * @param {string} validationRulesReferences answers from the reference define in the validation rule
 * @param {string} answer question answer
 * @param {string} ruleValue validationRuleValue
 */
const checkDateBeforeAnswerToOtherDateQuestion = (validationRulesReferences, answer, ruleValue) => {
  if (_.isEmpty(validationRulesReferences)) return true;

  if (ruleValue.match(_contextVariableREGEX)) {
    let trimReferenceValue = trimReference(ruleValue);
    let reference = _.find(validationRulesReferences, { reference: trimReferenceValue });
    if (_.isEmpty(reference) || _.isEmpty(reference.value)) return true;

    const referenceDate = reference.value ? moment(reference.value, "DD-MM-YYYY") : null;

    if (!referenceDate) return true;
    if (!referenceDate.isValid()) return true;

    const answerDate = moment(answer, "YYYYMMDD");
    return answerDate.isSameOrBefore(referenceDate);
  }
  return true;
};

/**
 *check if the answer is after answer form another date question
 * @param {string} referenceAnswer answers from the reference define in the validation rule
 * @param {string} answer question answer
 */
const checkDateAfterAnswerToOtherDateQuestion = (validationRulesReferences, answer, ruleValue) => {
  if (_.isEmpty(validationRulesReferences)) return true;

  if (ruleValue.match(_contextVariableREGEX)) {
    let trimReferenceValue = trimReference(ruleValue);
    let reference = _.find(validationRulesReferences, { reference: trimReferenceValue });
    if (_.isEmpty(reference) || _.isEmpty(reference.value)) return true;

    const referenceDate = reference.value ? moment(reference.value, "DD-MM-YYYY") : null;

    if (!referenceDate) return true;
    if (!referenceDate.isValid()) return true;

    const answerDate = moment(answer, "YYYYMMDD");
    return answerDate.isSameOrAfter(referenceDate);
  }
  return true;
};

/**
 *check if the answer -age is bigger or equal to the age defined in validation rule
 * @param {string} ruleValue validation rule value (value to compare with)
 * @param {string} answer question answer
 */
const checkAgeValue = (ruleValue, answer) => {
  const answerDate = moment(answer, "YYYYMMDD");
  return moment().diff(answerDate, "years") >= ruleValue;
};

/**
 *check if the answer is valid date
 * @param {string} answer question answer
 */
export const validDate = (answer) => {
  const answerDate = moment(answer, "YYYYMMDD", true);
  return answerDate.isValid();
};
/**
 *check if the answer is valid date
 * @param {string} answer question answer
 */
const validYear = (answer) => {
  const answerDate = defineDayMonthYear(answer);
  const baseDate = moment("19000101", "YYYYMMDD");
  return answerDate && answerDate.year.length === 4 && parseInt(answerDate.year) >= baseDate.year();
};

/**
 *check if the answer contains day and day has 2 characters
 * @param {string} answer question answer
 */
const validDay = (answer) => {
  const answerObject = defineDayMonthYear(answer);
  return answerObject && answerObject.day.length === 2 && answerObject.day !== "00" && parseInt(answerObject.day) <= 31 && parseInt(answerObject.day) >= 1;
};

/**
 *check if the answer compare from todays is not bigger then the ruleValue
 (specific for custom birthday question)
 * @param {string} answer question answer
 */
const yearsOldNotValid = (ruleValue, answer) => {
  const answerDate = moment(answer, "YYYYMMDD");
  return answerDate.isValid() && moment().diff(answerDate, "years") < ruleValue;
};

/**
 *check if the answer is valid date and if date year is not bigger from 
 the current application date year
 * @param {string} answer answer
 * @param {object} currentDate current application date
 */
const yearInFuture = (answer, currentDate) => {
  const answerData = answer ? moment(answer, "YYYYMMDD") : null;
  const currentDateObject = moment(currentDate, "YYYYMMDD");
  return answerData.isValid() && answerData.isBefore(currentDateObject);
};

/**
 *check if the answer contains year and year has 4 characters
 * @param {string} answer question answer
 */
const yearMaxCharacters = (answer) => {
  const answerObject = defineDayMonthYear(answer);
  return answerObject && answerObject.year.length === 4;
};

/**
 *check if password and confirm pasword are equals
 * @param {string} answer1 question answer
 * @param {string} answer2 question answer
 */
const validPassword = (answer1, answer2) => {
  if (_.isEmpty(answer1) || _.isEmpty(answer2)) return true;
  if (!_.isEmpty(answer1) && !_.isEmpty(answer2)) return _.isEqual(answer1, answer2);
};
