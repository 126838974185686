export const LOGOUT = "LOGOUT";

export default function logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  return {
    type: LOGOUT,
    payload: "",
  };
}
