import api from "../../assets/common/api/api";
import _ from "lodash";

import { selectPage } from "./selectPage";
import setCompleted from "./setCompleted";
import clearAllMessage from "../tempStorage/customErrorMsg/clearAllMessage";
import addErrorMessage from "../tempStorage/customErrorMsg/addMessage";
import { setLoading } from "../loading/setLoading";
import history from "../../assets/common/custom/history";
import { res } from "../../assets/localization/resources";

export const COMPLETE_QUESTIONNAIRE = "COMPLETE_QUESTIONNAIRE";

export const completeQuestionnaire = (pageRedirect) => async (dispatch, getState) => {
  let { id } = getState().userQuestionnaire;
  let study = getState().study;
  let postObj = {
    UserQuestionnaireId: id,
  };
  setLoading(true);
  await api
    .post("/Questionnaire/CompleteUserQuestionnaire", postObj)
    .then((response) => {
      if (
        response.status === 200 &&
        (response.data.ErrorMessage === null ||
          response.data.ErrorMessage === undefined ||
          response.data.ErrorMessage === "")
      ) {
        dispatch(clearAllMessage());
        dispatch(setCompleted(true));
        dispatch(setLoading(false));
        if (pageRedirect) {
          dispatch(selectPage(pageRedirect));
        } else {
          history.push(!_.isEmpty(response.data.RedirectUrl) ? response.data.RedirectUrl : "/");
        }
      } else {
        dispatch(setLoading(false));
        let errorMessage = res(study.Culture, response.data.ErrorMessage);
        dispatch(addErrorMessage("ID_MSG_KEY", _.isEmpty(errorMessage) ? response.data.ErrorMessage : errorMessage)); //TODO if are more messages add uniqui KEY for each message
      }
    })
    .catch((error) => {
      console.error("Action COMPLETE_QUESTIONNAIRE error: ", error);
      dispatch(setLoading(false));
      history.push("/pages/error");
    });
};
